import { Box, Typography, useTheme } from "@mui/joy";
import { useEffect, useState } from "react";
// import FooterPortfolio from "../FooterPortfolio";
import { useContext } from "react";
import Context from "../../Components/Context/Context";

const AnimatedGraph = () => {
  const themeP = useTheme();
  const themes = themeP.vars.palette;
  const { theme } = useContext(Context);
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setStartAnimation(true);
    }, 1);

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, []);

  const finalWidthBar1 = "32%";
  const finalWidthBar2 = "27%";
  const finalWidthBar3 = "40%";

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor:
          theme === "light" ? themes.primary[50] : themes.primary[900],
      }}
    >
      {/* <FooterPortfolio /> */}
      <Box
        maxWidth={"1000px"}
        width={"100%"}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        <Typography
          level="h3"
          sx={{ marginBottom: "12px" }}
          textColor={
            theme === "light" ? themes.primary[800] : themes.primary[50]
          }
        >
          Summer 2023 auction metrics
        </Typography>
        {/* > XS */}
        <Box
          sx={{
            width: "100%",
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
          }}
        >
          <Box mb={"32px"} width={"100%"}>
            <Box mb={"8px"}>
              <Typography
                level="body1"
                textColor={
                  theme === "light" ? themes.primary[800] : themes.primary[50]
                }
                textAlign={"start"}
              >
                +3 times the amount of registrants
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                backgroundColor: "#1AE05E",
                borderRadius: "16px",
                height: "40px",
                width: "100%",
                maxWidth: "1000px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  transition: "all 2000ms cubic-bezier(0.4, 0, 0.2, 1)",
                  height: "100%",
                  borderRadius: "16px 0px 0px 16px",
                  background: "#4893FF",
                  width: !startAnimation ? "20%" : finalWidthBar1,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    left: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexShrink: 0,
                    }}
                  >
                    <Box sx={{ width: "39px" }}>
                      <Typography
                        fontWeight="350"
                        fontSize="12px"
                        lineHeight="170%"
                        textColor={"common.white"}
                      >
                        2022
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "1px",
                        height: "24px",
                        backgroundColor: "#FFFFFF",
                        marginX: "8px",
                      }}
                    ></Box>
                    <Typography
                      fontWeight="350"
                      fontSize="12px"
                      lineHeight="170%"
                      textColor={"common.white"}
                    >
                      200 registrants
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    right: "-16px",
                    transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    transform: "translateX(100%)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexShrink: 0,
                    }}
                  >
                    <Box sx={{ width: "39px" }}>
                      <Typography
                        fontWeight="900"
                        fontSize="16px"
                        lineHeight="100%"
                        textColor={themes.primary[800]}
                      >
                        2023
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "1px",
                        height: "24px",
                        backgroundColor: "#00275F",
                        marginX: "10px",
                      }}
                    ></Box>
                    <Typography
                      sx={{ width: { xs: "150px", sm: "auto" } }}
                      fontWeight="900"
                      fontSize="16px"
                      lineHeight="100%"
                      textColor={themes.primary[800]}
                    >
                      600 registrants
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mb={"32px"} width={"100%"}>
            <Box mb={"8px"}>
              <Typography
                level="body1"
                textColor={
                  theme === "light" ? themes.primary[800] : themes.primary[50]
                }
                textAlign={"start"}
              >
                +5 times de amount of bids
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                backgroundColor: "#1AE05E",
                borderRadius: "16px",
                height: "40px",
                width: "100%",
                maxWidth: "1000px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  transition: "all 2000ms cubic-bezier(0.4, 0, 0.2, 1)",
                  height: "100%",
                  borderRadius: "16px 0px 0px 16px",
                  background: "#4893FF",
                  width: !startAnimation ? "20%" : finalWidthBar2,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    left: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexShrink: 0,
                    }}
                  >
                    <Box sx={{ width: "39px" }}>
                      <Typography
                        fontWeight="350"
                        fontSize="12px"
                        lineHeight="100%"
                        textColor={"common.white"}
                      >
                        2022
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "1px",
                        height: "24px",
                        backgroundColor: "#FFFFFF",
                        marginX: "8px",
                      }}
                    ></Box>
                    <Typography
                      fontWeight="350"
                      fontSize="12px"
                      lineHeight="170%"
                      textColor={"common.white"}
                    >
                      400 bids
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    right: "-16px",
                    transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    transform: "translateX(100%)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexShrink: 0,
                    }}
                  >
                    <Box sx={{ width: "39px" }}>
                      <Typography
                        fontWeight="900"
                        fontSize="16px"
                        lineHeight="150%"
                        textColor={themes.primary[800]}
                      >
                        2023
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "1px",
                        height: "24px",
                        backgroundColor: "#00275F",
                        marginX: "10px",
                        display: "flex",
                      }}
                    ></Box>
                    <Typography
                      sx={{ width: { xs: "150px", sm: "auto" } }}
                      fontWeight="900"
                      fontSize="16px"
                      lineHeight="100%"
                      textColor={themes.primary[800]}
                    >
                      2,000 total bids
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mb={"32px"} width={"100%"}>
            <Box mb={"8px"}>
              <Typography
                level="body1"
                textColor={
                  theme === "light" ? themes.primary[800] : themes.primary[50]
                }
                textAlign={"start"}
              >
                Outpaced by 12 M the estimated revenue
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                backgroundColor: "#1AE05E",
                borderRadius: "16px",
                height: "40px",
                width: "100%",
                maxWidth: "1000px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  transition: "all 2000ms cubic-bezier(0.4, 0, 0.2, 1)",
                  height: "100%",
                  borderRadius: "16px 0px 0px 16px",
                  background: "#4893FF",
                  width: !startAnimation ? "20%" : finalWidthBar3,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    left: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexShrink: 0,
                    }}
                  >
                    <Typography
                      fontWeight="350"
                      fontSize="12px"
                      lineHeight="170%"
                      textColor={"common.white"}
                    >
                      10 M estimated
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    right: "-16px",
                    transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    transform: "translateX(100%)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexShrink: 0,
                    }}
                  >
                    <Typography
                      sx={{ width: { xs: "150px", sm: "auto" } }}
                      fontWeight="900"
                      fontSize="16px"
                      lineHeight="100%"
                      textColor={themes.primary[800]}
                    >
                      22 M total revenue
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* XS */}
        <Box
          sx={{
            width: "100%",
            display: { xs: "flex", sm: "none" },
            flexDirection: "column",
          }}
        >
          <Box mb={"32px"} width={"100%"}>
            <Box mb={"8px"} mt={"16px"}>
              <Typography
                level="body1"
                textColor={
                  theme === "light" ? themes.primary[800] : themes.primary[50]
                }
                textAlign={"start"}
              >
                +3 times the amount of registrants
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  backgroundColor: "#1AE05E",
                  borderRadius: "16px 16px 0px 0px",
                  height: "100px",
                  transition: "all 2000ms cubic-bezier(0.4, 0, 0.2, 1)",
                  width: !startAnimation ? "70%" : "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    marginLeft: "20px",
                  }}
                >
                  <Box
                    sx={{ width: "20%", display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      level="body1Bold"
                      textColor={themes.primary[800]}
                    >
                      2023
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "1px",
                      height: "70px",
                      backgroundColor: "#00275F",
                      marginX: "10px",
                    }}
                  ></Box>
                  <Box
                    sx={{ width: "70%", display: "flex", alignItems: "center" }}
                  >
                    <Typography level="body1Bold" textColor={themes.primary[800]}>
                      600 registrants
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  background: "#4893FF",
                  borderRadius: "0px 0px 16px 16px",
                  height: "40px",
                  transition: "all 2000ms cubic-bezier(0.4, 0, 0.2, 1)",
                  width: !startAnimation ? "70%" : "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    marginLeft: "20px",
                  }}
                >
                  <Box sx={{ width: "39px" }}>
                    <Typography level="body3" textColor={themes.common.white}>
                      2022
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "1px",
                      height: "24px",
                      backgroundColor: themes.common.white,
                      marginX: "8px",
                    }}
                  ></Box>
                  <Typography level="body3" textColor={themes.common.white}>
                    200 registrants
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mb={"32px"} width={"100%"}>
            <Box mb={"8px"}>
              <Typography
                level="body1"
                textColor={
                  theme === "light" ? themes.primary[800] : themes.primary[50]
                }
                textAlign={"start"}
              >
                +5 times de amount of bids
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  backgroundColor: "#1AE05E",
                  borderRadius: "16px 16px 0px 0px",
                  height: "100px",
                  transition: "all 2000ms cubic-bezier(0.4, 0, 0.2, 1)",
                  width: !startAnimation ? "65%" : "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    marginLeft: "20px",
                  }}
                >
                  <Box
                    sx={{ width: "20%", display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      level="body1Bold"
                      textColor={themes.primary[800]}
                    >
                      2023
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "1px",
                      height: "70px",
                      backgroundColor: "#00275F",
                      marginX: "10px",
                    }}
                  ></Box>
                  <Box
                    sx={{ width: "70%", display: "flex", alignItems: "center" }}
                  >
                    <Typography level="body1Bold" textColor={themes.primary[800]}>
                      2,000 total bids
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  background: "#4893FF",
                  borderRadius: "0px 0px 16px 16px",
                  height: "40px",
                  transition: "all 2000ms cubic-bezier(0.4, 0, 0.2, 1)",
                  width: !startAnimation ? "65%" : "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    marginLeft: "20px",
                  }}
                >
                  <Box sx={{ width: "39px" }}>
                    <Typography level="body3" textColor={themes.common.white}>
                      2022
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "1px",
                      height: "24px",
                      backgroundColor: themes.common.white,
                      marginX: "8px",
                    }}
                  ></Box>
                  <Typography level="body3" textColor={themes.common.white}>
                    400 bids
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mb={"32px"} width={"100%"}>
            <Box mb={"8px"}>
              <Typography
                level="body1"
                textColor={
                  theme === "light" ? themes.primary[800] : themes.primary[50]
                }
                textAlign={"start"}
              >
                Outpaced by 12 M the estimated revenue
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  backgroundColor: "#1AE05E",
                  borderRadius: "16px 16px 0px 0px",
                  height: "100px",
                  transition: "all 2000ms cubic-bezier(0.4, 0, 0.2, 1)",
                  width: !startAnimation ? "55%" : "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    marginLeft: "20px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography level="body1Bold" textColor={themes.primary[800]}>
                      22 M total revenue
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  background: "#4893FF",
                  borderRadius: "0px 0px 16px 16px",
                  height: "40px",
                  transition: "all 2000ms cubic-bezier(0.4, 0, 0.2, 1)",
                  width: !startAnimation ? "55%" : "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                    marginLeft: "20px",
                  }}
                >
                  <Typography level="body3" textColor={themes.common.white}>
                    10 M estimated
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AnimatedGraph;
