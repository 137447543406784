// Assets
import HermesLogo from "../../Icons/HermesLogo";
import BurguerIcon from "../../Icons/BurgerIcon";
// import CancelIcon from "../Icons/CancelIcon";

// Component
import ThemeSwitch from "../../UIComponents/ThemeSwitch";

// Tools
import { Box, useTheme } from "@mui/joy";
import { useContext } from "react";

// Context
import Context from "../../../Context/Context";
import HermesLogoDark from "../../Icons/HermesLogoDark";

const PageModalHeader: React.ElementType = ({
  openMenu,
  setOpenMenu,
  closeModal,
}: {
  openMenu: boolean;
  setOpenMenu: Function;
  closeModal: Function;
}) => {
  const themes = useTheme();

  const { theme } = useContext(Context);

  const goHome = () => {
    closeModal();
  };

  return (
    <Box component={"main"}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: { xs: "68px", md: "98px" },
          backgroundColor:
            (window.location.pathname.includes("/services") ||
              window.location.pathname.includes("/playground") ||
              window.location.pathname.includes("/example") ||
              window.location.pathname.includes("/buttondisplay") ||
              window.location.pathname.includes("/infinitescroll") ||
              window.location.pathname.includes("/designsystem") ||
              window.location.pathname.includes("/caroussel") ||
              window.location.pathname.includes("/switch") ||
              window.location.pathname.includes("/itemsrow") ||
              window.location.pathname.includes("/graph")) &&
            theme === "light"
              ? themes.palette.primary[50]
              : "transparent",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            paddingLeft: "40px",
            cursor: "pointer",
          }}
          onClick={() => goHome()}
        >
          {theme === "light" ? (
            <>
              <HermesLogo
                width={"252"}
                height={"40"}
                fill={themes.vars.palette.common.black}
              />
            </>
          ) : (
            <>
              <HermesLogoDark
                width={"252"}
                height={"40"}
                fill={themes.vars.palette.common.white}
              />
            </>
          )}
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            paddingLeft: "20px",
            cursor: "pointer",
          }}
          onClick={() => goHome()}
        >
          {theme === "light" ? (
            <>
              <HermesLogo
                width={"150"}
                height={"23.81"}
                fill={themes.vars.palette.common.black}
              />
            </>
          ) : (
            <>
              <HermesLogoDark
                width={"150"}
                height={"23.81"}
                fill={themes.vars.palette.common.white}
              />
            </>
          )}
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            width: "15%",
            justifyContent: "end",
            position: "relative",
            zIndex: 20,
            marginRight: "10px",
          }}
        >
          <ThemeSwitch />
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none " },
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "20px",
            position: "relative",
            zIndex: 20,
            paddingBottom: "4px",
          }}
          onClick={() => setOpenMenu((prev: boolean) => !prev)}
        >
          {/* {openMenu ? (
            <CancelIcon />
          ) : ( */}
          <BurguerIcon width={"24"} height={"24"} />
          {/* )} */}
        </Box>
      </Box>
    </Box>
  );
};

export default PageModalHeader;
