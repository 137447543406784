// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Props {
  width?: string;
  height?: string;
}

const HermesLogo: React.ElementType = ({
  width = "",
  height = "",
  fill = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 252 40"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.7881 23.5125C28.6535 23.5132 28.5223 23.4695 28.4137 23.388C28.3052 23.3066 28.225 23.1915 28.185 23.0598L27.0891 19.6649L4.61912 10.185L7.40556 15.3471L20.998 19.5604C21.1593 19.6126 21.2941 19.7276 21.3736 19.8806C21.453 20.0336 21.4706 20.2125 21.4227 20.3787C21.3985 20.4612 21.3585 20.5379 21.3052 20.6045C21.2518 20.6711 21.1861 20.7262 21.1119 20.7667C21.0377 20.8071 20.9564 20.8322 20.8726 20.8403C20.7889 20.8484 20.7045 20.8394 20.6242 20.8139L6.8024 16.5484C6.64377 16.4959 6.5103 16.384 6.42861 16.235L2.62274 9.1839C2.55727 9.06665 2.52943 8.93129 2.54315 8.79694C2.55687 8.6626 2.61146 8.53607 2.69919 8.43526C2.78619 8.33722 2.89992 8.2682 3.02563 8.23716C3.15135 8.20612 3.28327 8.21448 3.4043 8.26115L27.8451 18.5854C27.9307 18.621 28.0077 18.6753 28.0708 18.7445C28.1339 18.8136 28.1815 18.896 28.2104 18.9859L29.3913 22.642C29.4178 22.7237 29.4283 22.81 29.4221 22.8959C29.416 22.9818 29.3933 23.0656 29.3554 23.1425C29.3175 23.2193 29.2651 23.2878 29.2013 23.3438C29.1375 23.3998 29.0634 23.4424 28.9835 23.469C28.9207 23.4927 28.8549 23.5074 28.7881 23.5125Z"
        fill="#101218"
      />
      <path
        d="M21.805 24.8705C21.7601 24.879 21.714 24.879 21.6691 24.8705L13.5986 22.9815C13.4999 22.9572 13.408 22.9101 13.33 22.8437C13.2519 22.7773 13.1898 22.6934 13.1484 22.5985L11.05 17.5843C10.9836 17.425 10.9816 17.2452 11.0445 17.0844C11.1074 16.9236 11.2301 16.795 11.3856 16.7269C11.5411 16.6588 11.7166 16.6567 11.8735 16.7212C12.0304 16.7857 12.1559 16.9114 12.2224 17.0707L14.1763 21.7715L21.9494 23.5735C22.1014 23.6101 22.2349 23.7027 22.3246 23.8336C22.4144 23.9644 22.4541 24.1245 22.4363 24.2834C22.4185 24.4423 22.3443 24.589 22.228 24.6956C22.1116 24.8022 21.9611 24.8614 21.805 24.8618V24.8705Z"
        fill="#101218"
      />
      <path
        d="M23.1643 28.6224H23.0878L18.305 27.9957C18.187 27.9779 18.0762 27.927 17.9847 27.8486C17.8932 27.7703 17.8247 27.6675 17.7868 27.5517L16.385 23.321C16.3311 23.1571 16.3428 22.9779 16.4177 22.8229C16.4926 22.6678 16.6244 22.5495 16.7843 22.494C16.8635 22.4654 16.9474 22.4534 17.0312 22.4586C17.1149 22.4637 17.1968 22.4861 17.2721 22.5242C17.3473 22.5623 17.4143 22.6155 17.4691 22.6806C17.524 22.7457 17.5655 22.8213 17.5914 22.9032L18.8657 26.7508L23.2492 27.3341C23.4058 27.3601 23.5472 27.4451 23.6458 27.5724C23.7445 27.6996 23.7933 27.86 23.7827 28.0222C23.772 28.1844 23.7028 28.3366 23.5884 28.4492C23.4741 28.5619 23.3228 28.6267 23.1643 28.6311V28.6224Z"
        fill="#101218"
      />
      <path
        d="M28.7881 31.5038C27.8905 31.5055 27.0126 31.2344 26.2655 30.7246C25.5183 30.2149 24.9356 29.4895 24.5909 28.6402C24.2463 27.791 24.1552 26.856 24.3293 25.9537C24.5034 25.0514 24.9348 24.2223 25.5689 23.5714C26.2029 22.9204 27.0113 22.4768 27.8915 22.2967C28.7717 22.1166 29.6843 22.2082 30.5137 22.5598C31.3431 22.9113 32.0521 23.5072 32.551 24.2718C33.0498 25.0364 33.3161 25.9355 33.3161 26.8553C33.3138 28.0859 32.8363 29.2657 31.9879 30.1367C31.1395 31.0077 29.9891 31.4992 28.7881 31.5038ZM28.7881 23.5212C28.1422 23.5195 27.5103 23.7143 26.9726 24.0809C26.4348 24.4476 26.0154 24.9696 25.7674 25.5807C25.5194 26.1919 25.4541 26.8647 25.5797 27.514C25.7053 28.1632 26.0162 28.7596 26.4729 29.2277C26.9297 29.6957 27.5117 30.0142 28.1453 30.1429C28.7789 30.2716 29.4355 30.2047 30.0319 29.9506C30.6284 29.6965 31.1378 29.2667 31.4956 28.7157C31.8534 28.1646 32.0435 27.5172 32.0418 26.8553C32.0418 25.9702 31.6993 25.1213 31.0893 24.4947C30.4794 23.868 29.6518 23.5148 28.7881 23.5125V23.5212Z"
        fill="#101218"
      />
      <path
        d="M4.54266 30.1197C3.65132 30.1209 2.77937 29.8531 2.0357 29.3496C1.29202 28.8461 0.709573 28.1293 0.361087 27.2886C0.0126002 26.4479 -0.0864828 25.5207 0.0762106 24.6227C0.238904 23.7247 0.656164 22.8957 1.27589 22.2392C1.89561 21.5827 2.69033 21.1279 3.56081 20.9314C4.4313 20.735 5.33897 20.8057 6.17048 21.1347C7.00199 21.4636 7.7205 22.0364 8.23628 22.7813C8.75206 23.5262 9.04226 24.4103 9.07063 25.3232C9.10731 26.5541 8.6667 27.7499 7.8452 28.6487C7.02369 29.5476 5.88818 30.0765 4.68708 30.1197H4.54266ZM4.54266 22.1371H4.43223C4.00475 22.1491 3.58391 22.2484 3.19441 22.4293C2.8049 22.6102 2.45458 22.8689 2.16399 23.1904C1.79972 23.5909 1.53728 24.0771 1.39985 24.6061C1.26242 25.1351 1.25424 25.6907 1.37601 26.2237C1.49779 26.7567 1.74578 27.2508 2.09809 27.6624C2.45039 28.0739 2.89618 28.3902 3.39606 28.5834C3.89595 28.7765 4.43456 28.8406 4.96433 28.7699C5.4941 28.6992 5.99873 28.4959 6.43364 28.1779C6.86856 27.86 7.22038 27.4372 7.45805 26.9469C7.69571 26.4566 7.81189 25.9139 7.79634 25.3667C7.76774 24.5014 7.41262 23.6812 6.80575 23.0789C6.19887 22.4765 5.38758 22.1389 4.54266 22.1371Z"
        fill="#101218"
      />
      <path
        d="M17.8462 9.29706C16.9552 9.2966 16.0841 9.0273 15.3416 8.52275C14.599 8.01821 14.0179 7.30077 13.6707 6.45995C13.3235 5.61913 13.2256 4.69216 13.3892 3.7947C13.5529 2.89725 13.9708 2.06904 14.5909 1.41342C15.211 0.757807 16.0057 0.30381 16.876 0.108081C17.7463 -0.0876484 18.6535 -0.0164442 19.4845 0.312809C20.3155 0.642063 21.0336 1.21479 21.549 1.95952C22.0644 2.70425 22.3543 3.58802 22.3827 4.50054C22.4194 5.73149 21.9788 6.92722 21.1573 7.82612C20.3358 8.72501 19.2002 9.2539 17.9991 9.29706H17.8462ZM17.8462 1.31447H17.7613C17.3338 1.32644 16.913 1.42577 16.5235 1.60665C16.134 1.78753 15.7836 2.0463 15.493 2.36779C14.9118 3.01909 14.6063 3.87997 14.6435 4.7617C14.6625 5.41806 14.8703 6.05405 15.2407 6.58989C15.6111 7.12573 16.1277 7.53756 16.7256 7.77372C17.3235 8.00988 17.9762 8.05985 18.6018 7.91737C19.2273 7.77488 19.7979 7.44629 20.2419 6.9728C20.6917 6.49898 20.994 5.89882 21.1102 5.24858C21.2264 4.59834 21.1514 3.92737 20.8947 3.32093C20.6379 2.71449 20.211 2.19994 19.6682 1.84268C19.1254 1.48542 18.4912 1.30156 17.8462 1.31447Z"
        fill="#101218"
      />
      <path
        d="M21.2443 40H20.9215C17.5916 39.9658 14.3391 38.9667 11.5399 37.1182C8.7408 35.2697 6.50929 32.6472 5.10335 29.5539C5.06765 29.4738 5.04768 29.3874 5.04458 29.2995C5.04149 29.2115 5.05532 29.1238 5.0853 29.0414C5.14584 28.8749 5.26845 28.7398 5.42617 28.6659C5.58389 28.5921 5.76378 28.5854 5.92629 28.6474C6.08879 28.7095 6.22059 28.8351 6.29269 28.9967C7.53691 31.7107 9.46457 34.0359 11.8772 35.7328C14.2898 37.4297 17.1002 38.437 20.019 38.651C22.9378 38.865 25.8596 38.278 28.4834 36.9504C31.1072 35.6228 33.3383 33.6026 34.947 31.0978C36.5557 28.593 37.4838 25.6941 37.6357 22.6996C37.7877 19.7051 37.158 16.7232 35.8115 14.0609C34.4651 11.3986 32.4505 9.15219 29.9752 7.5529C27.4998 5.95361 24.6533 5.05925 21.7286 4.96192C21.5588 4.95511 21.3985 4.88001 21.2824 4.75291C21.1663 4.62581 21.1038 4.45694 21.1084 4.28292C21.115 4.11127 21.1874 3.94923 21.3099 3.8319C21.4323 3.71458 21.595 3.65143 21.7625 3.65615C26.4122 3.83292 30.8105 5.86412 34.0195 9.3166C37.2284 12.7691 38.9941 17.3696 38.9399 22.1371C38.8577 26.895 36.9587 31.4303 33.6504 34.7698C30.3422 38.1094 25.8882 39.9871 21.2443 40Z"
        fill="#101218"
      />
      <path
        d="M49.4741 11.3254H51.8697V20.1785H62.6332V11.3254H65.0204V30.8248H62.6332V22.2155H51.8697V30.8509H49.4741V11.3254Z"
        fill="#101218"
      />
      <path
        d="M70.0496 11.3254H83.3956V13.2492H72.4197V20.3787H81.875V22.2677H72.4197V28.9271H83.7524V30.8509H70.0496V11.3254Z"
        fill="#101218"
      />
      <path
        d="M98.0244 23.7476L102.128 30.8509H99.5196L95.7647 24.1393H89.7331V30.8509H87.3714V11.3254H95.5268C99.8424 11.3254 102.374 14.5027 102.374 17.7323C102.362 19.0773 101.935 20.384 101.154 21.4647C100.372 22.5454 99.2768 23.3446 98.0244 23.7476ZM89.7331 22.2416H95.153C98.1689 22.2416 99.9104 20.1262 99.9104 17.7584C99.9104 15.3906 98.2113 13.2753 95.153 13.2753H89.7671L89.7331 22.2416Z"
        fill="#101218"
      />
      <path
        d="M106.265 11.3254H108.677L116.391 22.4679L124.037 11.3254H126.424V30.8248H124.037V19.7867L124.258 14.8857L116.612 25.9151H116.068L108.423 14.8857L108.618 19.7867V30.8509H106.307L106.265 11.3254Z"
        fill="#101218"
      />
      <path
        d="M131.513 11.3254H144.867V13.2492H133.883V20.3787H143.338V22.2677H133.883V28.9271H145.216V30.8509H131.513V11.3254Z"
        fill="#101218"
      />
      <path
        d="M147.254 27.8651L148.673 26.2285C149.437 27.1262 150.376 27.8502 151.43 28.3539C152.483 28.8576 153.627 29.13 154.79 29.1534C157.338 29.1534 159.08 28.0044 159.08 25.7758C159.08 23.2688 156.531 22.6333 153.617 21.9369C150.517 21.1534 147.96 19.6823 147.96 16.3047C147.96 12.5789 151.009 11.1251 154.594 11.1251C157 11.1122 159.343 11.9174 161.255 13.4146L160.116 15.086C158.564 13.7735 156.631 13.0264 154.62 12.9619C152.386 12.9619 150.457 13.8847 150.457 16.2002C150.457 18.5158 152.632 19.3428 155.189 20.0392C158.426 20.9619 161.603 21.9891 161.603 25.6975C161.603 29.4059 158.562 31.0424 154.807 31.0424C153.401 31.0827 152.003 30.8207 150.702 30.2735C149.401 29.7262 148.227 28.9058 147.254 27.8651Z"
        fill="#101218"
      />
      <path
        d="M171.61 21.1012C171.61 15.1382 175.688 11.1251 181.805 11.1251C183.206 11.0508 184.606 11.2859 185.91 11.8146C187.215 12.3433 188.393 13.1532 189.365 14.1893L187.666 15.7214C186.93 14.8995 186.03 14.2483 185.028 13.8114C184.026 13.3745 182.944 13.1621 181.856 13.1882C177.175 13.1882 174.023 16.2525 174.023 21.0751C174.023 25.8977 177.234 29.0054 181.796 29.0054C182.944 29.0024 184.079 28.7538 185.128 28.2756C186.176 27.7974 187.116 27.1002 187.887 26.2285L189.45 27.7867C188.47 28.832 187.291 29.6602 185.986 30.2201C184.682 30.7801 183.279 31.06 181.864 31.0424C175.798 31.0424 171.61 27.1425 171.61 21.1012Z"
        fill="#101218"
      />
      <path
        d="M191.294 21.1273C191.294 15.1643 195.431 11.1251 201.412 11.1251C207.392 11.1251 211.538 15.1643 211.538 21.1273C211.538 27.0903 207.486 31.0686 201.429 31.0686C195.372 31.0686 191.294 27.0642 191.294 21.1273ZM209.074 21.1273C209.074 16.2786 205.923 13.1882 201.429 13.1882C196.935 13.1882 193.783 16.2263 193.783 21.1273C193.783 26.0283 197.02 28.9619 201.429 28.9619C205.838 28.9619 209.074 26.0022 209.074 21.1534V21.1273Z"
        fill="#101218"
      />
      <path
        d="M226.226 23.7476L230.329 30.8509H227.721L223.975 24.1393H217.935V30.8509H215.573V11.3254H223.729C228.053 11.3254 230.576 14.5027 230.576 17.7323C230.564 19.0773 230.137 20.384 229.356 21.4647C228.574 22.5454 227.479 23.3446 226.226 23.7476ZM217.935 22.2416H223.321C226.337 22.2416 228.078 20.1262 228.078 17.7584C228.078 15.3906 226.379 13.2753 223.321 13.2753H217.935V22.2416Z"
        fill="#101218"
      />
      <path
        d="M234.475 11.3254H242.631C247.116 11.3254 249.478 14.5027 249.478 17.7323C249.478 20.9619 247.116 24.1132 242.682 24.1132H236.862V30.8248H234.475V11.3254ZM242.223 22.2155C245.426 22.2155 246.98 20.1001 246.98 17.7323C246.98 15.3645 245.46 13.2492 242.223 13.2492H236.837V22.2155H242.223Z"
        fill="#101218"
      />
      <path
        d="M249.062 29.6496C249.051 29.3498 249.129 29.0536 249.284 28.7992C249.439 28.5448 249.665 28.3436 249.933 28.2216C250.2 28.0996 250.497 28.0622 250.785 28.1143C251.074 28.1663 251.34 28.3055 251.551 28.5139C251.762 28.7223 251.907 28.9904 251.968 29.2838C252.029 29.5773 252.003 29.8827 251.893 30.161C251.784 30.4392 251.595 30.6776 251.353 30.8455C251.11 31.0134 250.824 31.1032 250.531 31.1034C250.342 31.1068 250.153 31.072 249.977 31.0008C249.8 30.9296 249.639 30.8235 249.502 30.6885C249.366 30.5535 249.257 30.3922 249.181 30.214C249.105 30.0357 249.065 29.844 249.062 29.6496Z"
        fill="#101218"
      />
    </svg>
  );
};

export default HermesLogo;
