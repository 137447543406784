// Assets
import GoBackLeftArrow from "../UI/Icons/GoBackLeftArrow";
import Line from "../UI/Icons/Line";

// React
// import { useState } from "react";

// Style
import { Box, Button, Typography, useTheme } from "@mui/joy";

// Tools
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const DesignSystem = () => {
  const F = FormattedMessage;
  const theme = useTheme();
  const themes = theme.vars.palette;
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/playground");
  };

  return (
    <Box
      component="main"
      sx={{ maxHeight: "100dvh", backgroundColor: themes.primary[50] }}
    >
      <Box
        sx={{
          width: { xs: "90dvw", sm: "94dvw", md: "70dvw" },
          marginLeft: { md: "40px" },
          marginX: { xs: "20px" },
          paddingBottom: "130px",
        }}
      >
        <Box>
          <Button
            onClick={() => goBack()}
            sx={{
              width: { xs: "30%", sm: "16%", md: "auto" },
              backgroundColor: themes.common.white,
              transition: "all 300ms ease-in",
              "&:hover": {
                borderRadius: "8px",
                backgroundColor: themes.neutral[50],
              },
            }}
            startDecorator={<GoBackLeftArrow width={"16px"} height={"16px"} />}
          >
            <Typography
              level="h6Bold"
              textColor={themes.primary[400]}
              sx={{ textTransform: "uppercase" }}
            >
              <F id="goBack.back" defaultMessage="Back" />
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, gap: "16px" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "100%",
              paddingTop: "36px",
            }}
          >
            <Typography level="h4" textColor={themes.primary[800]}>
              <F
                id="why.you.need"
                defaultMessage="Why you need a design system?"
              />
            </Typography>
            <Box
              sx={{
                display: "flex",
                paddingTop: "4px",
              }}
            >
              <Line width="550px" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                width: "100%",
                paddingTop: "2px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "83px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor: themes.secondary[50],
                }}
              >
                <Typography level="body3" textColor={themes.secondary[500]}>
                  <F id="info.example" defaultMessage="Example" />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "40px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor: themes.secondary[50],
                }}
              >
                <Typography level="body3" textColor={themes.secondary[500]}>
                  <F id="info.UI" defaultMessage="UI" />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "73px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor: themes.secondary[50],
                }}
              >
                <Typography level="body3" textColor={themes.secondary[500]}>
                  <F id="info.react" defaultMessage="React" />
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", marginTop: "20px" }}
          >
            <Box sx={{ marginBottom: "16px" }}>
              <Typography
                level="body1"
                textColor={themes.common.black}
                textAlign="start"
              >
                <F
                  id="why.you.need1"
                  defaultMessage="Si haz leído este término antes o no, es algo que debes conocer y comenzar a implementar en tus proyectos de programación, desarrollo y diseño de interfaces de usuario !YA!"
                />
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "8px" }}>
              <Typography
                level="body1Medium"
                textColor={themes.common.black}
                textAlign="start"
              >
                <F id="why" defaultMessage="¿Why?" />
              </Typography>
            </Box>
            <Box>
              <Typography
                level="body1"
                textColor={themes.common.black}
                textAlign="start"
              >
                <F
                  id="why.you.need2"
                  defaultMessage="Un sistema de diseño, como elaboraremos y explicaremos a lo largo de esta página, te ahorrará mucho tiempo de producción y te quitará muchos dolores de cabeza de comunicación entre los equipos de diseño y desarrollo."
                />
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm:"60%"},
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "80px",
          }}
        >
          <Typography
            level="body1"
            textColor={themes.common.black}
            textAlign="start"
          >
            <F
              id="design.text"
              defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempor velit at ante tincidunt, vel sollicitudin leo posuere. Nunc varius tellus non ligula vehicula, id blandit quam consectetur. Cras efficitur posuere dolor, sit amet feugiat lectus maximus non. Nullam lacinia fringilla leo, id malesuada sem rhoncus a. Integer iaculis diam id ligula varius, et volutpat sapien imperdiet. Morbi at efficitur massa. Maecenas lacinia tristique pulvinar. Vestibulum in elit sit amet nulla efficitur placerat. Sed vel semper lectus, vel dignissim purus. Integer eget volutpat enim.F"
            />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DesignSystem;
