import { Box, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
// import FooterPortfolio from './FooterPortfolio'
import { FormattedMessage } from "react-intl";
import WhatsApp from "../Components/UI/Icons/WhatsApp";
import Email from "../Components/UI/Icons/EmailIcon";

const InfoDisplayButton = () => {
  const F = FormattedMessage;
  const [open, setOpen] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [textColor, setTextColor] = useState("#232323");

  const [showDetails, setShowDetails] = useState<boolean>(false);

  useEffect(() => {
    if (open === true) {
      const timeout1 = setTimeout(() => {
        setTextColor("#FAFAFA");
      }, 500);
      return () => clearTimeout(timeout1);
    } else {
      setTextColor("#232323");
    }
  }, [open]);

  useEffect(() => {
    if (open === true) {
      const timeout1 = setTimeout(() => {
        setShowDetails(true);
      }, 200);
      return () => clearTimeout(timeout1);
    } else {
      const timeout2 = setTimeout(() => {
        setShowDetails(false);
      }, 600);
      return () => clearTimeout(timeout2);
    }
  }, [open]);

  const textStyles = {
    transition: "opacity 0.1s ease-in-out",
    animation: showDetails ? "bounce 0.6s forwards" : "none",
    opacity: showDetails ? 1 : 0,
    "@keyframes bounce": {
      "0%": {
        transform: "translateY(100%)",
      },
      "100%": {
        transform: "translateY(0%)",
      },
    },
  };

  const textStyles2 = {
    transition: "opacity 0.1s ease-in-out",
    animation: showDetails ? "slideDown 0.5s forwards" : "none",
    opacity: showDetails ? 1 : 0,
    "@keyframes slideDown": {
      "0%": {
        opacity: 0,
        transform: "translateY(-20px);",
      },
      "50%": {
        opacity: 1,
      },
      "100%": {
        opacity: 0,
        transform: "translateY(20px);",
      },
    },
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100%", p: 1 }}
    >
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", height: "100%" }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={() => setOpen((prev) => !prev)}
          sx={{
            width: open ? "100%" : "170px",
            height: open ? "100%" : "46px",
            background:
              "linear-gradient(90deg, #4BB0FF -45.79%, #4893FF 153.58%)",
            borderRadius: "16px",
            cursor: "pointer",
            transition: { xs: "all 0.6s ease-in-out" , sm: "all 0.9s ease-in-out"},
            position: "relative",
          }}
        >
          <Typography
            sx={{
              display: { xs: "none", md: "flex" },
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#ffffff",
              fontFamily: "Armin Grotesk",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: open ? "20px" : "14px",
              lineHeight: "20px",
              textTransform: "uppercase",
              opacity: open ? 1 : 0,
              whiteSpace: "nowrap",
              transition: "opacity 600ms ease, font-size 600ms ease",
              marginTop: { xs: "-60px", sm: "-30px" }
            }}
          >
            <F id="info.button" defaultMessage="Contact" />
          </Typography>
          <Typography
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#ffffff",
              fontFamily: "Armin Grotesk",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: open ? "14px" : "20px",
              lineHeight: "20px",
              textTransform: "uppercase",
              opacity: open ? 0 : 1,
              whiteSpace: "nowrap",
              transition: "opacity 600ms ease, font-size 600ms ease",
            }}
          >
            <F id="info.button" defaultMessage="Contact" />
          </Typography>
          {showDetails && (
            <Box mt={12} marginBottom={"20px"} width="80%" sx={open ? textStyles : textStyles2}>
              <Typography
                level="body1Medium"
                textColor={"#ffffff"}
                sx={{
                  textAlign: "center",
                  marginTop: { sm: "0px", md: "10px", lg: "20px"}
                }}
              >
                <F
                  id="info.button.text2"
                  defaultMessage="¿De qué forma deseas contactárnos y continuar la conversación?"
                />
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row"},
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  textAlign: "center",
                  mt: 4,
                  gap: "32px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <Box>
                    <WhatsApp width={23.98} height={23.98} />
                  </Box>
                  <Box>
                    <Typography level="body1" textColor={"#FFFFFF"}>
                     WhatsApp
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <Box>
                    <Email width={23.98} height={23.98} />
                  </Box>
                  <Box>
                    <Typography level="body1" textColor={"#FFFFFF"}>
                      hey@hermescorp.io
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {/* <Box position="absolute" bottom={0} width="100%">
          <FooterPortfolio textColor={textColor} />
        </Box> */}
      </Box>
    </Box>
  );
};

export default InfoDisplayButton;
