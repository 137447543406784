import { useEffect, useRef, useState } from "react";
import './Caroussel.css'
import { Box, Typography, useTheme } from "@mui/joy";
// import card1 from './carousselBackgrounds/card1.png'
// import card2 from './carousselBackgrounds/card2.png'
// import card3 from './carousselBackgrounds/card3.png'
// import card4 from './carousselBackgrounds/card4.png'
// import card5 from './carousselBackgrounds/card5.png'
// import FooterPortfolio from "../FooterPortfolio";

const Caroussel = () => {

  /*--------------------
  Vars
  --------------------*/
  let progress = 50;
  let startX = 0;
  let active = 0;
  let isDown = false;

  /*--------------------
  Contants
  --------------------*/
  const speedWheel = 0.08;
  const speedDrag = -0.1;

  /*--------------------
  Get Z
  --------------------*/
  const getZindex = (array: any, index: any) =>
    array.map((_: any, i: any) =>
      index === i ? array.length : array.length - Math.abs(index - i)
    );

  /*--------------------
  Items
  --------------------*/
  const itemsContent = [
    {
      title: "Countries we operate",
      num: "4",
      sub: "",
      img: "/carousselBackgrounds/card1.png",
    },
    {
      title: "Code lines writen",
      num: "99k",
      sub: "",
      img: "/carousselBackgrounds/card2.png",
    },
    {
      title: "Experience",
      num: "10+",
      sub: "years",
      img: "/carousselBackgrounds/card3.png",
    },
    {
      title: "Making great things since",
      num: "2013",
      sub: "",
      img: "/carousselBackgrounds/card3.png",
    },
    {
      title: "Our contributors",
      num: "50+",
      sub: "",
      img: "/carousselBackgrounds/card3.png",
    },
  ];
  const [$items, setItems] = useState<any>([])
  const cursor1 = useRef<HTMLDivElement>(null);
  const cursor2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setItems(document.querySelectorAll(".carousel-item"))
  }, [])

  const displayItems = (item: any, index: any, active: any) => {
    const zIndex = getZindex(Array.from($items), active)[index];
    item.style.setProperty("--zIndex", zIndex);
    item.style.setProperty("--active", (index - active) / $items.length);
  };

  /*--------------------
  Animate
  --------------------*/
  const animate = () => {
    progress = Math.max(0, Math.min(progress, 100));
    active = Math.floor((progress / 100) * ($items.length - 1));

    $items.forEach((item: any, index: number) =>
      displayItems(item, index, active)
    );
  };
  animate();

  /*--------------------
  Click on Items
  --------------------*/
  useEffect(() => {
    if ($items.length === 0) return
    $items.forEach((item: any, i: number) => {
      item.addEventListener("click", () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        progress = (i / $items.length) * 100 + 20;
        animate();
      });
    });
  }, [$items]);

  /*--------------------
  Handlers
  --------------------*/
  const handleWheel = (e: any) => {
    const wheelProgress = e.deltaY * speedWheel;
    progress = progress + wheelProgress;
    animate();
  };

  const handleMouseMove = (e: any) => {
    if (e.type === "mousemove") {
      if (cursor1.current === null || cursor2.current === null) return
      cursor1.current.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
      cursor2.current.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
    }
    if (!isDown) return;
    const x = e.clientX || (e.touches && e.touches[0].clientX) || 0;
    const mouseProgress = (x - startX) * speedDrag;
    progress = progress + mouseProgress;
    startX = x;
    animate();
  };

  const handleMouseDown = (e: any) => {
    isDown = true;
    startX = e.clientX || (e.touches && e.touches[0].clientX) || 0;
  };

  const handleMouseUp = () => {
    isDown = false;
  };

  const theme = useTheme();

  /*--------------------
  Listeners
  --------------------*/
  useEffect(() => {
    if ($items.length === 0) return;
    document.addEventListener("mousewheel", handleWheel);
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchstart", handleMouseDown);
    document.addEventListener("touchmove", handleMouseMove);
    document.addEventListener("touchend", handleMouseUp);
    return () => {
      document.removeEventListener("mousewheel", handleWheel);
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchstart", handleMouseDown);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("touchend", handleMouseUp);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$items]);


  return (
    <Box
      sx={{
        height: "100dvh",
        overflow: "hidden",
        width: "100%"
        // fontFamily: '"Roboto", serif',
        // background: "#ffffff",
      }}
    >
      <Box
        sx={{
          height: "100%",
          // position: "relative",
          // zIndex: 1,
          overflow: "hidden",
          pointerEvents: "none",
        }}
      >
        {itemsContent.map((item, index) => {
          return (
            <Box className="carousel-item" key={index}>
              <Box className="carousel-box">
                <Typography zIndex="1" level="h6Bold" textColor={theme.vars.palette.primary[800]}>{item.title}</Typography>
                <Typography zIndex="1" level="h1" textColor={theme.vars.palette.primary[800]}>{item.num}</Typography>
                <Typography zIndex="1" level="h6Bold" textColor={theme.vars.palette.primary[800]}>{item.sub}</Typography>
                <img alt="caroussel-item" src={item.img} />
              </Box>
            </Box>
          );
        })}
      </Box>
      <div ref={cursor1} className="cursor"></div>
      <div ref={cursor2} className="cursor cursor2"></div>
      {/* <FooterPortfolio /> */}
    </Box>
  );
}

export default Caroussel