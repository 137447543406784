import { ReactNode, useEffect, useRef } from "react";
import "./InfiniteScroll.css";
import { Box, Typography, useTheme } from "@mui/joy";
// import FooterPortfolio from "../FooterPortfolio";

const TAGS = ["Design", "Define", "Create", "Engineer", "Improve"];
const DURATION = 30000;
const ROWS = 4;
const TAGS_PER_ROW = 5;

const random = (min: any, max: any) =>
  Math.floor(Math.random() * (max - min)) + min;
const shuffle = (arr: any) => [...arr].sort(() => 0.5 - Math.random());

const InfiniteLoopSlider = ({
  children,
  duration,
  reverse = false,
}: {
  children: ReactNode;
  duration: string;
  reverse: number | boolean;
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sliderRef.current === null) return;
    sliderRef.current.style.setProperty("--duration", `${duration}ms`);
    sliderRef.current.style.setProperty(
      "--reverse",
      reverse ? "reverse" : "normal"
    );
  }, [duration, reverse]);

  return (
    <div className="loop-slider" ref={sliderRef}>
      <div className="inner">
        {children}
        {children}
      </div>
    </div>
  );
};

const Tag = ({ text }: { text: string }) => <div className="tag">{text}</div>;

export const InfiniteScroll = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        // background: "radial-gradient(circle, #95B9FF, #DCF0FF, #E6F7FF)",
        // backgroundPosition: "50% 50%",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#FFF",
          height:{ sm: "550px", md: "600px"},
          width: { xs: "100%"},
          borderRadius: "64px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingY: "60px",
          boxShadow: "0px 0px 32px rgba(156, 156, 156, 0.25)",
        }}
      >
        <Typography textColor={theme.vars.palette.primary[800]} level="h3" textAlign="center">
          OUR GOAL IS TO HELP YOU
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexShrink: "0",
            flexDirection: "column",
            gap: "1rem 0",
            // position: "relative",
            padding: "1.5rem 0",
            overflow: "hidden",
          }}
        >
          {[...new Array(ROWS)].map((_, i) => (
            <InfiniteLoopSlider
              key={i}
              duration={random(DURATION - 5000, DURATION + 5000)}
              reverse={i % 2}
            >
              {shuffle(TAGS)
                .slice(0, TAGS_PER_ROW)
                .map((tag) => (
                  <Tag text={tag} key={tag} />
                ))}
            </InfiniteLoopSlider>
          ))}
        </Box>

        <Typography textColor={theme.vars.palette.primary[800]} level="h3" textAlign="center">
          YOUR GOALS
        </Typography>
      </Box>
      {/* <FooterPortfolio /> */}
    </Box>
  );
};
