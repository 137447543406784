import React, { useState } from "react";

interface UIContext {
  language: string;
  setLanguage: Function;
  position: string;
  setPosition: Function;
  visible: boolean;
  setVisible: Function;
  openModal: boolean;
  setOpenModal: Function;
  theme: string;
  setTheme: Function;
}

let ContextState: UIContext = {
  language: "en",
  setLanguage: () => {},
  position: "Home",
  setPosition: () => {},
  visible: true,
  setVisible: () => {},
  openModal: false,
  setOpenModal: () => {},
  theme: "",
  setTheme: () => {}
};

const Context = React.createContext(ContextState);
type PropsPage = { children: React.ReactNode };

export const ContextProvider = (props: PropsPage) => {

  const [language, setLanguage] = useState<string>("en");
  const [position, setPosition] = useState<string>("");
  const [visible , setVisible] = useState<boolean>(true);
  const [openModal , setOpenModal] = useState<boolean>(false);
  const [theme , setTheme] = useState<string>("light");

  return (
    <Context.Provider
      value={{
        language,
        setLanguage,
        position,
        setPosition,
        visible,
        setVisible,
        openModal,
        setOpenModal, 
        theme, 
        setTheme
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default Context;
