import { Box } from "@mui/joy";
import { ElementType, createContext, useState, useContext, ReactNode } from "react";
import Spanish from "../../Languages/esp.json";
import English from "../../Languages/eng.json";
import PageModal from "../UI/UIComponents/PageModal";
import Context from "./Context";

interface modal {
  open: boolean;
  ComponentModal: ReactNode;
}

interface initialUIState {
  buttonPageModal: modal;
  openPageModal: Function;
  closePageModal: Function;
}

let initialUIState: initialUIState = {
  buttonPageModal: {
    open: false,
    ComponentModal: <></>,
  },
  openPageModal: () => {},
  closePageModal: () => {},
};

const UIContext = createContext(initialUIState);
type PropsPage = { children: React.ReactNode };

export const UIContextProvider = (props: PropsPage) => {

  const { language } = useContext(Context);

  const locale = navigator.language;
  let lang: any;

  if (language === "en") {
    lang = English;
  } else {
    lang = Spanish;
  }

  const [buttonPageModal, setButtonPageModal] = useState<modal>({
    open: false,
    ComponentModal: <></>
  })

  const openPageModal = (ComponentModal: ReactNode) => {
    setButtonPageModal({
      open: true,
      ComponentModal,
    });
  }

  const closePageModal = () => {
    setButtonPageModal((prev) => {
      return {
        ...prev,
        open: false,
      };
    });
  }

  return (
    <Box
      sx={{
        height: "100dvh",
        overflowY: "hidden",
      }}
    >
      <Box sx={{ maxHeight: '100dvh', overflow: buttonPageModal.open ? 'hidden' : 'auto'}}>
        <UIContext.Provider
          value={{
            buttonPageModal,
            openPageModal,
            closePageModal,
          }}
        >
          {props.children}
        </UIContext.Provider>
      </Box>
      {buttonPageModal.open ? (
        <PageModal
          ComponentModal={buttonPageModal.ComponentModal}
          closePageModal={closePageModal}
        />
      ) : (
        <></>
      )}
    </Box>
  );
}

export default UIContext;