import { motion } from "framer-motion";
import { useContext, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/joy";
import Context from "../Components/Context/Context";
// import FooterPortfolio from './FooterPortfolio';

const listVariants = {
  expanded: {
    transition: { staggerChildren: 0.1, delayChildren: 0.6 },
  },
  collapsed: {
    transition: { staggerChildren: 0.08, staggerDirection: -1 },
  },
};

const itemVariants = {
  expanded: { opacity: 1, x: 0 },
  collapsed: { opacity: 0, x: "-50%" },
};

const AnimationExample = () => {
  const { theme } = useContext(Context);
  const themeP = useTheme();
  const themes = themeP.vars.palette;
  const [isExpanded, setIsExpanded] = useState(false);

  const headphones = [
    {
      image: "/briefcase/animation1.jpg",
      title: "Sony",
      subtitle: "Wh-1000xm4",
      amount: "6,499",
    },
    {
      image: "/briefcase/animation2.jpg",
      title: "Sennheiser",
      subtitle: "Hd 450BT",
      amount: "3,600",
    },
    {
      image: "/briefcase/animation3.jpg",
      title: "Apple",
      subtitle: "Airpods max",
      amount: "5,590",
    },
    {
      image: "/briefcase/animation4.jpg",
      title: "Beats",
      subtitle: "Beats studio 3",
      amount: "5,590",
    },
  ];

  const computers = [
    {
      image: "/briefcase/animation5.jpg",
      title: "Apple",
      subtitle: "Macbook pro",
      amount: "32,499",
    },
    {
      image: "/briefcase/animation6.jpg",
      title: "Alienware",
      subtitle: "Alienware M15R7",
      amount: "45,530",
    },
    {
      image: "/briefcase/animation7.jpg",
      title: "Samsung",
      subtitle: "Notebook 9 pro",
      amount: "59,900",
    },
    {
      image: "/briefcase/animation8.jpg",
      title: "Lenovo",
      subtitle: "Thinkpad x1",
      amount: "47,999",
    },
  ];

  return (
    <Box
      sx={{
        backgroundPosition: "50% 50%",
        minHeight: "100%",
        width: { xs: "100%", md: "90%" },
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          background: "radial-gradient(circle, #95B9FF, #DCF0FF, #E6F7FF)",
          backgroundPosition: "50% 50%",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "100%",
            position: "absolute",
            top: { xs: 8, lg: 186 },
            zIndex: "999",
          }}
        >
          <Grid container width={{ xs: "90vw", md: "100%" }}>
            <Grid
              onClick={() => setIsExpanded(false)}
              xs={6}
              display="flex"
              justifyContent="center"
              py={3}
              sx={{
                borderBottom: !isExpanded
                  ? "3px solid #4893FF"
                  : "3px solid #99C3FF",
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  display: { xs: "none", sm: "flex" },
                  fontStyle: "normal",
                  fontWeight: 900,
                  fontSize: "32px",
                  lineHeight: "120%",
                  color: !isExpanded ? "#4893FF" : "#99C3FF",
                }}
              >
                Headphones
              </Typography>
              <Typography
                sx={{
                  display: { xs: "flex", sm: "none" },
                  fontStyle: "normal",
                  fontWeight: 900,
                  fontSize: "24px",
                  lineHeight: "120%",
                  color: !isExpanded ? "#4893FF" : "#99C3FF",
                }}
              >
                Headphones
              </Typography>
            </Grid>
            <Grid
              onClick={() => setIsExpanded(true)}
              xs={6}
              display="flex"
              justifyContent="center"
              py={3}
              sx={{
                borderBottom: isExpanded
                  ? "3px solid #4893FF"
                  : "3px solid #99C3FF",
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  display: { xs: "none", sm: "flex" },
                  fontStyle: "normal",
                  fontWeight: 900,
                  fontSize: "32px",
                  lineHeight: "120%",
                  color: isExpanded ? "#4893FF" : "#99C3FF",
                }}
              >
                Computers
              </Typography>
              <Typography
                sx={{
                  display: { xs: "flex", sm: "none" },
                  fontStyle: "normal",
                  fontWeight: 900,
                  fontSize: "24px",
                  lineHeight: "120%",
                  color: isExpanded ? "#4893FF" : "#99C3FF",
                }}
              >
                Computers
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <motion.div
          variants={listVariants}
          initial="collapsed"
          animate={isExpanded ? "expanded" : "collapsed"}
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: 72,
          }}
        >
          <Box mt={{ xs: 4, lg: 30 }}>
            {computers.map((item, index) => (
              <motion.div key={index} variants={itemVariants}>
                {/* >= SM  */}
                <Grid
                  container
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    backgroundColor: theme === "light" ? themes.common.white : themes.neutral[800], 
                    borderRadius: "32px",
                    width: { xs: "90vw", md: "66vw" },
                    height: "100%",
                    mb: 2,
                  }}
                >
                  <Grid xs={0} sm={2}>
                    <img
                      alt="alt"
                      style={{
                        borderTopLeftRadius: "32px",
                        borderBottomLeftRadius: "32px",
                      }}
                      src={item.image}
                      height="104px"
                      width="160px"
                    ></img>
                  </Grid>
                  <Grid
                    xs={6}
                    sm={5}
                    px={2}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Typography
                      level="h6Bold"
                      textColor={ theme === "light" ? themes.neutral[500] : themes.common.white }
                      sx={{
                        textTransform: "uppercase",
                        textAlign: "start",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      level="body1Bold"
                      textColor={ theme === "light" ? themes.primary[800] : themes.primary[500]}
                      sx={{
                        textAlign: "start",
                      }}
                    >
                      {item.subtitle}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={6}
                    sm={5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        backgroundColor: "#E7F1FF",
                        py: 2,
                        borderRadius: "32px",
                        width: "100%",
                        mr: 2,
                      }}
                    >
                      <Typography
                        level="h6Bold"
                        textColor={themes.primary[400]}
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        $ {item.amount} MXN
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                {/* XS */}
                <Grid
                  container
                  sx={{
                    display: { xs: "flex", sm: "none" },
                    backgroundColor: theme === "light" ? themes.common.white : themes.neutral[800], 
                    borderRadius: "32px",
                    width: { xs: "90vw", md: "66vw" },
                    height: "100%",
                    mb: 2,
                  }}
                >
                  <Grid xs={12}>
                    <Box sx={{ display: "flex", padding: 1 }}>
                      <Box>
                        <img
                          alt="alt"
                          style={{
                            borderTopLeftRadius: "32px",
                            borderBottomLeftRadius: "32px",
                          }}
                          src={item.image}
                          height="104px"
                          width="160px"
                        ></img>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "start",
                          marginLeft: 1
                        }}
                      >
                        <Typography
                          level="h6Bold"
                          textColor={ theme === "light" ? themes.neutral[500] : themes.common.white }
                          sx={{
                            textTransform: "uppercase",
                            textAlign: "start",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          level="body1Bold"
                          textColor={ theme === "light" ? themes.primary[800] : themes.primary[500]}
                          sx={{
                            textAlign: "start",
                          }}
                        >
                          {item.subtitle}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        backgroundColor: "#E7F1FF",
                        py: 2,
                        borderRadius: "32px",
                        width: "100%",
                        padding: 3,
                      }}
                    >
                      <Typography
                        level="h6Bold"
                        textColor={themes.primary[400]}
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        $ {item.amount} MXN
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </motion.div>
            ))}
          </Box>
        </motion.div>
        <motion.div
          variants={listVariants}
          initial="collapsed"
          animate={!isExpanded ? "expanded" : "collapsed"}
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: 72,
          }}
        >
          <Box mt={{ xs: 4, lg: "230px", xl: 30 }}>
            {headphones.map((item, index) => (
              <motion.div key={index} variants={itemVariants}>
                {/* SM A DESK */}
                <Grid
                  container
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    backgroundColor: theme === "light" ? themes.common.white : themes.neutral[800],
                    borderRadius: "32px",
                    width: { xs: "90vw", md: "66vw" },
                    height: "100%",
                    mb: 2,
                  }}
                >
                  <Grid xs={0} sm={2}>
                    <img
                      alt="alt"
                      style={{
                        borderTopLeftRadius: "32px",
                        borderBottomLeftRadius: "32px",
                      }}
                      src={item.image}
                      height={index !== 1 ? "104px" : "134px"}
                      width="160px"
                    ></img>
                  </Grid>
                  <Grid
                    xs={6}
                    sm={5}
                    px={2}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Typography
                      level="h6Bold"
                      textColor={ theme === "light" ? themes.neutral[500] : themes.common.white }
                      sx={{
                        textTransform: "uppercase",
                        textAlign: "start",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      level="body1Bold"
                      textColor={ theme === "light" ? themes.primary[800] : themes.primary[500]}
                      sx={{
                        textTransform: "uppercase",
                        textAlign: "start",
                      }}
                    >
                      {item.subtitle}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={6}
                    sm={5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    {index === 1 && (
                      <Typography
                        level="body1"
                        textColor={themes.danger[500]}
                        sx={{
                          textAlign: "center",
                          mb: 1,
                        }}
                      >
                        $ 4,500 mxn 30% Off
                      </Typography>
                    )}
                    <Box
                      sx={{
                        backgroundColor: "#E7F1FF",
                        py: 2,
                        borderRadius: "32px",
                        width: "100%",
                        mr: 2,
                      }}
                    >
                      <Typography
                        level="buttonBig"
                        textColor={themes.primary[400]}
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        $ {item.amount} MXN
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                {/* XS */}
                <Grid
                  container
                  sx={{
                    display: { xs: "flex", sm: "none" },
                    backgroundColor: theme === "light" ? themes.common.white : themes.neutral[800], 
                    borderRadius: "32px",
                    width: { xs: "90vw", md: "66vw" },
                    height: "100%",
                    mb: 2,
                  }}
                >
                  <Grid xs={12}>
                    <Box sx={{ display: "flex", padding: 1 }}>
                      <Box>
                        <img
                          alt="alt"
                          style={{
                            borderTopLeftRadius: "32px",
                            borderBottomLeftRadius: "32px",
                          }}
                          src={item.image}
                          height="104px"
                          width="160px"
                        ></img>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "start",
                          marginLeft: 1
                        }}
                      >
                        <Typography
                          level="h6Bold"
                          textColor={ theme === "light" ? themes.neutral[500] : themes.common.white }
                          sx={{
                            textTransform: "uppercase",
                            textAlign: "start",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          level="body1Bold"
                          textColor={ theme === "light" ? themes.primary[800] : themes.primary[500]}
                          sx={{
                            textAlign: "start",
                          }}
                        >
                          {item.subtitle}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        backgroundColor: "#E7F1FF",
                        py: 2,
                        borderRadius: "32px",
                        width: "100%",
                        padding: 3,
                      }}
                    >
                      <Typography
                        level="h6Bold"
                        textColor={themes.primary[400]}
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        $ {item.amount} MXN
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </motion.div>
            ))}
          </Box>
        </motion.div>
      </Box>
      {/* <FooterPortfolio /> */}
    </Box>
  );
};

export default AnimationExample;
