import React from "react";

const GoBackArrow = ({width, height} : { width: string, height: string }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.22003 10L7.5667 5.65334C8.08003 5.14001 8.92003 5.14001 9.43336 5.65334L13.78 10"
        stroke="#4893FF"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GoBackArrow;
