import { Box, Button, Link, Typography, useTheme } from "@mui/joy";
import React, { useMemo, useState } from "react";
// import Footer from '../FooterPortfolio'

type mode = "LIGHT" | "DARK";

const vcardQR = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectedMode, setSelectedMode] = useState<mode>("LIGHT");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [hover, setHover] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme();
  const themes = theme.vars.palette;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const textColor = useMemo(() => {
    switch (selectedMode) {
      case "DARK":
        return "#FAFAFA";
      case "LIGHT":
        return "#232323";
    }
  }, [selectedMode]);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: { xs: "200px", sm: "300px" },
        justifyContent: "center",
        alignItems: "center",
        transition: "all 300ms ease-in",
      }}
    >
      <img
        alt="alt"
        style={{
          borderRadius: "32px",
          paddingBottom: "16px"
        }}
        src="/vcardQRs/QR_image.png"
        height="250px"
        width="250px"
      ></img>
      <Link
        href="https://hermes-vcards.vercel.app/armin"
        target="blank"
        rel="noreferrer"
        sx={{
          "&:hover": {
            textDecoration: "none",
          },
        }}
      >

        <Button
          sx={{
            width: "250px",
            borderRadius: "16px",
            padding: "8px 16px",
            backgroundColor: themes.primary[500],
            transition: "all 300ms ease-in",
            "&:hover": {
              backgroundColor: themes.primary[600],
              textDecoration: "none",
            },
          }}
        >
          <Typography level="body1Bold" textColor={themes.common.white}>
            Scan me
          </Typography>
        </Button>
      </Link>
    </Box>
  );
};

export default vcardQR;
