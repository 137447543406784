// React
import { useContext, useEffect, useState } from "react";

// Styles
import { Box, Button, Typography, useTheme } from "@mui/joy";

// Tools
import { FormattedMessage } from "react-intl";
import TextTransition, { presets } from "react-text-transition";
import { useNavigate } from "react-router-dom";
import Context from "../Context/Context";
import UIContext from "../Context/ui-context";
import Services from "./Services";

const Home: React.ElementType = () => {
  const themes = useTheme();
  const F = FormattedMessage;
  const navigate = useNavigate();

  const { language, setOpenModal, theme } = useContext(Context);
  const { openPageModal, closePageModal } = useContext(UIContext);

  const TEXTSEng = ["Help", "Design", "Engineer", "Develop"];
  const TEXTSEsp = ["Planear", "Trazar", "Diseñar", "Lograr"];

  const [index, setIndex] = useState<number>(0);

  const goToServices = () => {
    openPageModal(<Services />);
  };

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index: any) => index + 1),
      2000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "150px",
        height: { xs: "100dvh", sm: "auto"},
      }}
    >
      {/* DESKTOP LG */}
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "none", lg: "flex" },
          gap: "15px",
        }}
      >
        <Typography
          level="display1"
          textColor={
            theme === "light"
              ? themes.vars.palette.primary[800]
              : themes.vars.palette.primary[50]
          }
          textAlign={"start"}
          sx={{ marginLeft: "40px" }}
        >
          <F id="home.title1" defaultMessage="Our goal is to" />
        </Typography>
        <Typography
          level="display1"
          textColor={themes.vars.palette.primary[500]}
        >
          <TextTransition springConfig={presets.wobbly}>
            {language === "en" ? (
              <>{TEXTSEng[index % TEXTSEng.length]}</>
            ) : (
              <>{TEXTSEsp[index % TEXTSEsp.length]}</>
            )}
          </TextTransition>
        </Typography>
      </Box>
      {/* DESKTOP */}
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "flex", lg: "none" },
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Typography
          level="display1"
          textColor={
            theme === "light"
              ? themes.vars.palette.primary[800]
              : themes.vars.palette.primary[50]
          }
          textAlign={"start"}
          sx={{ marginLeft: "40px" }}
        >
          <F id="home.title1" defaultMessage="Our goal is to" />
        </Typography>
        <Typography
          level="display1"
          textColor={themes.vars.palette.primary[500]}
          sx={{ marginLeft: "40px", marginBottom: "12px" }}
        >
          <TextTransition springConfig={presets.wobbly}>
            {language === "en" ? (
              <>{TEXTSEng[index % TEXTSEng.length]}</>
            ) : (
              <>{TEXTSEsp[index % TEXTSEsp.length]}</>
            )}
          </TextTransition>
        </Typography>
      </Box>
      {/* MEDIUM */}
      <Box
        sx={{
          width: "95%",
          display: { xs: "none", sm: "flex", md: "none" },
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Typography
          level="display1"
          textColor={
            theme === "light"
              ? themes.vars.palette.primary[800]
              : themes.vars.palette.primary[50]
          }
          textAlign={"start"}
          sx={{ marginLeft: "40px" }}
        >
          <F id="home.title1" defaultMessage="Our goal is to" />
        </Typography>
        <Typography
          level="display1"
          textColor={themes.vars.palette.primary[500]}
          sx={{ marginLeft: "40px" }}
          textAlign={"start"}
        >
          <TextTransition springConfig={presets.wobbly}>
            {language === "en" ? (
              <>{TEXTSEng[index % TEXTSEng.length]}</>
            ) : (
              <>{TEXTSEsp[index % TEXTSEsp.length]}</>
            )}
          </TextTransition>
        </Typography>
      </Box>
      {/* xSMALL */}
      <Box
        sx={{
          width: "70%",
          display: { xs: "flex", sm: "none", md: "none" },
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Typography
          level="h2"
          textColor={
            theme === "light"
              ? themes.vars.palette.primary[800]
              : themes.vars.palette.primary[50]
          }
          textAlign={"start"}
          sx={{ marginLeft: "20px", textTransform: "uppercase" }}
        >
          <F id="home.title1" defaultMessage="Our goal is to" />
        </Typography>
        <Typography
          level="h2"
          textColor={themes.vars.palette.primary[500]}
          textAlign={"start"}
          sx={{ marginLeft: "20px", textTransform: "uppercase" }}
        >
          <TextTransition springConfig={presets.wobbly}>
            {language === "en" ? (
              <>{TEXTSEng[index % TEXTSEng.length]}</>
            ) : (
              <>{TEXTSEsp[index % TEXTSEsp.length]}</>
            )}
          </TextTransition>
        </Typography>
      </Box>
      <Box sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
        <Typography
          level="display1"
          textColor={
            theme === "light"
              ? themes.vars.palette.primary[800]
              : themes.vars.palette.primary[50]
          }
          textAlign={"start"}
          sx={{ marginLeft: "40px" }}
        >
          <F id="home.title2" defaultMessage="your goals" />
        </Typography>
      </Box>
      <Box
        sx={{ width: "95%", display: { xs: "none", sm: "flex", md: "none" } }}
      >
        <Typography
          level="display1"
          textColor={
            theme === "light"
              ? themes.vars.palette.primary[800]
              : themes.vars.palette.primary[50]
          }
          textAlign={"start"}
          sx={{
            marginLeft: "40px",
            textTransform: "uppercase",
            marginTop: "12px",
          }}
        >
          <F id="home.title2" defaultMessage="your goals" />
        </Typography>
      </Box>
      <Box
        sx={{ width: "70%", display: { xs: "flex", sm: "none", md: "none" } }}
      >
        <Typography
          level="h2"
          textColor={
            theme === "light"
              ? themes.vars.palette.primary[800]
              : themes.vars.palette.primary[50]
          }
          textAlign={"start"}
          sx={{
            marginLeft: "20px",
            textTransform: "uppercase",
            marginTop: "12px",
          }}
        >
          <F id="home.title2" defaultMessage="your goals" />
        </Typography>
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          gap: "24px",
          paddingLeft: "40px",
          paddingTop: "40px",
        }}
      >
        <Button
          sx={{
            width: "auto",
            height: "47px",
            backgroundColor:
              theme === "light"
                ? themes.vars.palette.primary[100]
                : themes.vars.palette.primary[500],
            transition: "all 300ms ease-in",
            "&:hover": {
              borderRadius: "8px",
              backgroundColor:
                theme === "light"
                  ? themes.vars.palette.neutral[100]
                  : themes.vars.palette.neutral[500],
            },
          }}
          onClick={() => goToServices()}
        >
          <Typography
            level="buttonBig"
            textColor={
              theme === "light"
                ? themes.vars.palette.primary[700]
                : themes.vars.palette.primary[100]
            }
          >
            <F id="home.button1" defaultMessage="Our services" />
          </Typography>
        </Button>
        <Button
          onClick={() => setOpenModal(true)}
          sx={{
            width: "140px",
            height: "47px",
            backgroundColor:
              theme === "light"
                ? themes.vars.palette.primary[100]
                : themes.vars.palette.primary[500],
            transition: "all 300ms ease-in",
            "&:hover": {
              borderRadius: "8px",
              backgroundColor:
                theme === "light"
                  ? themes.vars.palette.neutral[100]
                  : themes.vars.palette.neutral[500],
            },
          }}
        >
          <Typography
            level="buttonBig"
            textColor={
              theme === "light"
                ? themes.vars.palette.primary[700]
                : themes.vars.palette.primary[100]
            }
          >
            <F id="home.button2" defaultMessage="Contact Us" />
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "flex", md: "none" },
          gap: "24px",
          paddingLeft: "40px",
          paddingTop: "40px",
        }}
      >
        <Button
          sx={{
            width: "auto",
            height: "47px",
            backgroundColor:
              theme === "light"
                ? themes.vars.palette.primary[100]
                : themes.vars.palette.primary[500],
            transition: "all 300ms ease-in",
            "&:hover": {
              borderRadius: "8px",
              backgroundColor:
                theme === "light"
                  ? themes.vars.palette.neutral[100]
                  : themes.vars.palette.neutral[500],
            },
          }}
          onClick={() => goToServices()}
        >
          <Typography
            level="buttonBig"
            textColor={
              theme === "light"
                ? themes.vars.palette.primary[700]
                : themes.vars.palette.primary[100]
            }
          >
            <F id="home.button1" defaultMessage="Our services" />
          </Typography>
        </Button>
        <Button
          onClick={() => setOpenModal(true)}
          sx={{
            width: "140px",
            height: "47px",
            backgroundColor:
              theme === "light"
                ? themes.vars.palette.primary[100]
                : themes.vars.palette.primary[500],
            transition: "all 300ms ease-in",
            "&:hover": {
              borderRadius: "8px",
              backgroundColor:
                theme === "light"
                  ? themes.vars.palette.neutral[100]
                  : themes.vars.palette.neutral[500],
            },
          }}
        >
          <Typography
            level="buttonBig"
            textColor={
              theme === "light"
                ? themes.vars.palette.primary[700]
                : themes.vars.palette.primary[100]
            }
          >
            <F id="home.button2" defaultMessage="Contact Us" />
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          display: { xs: "flex", sm: "none", md: "none" },
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          gap: "18px",
          paddingLeft: "20px",
          paddingTop: "40px",
          paddingBottom: { xs: "80px", sm: "0px"}
        }}
      >
        <Button
          sx={{
            width: { xs: "70%", sm: "130px"},
            height: "47px",
            backgroundColor:
              theme === "light"
                ? themes.vars.palette.primary[100]
                : themes.vars.palette.primary[500],
            transition: "all 300ms ease-in",
            "&:hover": {
              borderRadius: "8px",
              backgroundColor:
                theme === "light"
                  ? themes.vars.palette.neutral[100]
                  : themes.vars.palette.neutral[500],
            },
          }}
          onClick={() => goToServices()}
        >
          <Typography
            level="buttonSmall"
            textColor={
              theme === "light"
                ? themes.vars.palette.primary[700]
                : themes.vars.palette.primary[100]
            }
          >
            <F id="home.button1" defaultMessage="Our services" />
          </Typography>
        </Button>
        <Button
          onClick={() => setOpenModal(true)}
          sx={{
            width: { xs: "70%", sm: "130px"},
            height: "47px",
            backgroundColor:
              theme === "light"
                ? themes.vars.palette.primary[100]
                : themes.vars.palette.primary[500],
            transition: "all 300ms ease-in",
            "&:hover": {
              borderRadius: "8px",
              backgroundColor:
                theme === "light"
                  ? themes.vars.palette.neutral[100]
                  : themes.vars.palette.neutral[500],
            },
          }}
        >
          <Typography
            level="buttonSmall"
            textColor={
              theme === "light"
                ? themes.vars.palette.primary[700]
                : themes.vars.palette.primary[100]
            }
          >
            <F id="home.button2" defaultMessage="Contact Us" />
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Home;
