// Assets
import Menu from "../MenuResponsive";
import CircleL from "../../Images/hermes2/backgroundCircleLg.webp";
import CircleM from "../../Images/hermes2/backgroundCircleMD.webp";
import CircleX from "../../Images/hermes2/backgroundCircleXS.webp";

// Component
import PageModalHeader from "./PageModalHeader";

// React
import { useContext, useState } from "react";
import { Outlet } from "react-router-dom";

// Styles
import { Box, useTheme } from "@mui/joy";
import Context from "../../../Context/Context";
import PageModalSocialMedia from "./PageModalSocialMedia";

const LayoutPageModal: React.ElementType = ({ children, closeModal }: { children: any, closeModal: Function }) => {
  const [navbar, setNavbar] = useState<boolean>(false);
  const themes = useTheme();

  const { theme } = useContext(Context);

  return (
    <Box
      component={"main"}
      sx={{
        backgroundColor:
          theme === "light"
            ? themes.vars.palette.primary[50]
            : themes.vars.palette.primary[900],
        backgroundImage: {
          xs: `url(${CircleX})`,
          md: `url(${CircleM})`,
          xl: `url(${CircleL})`,
        },
        backgroundRepeat: "no-repeat",
        backgroundPosition: "start",
      }}
    >
      <Box>
        <PageModalHeader
          closeModal={closeModal}
          openMenu={navbar}
          setOpenMenu={setNavbar}
        />
        <Box>
          {!navbar ? (
            <></>
          ) : (
            <>
              <Box sx={{ position: "absolute", zIndex: 10 }}>
                <Outlet />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Box sx={{ position: "relative", zIndex: 1000 }}>
                  <Menu setOpenMenu={setNavbar} />
                </Box>
              </Box>
            </>
          )}
        </Box>
        {children}
        <Outlet />
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            position: "fixed",
            right: 0,
            top: 0,
            marginRight: 5,
          }}
        >
          <PageModalSocialMedia closeModal={closeModal} />
        </Box>
      </Box>
    </Box>
  );
};

export default LayoutPageModal;
