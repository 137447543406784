import { extendTheme } from "@mui/joy/styles";

// import themeCollection from "./themes/themeCollection";

const cssVarPrefix = "hermes";

declare module "@mui/joy/styles" {
  interface BreakpointOverrides {
    xxl: true;
  }
}

const hermesPalette = {
  common: {
    black: "#101218",
    white: "#ffffff",
  },
  primary: {
    "50": "#f8fbff",
    "100": "#e7f1ff",
    "200": "#cae0ff",
    "300": "#99c3ff",
    "400": "#75adff",
    "500": "#4893ff",
    "600": "#1d6cdd",
    "700": "#0048b0",
    "800": "#00275f",
    "900": "#030b27",
  },
  secondary: {
    "50": "#f8f3ff",
    "100": "#e4ceff",
    "200": "#ccadff",
    "300": "#ba84ff",
    "400": "#b961ff",
    "500": "#b02fff",
    "600": "#9428ff",
    "700": "#7617c1",
    "800": "#470a84",
    "900": "#39084a",
  },
  neutral: {
    "50": "#f6f6f6",
    "100": "#d2d4d8",
    "200": "#b5b8c0",
    "300": "#979ca7",
    "400": "#7a808e",
    "500": "#5f6475",
    "600": "#484f5f",
    "700": "#353a48",
    "800": "#222631",
    "900": "#101218",
  },
  successs: {
    "50": "#e8fef0",
    "100": "#bffcd4",
    "200": "#96fab8",
    "300": "#6cf99c",
    "400": "#43f781",
    "500": "#1ae05e",
    "600": "#15c451",
    "700": "#10933d",
    "800": "#0a6228",
    "900": "#053114",
  },
  warning: {
    "50": "#fffbed",
    "100": "#fff5ce",
    "200": "#ffeeae",
    "300": "#ffe88e",
    "400": "#ffe26f",
    "500": "#ffdb4f",
    "600": "#e7c440",
    "700": "#b1993a",
    "800": "#665820",
    "900": "#332c10",
  },
  danger: {
    "50": "#fee7e9",
    "100": "#fbbcc2",
    "200": "#f9909b",
    "300": "#f76574",
    "400": "#f4394c",
    "500": "#f20e25",
    "600": "#c20b1e",
    "700": "#910816",
    "800": "#61060f",
    "900": "#300307",
  },
};

export const hermesTheme = extendTheme({
  cssVarPrefix: cssVarPrefix,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
    },
  },
  colorSchemes: {
    light: {
      palette: {
        mode: "light",
        ...hermesPalette,

        text: {
          primary: hermesPalette.neutral[800],
          secondary: hermesPalette.neutral[800],
          tertiary: hermesPalette.neutral[800],
        },

        background: {
          body: hermesPalette.primary[50],
        },
        gradients: {
          gradient1: {
            "100": "linear-gradient(90.78deg, #CAE0FF -27.07%, #E4CEFF 100%)",
            "500": "linear-gradient(90.78deg, #4893FF -27.07%, #B961FF 100%)",
          },
        },
      },
    },
    dark: {
      palette: {
        mode: "dark",
        ...hermesPalette,

        text: {
          primary: hermesPalette.neutral[800],
          secondary: hermesPalette.neutral[800],
          tertiary: hermesPalette.neutral[800],
        },

        background: {
          body: "#FFFFFF",
        },
        gradients: {},
      },
    },
  },
  fontFamily: {
    body: "Montserrat",
    display: "Montserrat",
    fallback: "Montserrat, Helvetica, Arial",
  },
  typography: {
    display1: {
      fontFamily: "Montserrat",
      fontSize: "5.5rem",
      fontWeight: "800",
      lineHeight: "100%",
      textTransform: "uppercase",
    },
    display2: {
      fontFamily: "Montserrat",
      fontSize: "4.5rem",
      fontWeight: "800",
      lineHeight: "100%",
      textTransform: "uppercase",
    },
    h1: {
      fontFamily: "Montserrat",
      fontSize: "4rem",
      fontWeight: "800",
      lineHeight: "100%",
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: "Montserrat",
      fontSize: "3rem",
      fontWeight: "800",
      lineHeight: "100%",
      textTransform: "none",
    },
    h3: {
      fontFamily: "Montserrat",
      fontSize: "2.5rem",
      fontWeight: "800",
      lineHeight: "100%",
      textTransform: "none",
    },
    h4: {
      fontFamily: "Montserrat",
      fontSize: "2rem",
      fontWeight: "800",
      lineHeight: "100%",
      textTransform: "none",
    },
    h5: {
      fontFamily: "Montserrat",
      fontSize: "1.5rem",
      fontWeight: "800",
      lineHeight: "100%",
      textTransform: "none",
    },
    h6: {
      fontFamily: "Montserrat",
      fontSize: "1.25rem",
      fontWeight: "400",
      lineHeight: "100%",
      textTransform: "none",
    },
    h6Medium: {
      fontFamily: "Montserrat",
      fontSize: "1.25rem",
      fontWeight: "600",
      lineHeight: "100%",
      textTransform: "none",
    },
    h6Bold: {
      fontFamily: "Montserrat",
      fontSize: "1.25rem",
      fontWeight: "800",
      lineHeight: "100%",
      textTransform: "none",
    },
    buttonBig: {
      fontFamily: "Montserrat",
      fontSize: "1.125rem",
      fontWeight: "800",
      lineHeight: "150%",
      textTransform: "none",
    },
    buttonSmall: {
      fontFamily: "Montserrat",
      fontSize: "1.125rem",
      fontWeight: "600",
      lineHeight: "150%",
      textTransform: "none",
    },
    linkBig: {
      fontFamily: "Montserrat",
      fontSize: "1.125rem",
      fontWeight: "600",
      lineHeight: "150%",
      textTransform: "none",
    },
    linkSmall: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: "600",
      lineHeight: "150%",
      textTransform: "none",
    },
    body1: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "120%",
      textTransform: "none",
    },
    body1Medium: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: "600",
      lineHeight: "150%",
      textTransform: "none",
    },
    body1Bold: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: "800",
      lineHeight: "150%",
      textTransform: "none",
    },
    body2: {
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      fontWeight: "400",
      lineHeight: "150%",
      textTransform: "none",
    },
    body2Medium: {
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      fontWeight: "600",
      lineHeight: "150%",
      textTransform: "none",
    },
    body2Bold: {
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      fontWeight: "800",
      lineHeight: "150%",
      textTransform: "none",
    },
    body3: {
      fontFamily: "Montserrat",
      fontSize: "0.75rem",
      fontWeight: "400",
      lineHeight: "150%",
      textTransform: "none",
    },
    body3Medium: {
      fontFamily: "Montserrat",
      fontSize: "0.75rem",
      fontWeight: "600",
      lineHeight: "150%",
      textTransform: "none",
    },
    body3Bold: {
      fontFamily: "Montserrat",
      fontSize: "0.75rem",
      fontWeight: "800",
      lineHeight: "150%",
      textTransform: "none",
    },
    body4: {
      fontFamily: "Montserrat",
      fontSize: "0.625rem",
      fontWeight: "400",
      lineHeight: "170%",
      textTransform: "none",
    },
    body4Medium: {
      fontFamily: "Montserrat",
      fontSize: "0.625rem",
      fontWeight: "600",
      lineHeight: "170%",
      textTransform: "none",
    },
    body4Bold: {
      fontFamily: "Montserrat",
      fontSize: "0.625rem",
      fontWeight: "800",
      lineHeight: "170%",
      textTransform: "none",
    },
  },
  components: {
    JoySelect: {
      styleOverrides: {
        root: ({ ownerState, theme }: { ownerState: any; theme: any }) => ({
          padding: "14px 24px",
          ...(ownerState.variant === "solid" && {
            ...(ownerState.color === "primary" && {
              "--Icon-color": theme.vars.palette.primary[800],
              backgroundColor: "#FFF4E2",
              color: theme.vars.palette.primary[800],
              ":hover": {
                backgroundColor: theme.vars.palette.primary[100],
              },
            }),
          }),
        }),
        listbox: ({ ownerState, theme }: { ownerState: any; theme: any }) => ({
          "--List-padding": "0px",
          border: "none",
        }),
      },
    },
    JoyOption: {
      styleOverrides: {
        root: ({ ownerState, theme }: { ownerState: any; theme: any }) => ({
          padding: "14px 24px",
          borderRadius: "0px",
          ...(ownerState.variant === "solid" && {
            ...(ownerState.color === "primary" && {
              "--Icon-color": theme.vars.palette.primary[800],
              backgroundColor: "#FFF4E2",
              color: theme.vars.palette.primary[800],
              ":hover": {
                backgroundColor: theme.vars.palette.primary[100],
              },
              "&.MuiOption-highlighted": {
                backgroundColor: theme.vars.palette.primary[100],
              },
            }),
          }),
        }),
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }: { ownerState: any; theme: any }) => ({
          borderRadius: "12px",
          transition: "all 150ms cubic-bezier(0.33, 1, 0.68, 1)",
          "&:hover": {
            borderRadius: "8px",
          },
          ...(ownerState.size === "lg" && {
            padding: "14px 16px",
            width: "fit-content",
          }),
          ...(ownerState.size === "md" && {
            padding: "8px 10px",
            width: "fit-content",
          }),
          ...(ownerState.size === "sm" && {
            padding: "8px 8px",
            width: "fit-content",
          }),

          ...(ownerState.variant === "solid" && {
            ...(ownerState.color === "primary" && {
              background: theme.vars.palette.primary[400],
              ":hover": {
                background: theme.vars.palette.primary[500],
              },
              ":active": {
                background: theme.vars.palette.primary[500],
              },
              ":disabled": {
                backgroundColor: theme.vars.palette.neutral[200],
              },
              color: "#ffffff",
            }),
          }),
          ...(ownerState.variant === "outlined" && {
            ":hover": {
              border: "1px solid",
              background: theme.vars.palette.common.white,
            },
            border: "1px solid",
            borderColor: theme.vars.palette.primary[400],
            background: theme.vars.palette.common.white,
            ":active": {
              background: theme.vars.palette.neutral[100],
            },
            ":disabled": {
              backgroundColor: theme.vars.palette.neutral[100],
            },
            ...(ownerState.color === "primary" && {
              color: theme.vars.palette.primary[400],
              borderColor: theme.vars.palette.primary[400],
            }),
          }),
        }),
      },
    },
  },
});
