// Assets
import GoBackLeftArrow from "../UI/Icons/GoBackLeftArrow";
import Line from "../UI/Icons/Line";

// React
// import { useState } from "react";

// Style
import { Box, Button, Link, Typography, useTheme } from "@mui/joy";

// Tools
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import Context from "../Context/Context";
import { useContext, useEffect, useState } from "react";

const Enrico = () => {
  const { theme } = useContext(Context);
  const F = FormattedMessage;
  const themeP = useTheme();
  const themes = themeP.vars.palette;
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/playground");
  };

  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  const [dots, setDots] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((dots) => (dots === 3 ? 1 : dots + 1));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      component="main"
      sx={{
        height: { xs: "auto" },
        backgroundColor:
          theme === "light" ? themes.primary[50] : themes.primary[900],
      }}
    >
      <Box
        sx={{
          width: { xs: "90dvw", sm: "94dvw", md: "70dvw" },
          marginLeft: { md: "40px" },
          marginX: { xs: "20px" },
          paddingBottom: "130px",
        }}
      >
        <Box>
          <Button
            onClick={() => goBack()}
            sx={{
              width: { xs: "30%", sm: "16%", md: "auto" },
              backgroundColor:
                theme === "light" ? themes.common.white : themes.primary[800],
              transition: "all 300ms ease-in",
              "&:hover": {
                borderRadius: "8px",
                backgroundColor:
                  theme === "light" ? themes.neutral[50] : themes.neutral[700],
              },
            }}
            startDecorator={<GoBackLeftArrow width={"16px"} height={"16px"} />}
          >
            <Typography
              level="h6Bold"
              textColor={themes.primary[400]}
              sx={{ textTransform: "uppercase" }}
            >
              <F id="goBack.back" defaultMessage="Back" />
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "100%",
              paddingTop: "36px",
            }}
          >
            <Typography
              level="h4"
              textColor={
                theme === "light" ? themes.primary[800] : themes.primary[50]
              }
            >
              <F id="enrico" defaultMessage="Enrico" />
            </Typography>
            <Box
              sx={{
                display: "flex",
                paddingTop: "4px",
              }}
            >
              <Line width="550px" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                width: "100%",
                paddingTop: "2px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "83px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor:
                    theme === "light"
                      ? themes.common.white
                      : themes.secondary[800],
                }}
              >
                <Typography
                  level="body3"
                  textColor={
                    theme === "light"
                      ? themes.secondary[500]
                      : themes.secondary[100]
                  }
                >
                  <F id="info.example" defaultMessage="Example" />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "40px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor:
                    theme === "light"
                      ? themes.common.white
                      : themes.secondary[800],
                }}
              >
                <Typography
                  level="body3"
                  textColor={
                    theme === "light"
                      ? themes.secondary[500]
                      : themes.secondary[100]
                  }
                >
                  <F id="info.UI" defaultMessage="UI" />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "73px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor:
                    theme === "light"
                      ? themes.common.white
                      : themes.secondary[800],
                }}
              >
                <Typography
                  level="body3"
                  textColor={
                    theme === "light"
                      ? themes.secondary[500]
                      : themes.secondary[100]
                  }
                >
                  <F id="infinite.design" defaultMessage="Design" />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "73px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor:
                    theme === "light"
                      ? themes.common.white
                      : themes.secondary[800],
                }}
              >
                <Typography
                  level="body3"
                  textColor={
                    theme === "light"
                      ? themes.secondary[500]
                      : themes.secondary[100]
                  }
                >
                  <F id="infinite.project" defaultMessage="Project" />
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", marginTop: "20px" }}
          >
            <Box sx={{ marginBottom: "16px" }}>
              <Typography
                level="body1"
                textColor={
                  theme === "light" ? themes.common.black : themes.common.white
                }
                textAlign="start"
              >
                <F
                  id="enrico.text1"
                  defaultMessage="Our working model grants us the honor of occasionally collaborating with creative individuals, enabling us to bring the ideas they have in mind to life and materialize them into robust digital products that meet and exceed the expected expectations."
                />
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "16px" }}>
              <Typography
                level="body1"
                textColor={
                  theme === "light" ? themes.common.black : themes.common.white
                }
                textAlign="start"
              >
                <F
                  id="enrico.text2"
                  defaultMessage="We design and develop a dodecagon of intervals to support Enrico's arduous task of creating music and bringing art to the world, which serves educational and professional purposes."
                />
              </Typography>
            </Box>
            <Box>
              <Typography
                level="body1"
                textColor={
                  theme === "light" ? themes.common.black : themes.common.white
                }
                textAlign="start"
              >
                <F
                  id="enrico.text3"
                  defaultMessage="You can learn more about their work at the"
                />
                <Link
                  href="https://www.enricochapela.com"
                  target="blank"
                  rel="noreferrer"
                  sx={{
                    textDecoration: "underline",
                  }}
                >
                  <F
                    id="enrico.text4"
                    defaultMessage="following link."
                  />
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: '100%',
            overflow: 'hidden',
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
            borderRadius: "16px",
          }}
        >
          {iframeLoaded ? (
            <iframe
              
              title="Enrico's project"
              src="https://enricos-music-project.vercel.app"
              style={{
                width: '100%',
                height: "730px",
                border: 'none',
                scrollbarWidth: "none",
                overflow: "hidden",
              }}
            />
          ) : (
            <Box sx={{ display: "flex" }}>
              <Typography
                level="h6Bold"
                textColor={theme === "light" ? themes.primary[800] : themes.primary[50]}
                textTransform="uppercase"
              >
                <F id="loading" defaultMessage="Loading" />{'.'.repeat(dots)}</Typography>
            </Box>
          )}
          <iframe
            title="Enrico's project"
            src="https://enricos-music-project.vercel.app"
            style={{
              display: 'none'
            }}
            onLoad={handleIframeLoad}
          />

        </Box>
      </Box>
    </Box>
  );
};

export default Enrico;