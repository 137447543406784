import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Alert,
  Input,
  Textarea,
  Divider,
  CircularProgress,
  Avatar,
  useColorScheme,
} from '@mui/joy'

const ThemeExample = () => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mode, setMode } = useColorScheme();

  return (
    <Box>
      <Box component="nav">
        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            startDecorator={<Avatar>1</Avatar>}
            variant="plain"
            onClick={() =>
              setMode('light')
            }
          >
            Theme #1
          </Button>
          <Box p={1} />
          <Button
            startDecorator={<Avatar>2</Avatar>}
            variant="plain"
            onClick={() =>
              setMode('dark')
            }
          >
            Theme #2
          </Button>
        </Container>

        <Container sx={{maxWidth: "100vw", overflow: "hidden"}}>
          <Box p={2} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography level="h6">Buttons</Typography>
            <Typography>Solid</Typography>
          </div>
          <Divider />
          <Box p={1} />

          <Grid container spacing={1}>
            <Grid
              xs={12}
              gap={{xs: 2, md: 0}}
              sx={{ display: 'flex',flexDirection:{xs: "column", md: "row"}, justifyContent: {md: 'space-around'} }}
            >
              <Button size="lg" variant="solid">
                Button
              </Button>
              <Button
                size="lg"
                variant="solid"
              >
                Leading
              </Button>
              <Button
                size="lg"
                variant="solid"
              >
                Trailing
              </Button>
              <Button size="lg" variant="solid">
                <CircularProgress size="sm" />
              </Button>
              <Button size="lg" variant="solid" disabled>
                Disabled
              </Button>
            </Grid>
          </Grid>
          <Box p={1} />
          <Grid container spacing={1}>
            <Grid
              xs={12}
              gap={{xs: 2, md: 0}}
              sx={{ display: 'flex', flexDirection:{xs: "column", md: "row"}, justifyContent: {md: 'space-around'} }}
            >
              <Button size="sm" variant="solid">
                Button
              </Button>
              <Button
                size="sm"
                variant="solid"
              >
                Leading
              </Button>
              <Button
                size="sm"
                variant="solid"
              >
                Trailing
              </Button>
              <Button size="sm" variant="solid">
                <CircularProgress size="sm" />
              </Button>
              <Button size="sm" variant="solid" disabled>
                Disabled
              </Button>
            </Grid>
          </Grid>

          <Box p={2} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography level="h6">Buttons</Typography>
            <Typography>Outlined</Typography>
          </div>
          <Divider />
          <Box p={1} />

          <Grid container spacing={1}>
            <Grid
              xs={12}
              gap={{xs: 2, md: 0}}
              sx={{ display: 'flex', flexDirection:{xs: "column", md: "row"}, justifyContent: {md: 'space-around'} }}
            >
              <Button size="lg" variant="outlined">
                Button
              </Button>
              <Button
                size="lg"
                variant="outlined"
              >
                Leading
              </Button>
              <Button
                size="lg"
                variant="outlined"
              >
                Trailing
              </Button>
              <Button size="lg" variant="outlined">
                <CircularProgress size="sm" />
              </Button>
              <Button size="lg" variant="outlined" disabled>
                Disabled
              </Button>
            </Grid>
          </Grid>
          <Box p={1} />
          <Grid container spacing={1}>
            <Grid
              xs={12}
              gap={{xs: 2, md: 0}}
              sx={{ display: 'flex', flexDirection:{xs: "column", md: "row"}, justifyContent: {md: 'space-around'} }}
            >
              <Button size="sm" variant="outlined">
                Button
              </Button>
              <Button
                size="sm"
                variant="outlined"
              >
                Leading
              </Button>
              <Button
                size="sm"
                variant="outlined"
              >
                Trailing
              </Button>
              <Button size="sm" variant="outlined">
                <CircularProgress size="sm" />
              </Button>
              <Button size="sm" variant="outlined" disabled>
                Disabled
              </Button>
            </Grid>
          </Grid>

          <Box p={2} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography level="h6">Buttons</Typography>
            <Typography>Soft</Typography>
          </div>
          <Divider />
          <Box p={1} />

          <Grid container spacing={1}>
            <Grid
              xs={12}
              gap={{xs: 2, md: 0}}
              sx={{ display: 'flex', flexDirection:{xs: "column", md: "row"}, justifyContent: {md: 'space-around'} }}
            >
              <Button size="lg" variant="soft">
                Button
              </Button>
              <Button
                size="lg"
                variant="soft"
              >
                Leading
              </Button>
              <Button
                size="lg"
                variant="soft"
              >
                Trailing
              </Button>
              <Button size="lg" variant="soft">
                <CircularProgress size="sm" />
              </Button>
              <Button size="lg" variant="soft" disabled>
                Disabled
              </Button>
            </Grid>
          </Grid>
          <Box p={1} />
          <Grid container spacing={1}>
            <Grid
              xs={12}
              gap={{xs: 2, md: 0}}
              sx={{ display: 'flex', flexDirection:{xs: "column", md: "row"}, justifyContent: {md: 'space-around'} }}
            >
              <Button size="sm" variant="soft">
                Button
              </Button>
              <Button
                size="sm"
                variant="soft"
              >
                Leading
              </Button>
              <Button
                size="sm"
                variant="soft"
              >
                Trailing
              </Button>
              <Button size="sm" variant="soft">
                <CircularProgress size="sm" />
              </Button>
              <Button size="sm" variant="soft" disabled>
                Disabled
              </Button>
            </Grid>
          </Grid>

          <Box p={2} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography level="h6">Buttons</Typography>
            <Typography>Plain</Typography>
          </div>
          <Divider />
          <Box p={1} />

          <Grid container spacing={1}>
            <Grid
              xs={12}
              gap={{xs: 2, md: 0}}
              sx={{ display: 'flex', flexDirection:{xs: "column", md: "row"}, justifyContent: {md: 'space-around'} }}
            >
              <Button size="lg" variant="plain">
                Button
              </Button>
              <Button
                size="lg"
                variant="plain"
              >
                Leading
              </Button>
              <Button
                size="lg"
                variant="plain"
              >
                Trailing
              </Button>
              <Button size="lg" variant="plain">
                <CircularProgress size="sm" />
              </Button>
              <Button size="lg" variant="plain" disabled>
                Disabled
              </Button>
            </Grid>
          </Grid>
          <Box p={1} />
          <Grid container spacing={1}>
            <Grid
              xs={12}
              gap={{xs: 2, md: 0}}
              sx={{ display: 'flex', flexDirection:{xs: "column", md: "row"}, justifyContent: {md: 'space-around'} }}
            >
              <Button size="sm" variant="plain">
                Button
              </Button>
              <Button
                size="sm"
                variant="plain"
              >
                Leading
              </Button>
              <Button
                size="sm"
                variant="plain"
              >
                Trailing
              </Button>
              <Button size="sm" variant="plain">
                <CircularProgress size="sm" />
              </Button>
              <Button size="sm" variant="plain" disabled>
                Disabled
              </Button>
            </Grid>
          </Grid>

          <Box p={2} />
          <Typography level="h6">Alerts</Typography>
          <Divider />
          <Box p={1} />

          <Grid container spacing={1}>
            <Grid xs={6}>
              <Alert color="danger">
                This is an error alert — check it out!
              </Alert>
            </Grid>
            <Grid xs={6}>
              <Alert color="warning">
                This is a warning alert — check it out!
              </Alert>
            </Grid>
            <Grid xs={6}>
              <Alert color="info">This is an info alert — check it out!</Alert>
            </Grid>
            <Grid xs={6}>
              <Alert color="success">
                This is a success alert — check it out!
              </Alert>
            </Grid>
          </Grid>

          <Box p={2} />
          <Typography level="h6">Inputs</Typography>
          <Divider />
          <Box p={1} />

          <Grid container spacing={4}>
            <Grid xs={12} md={4}>
              <Input placeholder="Type something" fullWidth />
            </Grid>
            <Grid xs={12} md={4}>
              <Input variant="solid" placeholder="Type something" fullWidth />
            </Grid>
            <Grid xs={12} md={4}>
              <Input placeholder="Type something" fullWidth />
            </Grid>
          </Grid>

          <Box p={1} />

          <Grid container spacing={4}>
            <Grid xs={12} md={4}>
              <Textarea placeholder="Type something more" minRows={4} />
            </Grid>
            <Grid xs={12} md={4}>
              <Textarea
                variant="solid"
                placeholder="Type something more"
                minRows={4}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <Textarea placeholder="Type something more" minRows={4} />
            </Grid>
          </Grid>

          <Box p={2} />

          <Grid container spacing={4}>
            <Grid md={6}>
              <Typography level="h6">Display 1</Typography>
              <Divider />
              <Box p={1} />
              <Typography level="display1">
                The brown fox jumps over the lazy dog.
              </Typography>
            </Grid>
            <Grid md={6}>
              <Typography level="h6">Display 2</Typography>
              <Divider />
              <Typography level="display2">
                The brown fox jumps over the lazy dog.
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid md={6}>
              <Typography level="h6">H1</Typography>
              <Divider />
              <Box p={1} />
              <Typography level="h1">
                The brown fox jumps over the lazy dog.
              </Typography>
            </Grid>
            <Grid md={6}>
              <Typography level="h6">H2</Typography>
              <Divider />
              <Typography level="h2">
                The brown fox jumps over the lazy dog.
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid md={6}>
              <Typography level="h6">H3</Typography>
              <Divider />
              <Box p={1} />
              <Typography level="h3">
                The brown fox jumps over the lazy dog.
              </Typography>
            </Grid>
            <Grid md={6}>
              <Typography level="h6">H4</Typography>
              <Divider />
              <Typography level="h4">
                The brown fox jumps over the lazy dog.
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid md={6}>
              <Typography level="h6">H5</Typography>
              <Divider />
              <Box p={1} />
              <Typography level="h5">
                The brown fox jumps over the lazy dog.
              </Typography>
            </Grid>
            <Grid md={6}>
              <Typography level="h6">H6</Typography>
              <Divider />
              <Typography level="h6">
                The brown fox jumps over the lazy dog.
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid md={4}>
              <Typography level="h6">Body 1</Typography>
              <Divider />
              <Box p={1} />
              <Typography level="body1">
                The brown fox jumps over the lazy dog.
              </Typography>
            </Grid>
            <Grid md={4}>
              <Typography level="h6">Body 2</Typography>
              <Divider />
              <Typography level="body2">
                The brown fox jumps over the lazy dog.
              </Typography>
            </Grid>
            <Grid md={4}>
              <Typography level="h6">Body 3</Typography>
              <Divider />
              <Typography level="body3">
                The brown fox jumps over the lazy dog.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box p={16} />
    </Box>
  )
}

export default ThemeExample;