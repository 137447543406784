// Assets
import GoBackLeftArrow from "../UI/Icons/GoBackLeftArrow";

// React
import { useContext } from "react";

// Style
import { Box, Button, Grid, Typography, useTheme } from "@mui/joy";

// Tools
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import Context from "../Context/Context";
import UIContext from "../Context/ui-context";
import Services from "./Services";

const PlayGround = ({
  modal = false,
  closeModal = () => { },
}: {
  modal?: boolean;
  closeModal?: Function;
}) => {
  const F = FormattedMessage;
  const themeP = useTheme();
  const themes = themeP.vars.palette;
  const navigate = useNavigate();
  const { theme } = useContext(Context);
  const { openPageModal } = useContext(UIContext);

  // const [selected, setSelected] = useState<string>("");

  const goBack = () => {
    if (modal) {
      closeModal();
    } else {
      openPageModal(<Services />);
      setTimeout(() => {
        navigate("/");
      }, 400); // animation duration
    }
  };

  const goToButtonDisplay = () => {
    navigate("/playground/buttondisplay");
    if (modal) {
      closeModal();
    }
  };

  const goToCarrousel = () => {
    navigate("/playground/caroussel");
    if (modal) {
      closeModal();
    }
  };

  const goToItems = () => {
    navigate("/playground/itemsrow");
    if (modal) {
      closeModal();
    }
  };

  const goToSpyglass = () => {
    navigate("/playground/spyglass");
    if (modal) {
      closeModal();
    }
  };

  // const goToDesignSystem = () => {
  //   navigate("/playground/designsystem");
  //   if (modal) {
  //     closeModal();
  //   }
  // };

  const goToMode = () => {
    navigate("/playground/switch");
    if (modal) {
      closeModal();
    }
  };

  const goToGraphBars = () => {
    navigate("/playground/graph");
    if (modal) {
      closeModal();
    }
  };

  const goToVcard = () => {
    navigate("/playground/vcard");
    if (modal) {
      closeModal();
    }
  };

  const goToEnrico = () => {
    navigate("/playground/enrico");
    if (modal) {
      closeModal();
    }
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor:
          theme === "light" ? themes.primary[50] : themes.primary[900],
      }}
    >
      <Box
        sx={{
          width: { xs: "90dvw", sm: "94dvw", md: "70dvw" },
          marginLeft: { md: "40px" },
          marginX: { xs: "20px" },
          paddingBottom: "130px",
        }}
      >
        <Box>
          <Button
            onClick={() => goBack()}
            sx={{
              width: { xs: "30%", sm: "16%", md: "auto" },
              backgroundColor:
                theme === "light" ? themes.common.white : themes.primary[800],
              transition: "all 300ms ease-in",
              "&:hover": {
                borderRadius: "8px",
                backgroundColor:
                  theme === "light" ? themes.neutral[50] : themes.neutral[700],
              },
            }}
            startDecorator={<GoBackLeftArrow width={"16px"} height={"16px"} />}
          >
            <Typography
              level="h6Bold"
              textColor={themes.primary[400]}
              sx={{ textTransform: "uppercase" }}
            >
              <F id="goBack.back" defaultMessage="Back" />
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "12px",
            width: "100%",
            paddingTop: "36px",
          }}
        >
          <Typography
            level="h4"
            textColor={
              theme === "light" ? themes.primary[800] : themes.primary[50]
            }
            sx={{ textTransform: "uppercase" }}
          >
            <F
              id="playground.title"
              defaultMessage="What we do defines who we are"
            />
          </Typography>
        </Box>
        {/* <Box
          sx={{
            width: "100%",
            display: "flex",
            marginTop: "24px",
            gap: "16px",
            overflowX: "auto",
          }}
        >
          <Box
            sx={{
              width: { xs: "45px", md: "8%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "30px",
              borderRadius: "16px",
              cursor: "pointer",
              backgroundColor:
                selected === "All"
                  ? themes.primary[500]
                  : theme === "light"
                  ? themes.common.white
                  : themes.primary[900],
              transition: "all 300ms ease-in",
              "&:hover": {
                backgroundColor:
                  selected === "All"
                    ? themes.primary[500]
                    : theme === "light"
                    ? themes.neutral[50]
                    : themes.neutral[700],
              },
            }}
            onClick={() => setSelected("All")}
          >
            <Typography
              level={selected === "All" ? "body1Bold" : "body1"}
              textColor={
                selected === "All"
                  ? themes.common.white
                  : theme === "light"
                  ? themes.common.black
                  : themes.common.white
              }
            >
              <F id="chip.all" defaultMessage="All" />
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "72px", md: "12%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "30px",
              backgroundColor:
                selected === "Tools"
                  ? themes.primary[500]
                  : theme === "light"
                  ? themes.common.white
                  : themes.primary[900],
              transition: "all 300ms ease-in",
              borderRadius: "16px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor:
                  selected === "Tools"
                    ? themes.primary[500]
                    : theme === "light"
                    ? themes.neutral[50]
                    : themes.neutral[700],
              },
            }}
            onClick={() => setSelected("Tools")}
          >
            <Typography
              level={selected === "Tools" ? "body1Bold" : "body1"}
              textColor={
                selected === "Tools"
                  ? themes.common.white
                  : theme === "light"
                  ? themes.common.black
                  : themes.common.white
              }
            >
              <F id="chip.tools" defaultMessage="Tools" />
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "102px", md: "14%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "30px",
              backgroundColor:
                selected === "Examples"
                  ? themes.primary[500]
                  : theme === "light"
                  ? themes.common.white
                  : themes.primary[900],
              transition: "all 300ms ease-in",
              borderRadius: "16px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor:
                  selected === "Examples"
                    ? themes.primary[500]
                    : theme === "light"
                    ? themes.neutral[50]
                    : themes.neutral[700],
              },
            }}
            onClick={() => setSelected("Examples")}
          >
            <Typography
              level={selected === "Examples" ? "body1Bold" : "body1"}
              textColor={
                selected === "Examples"
                  ? themes.common.white
                  : theme === "light"
                  ? themes.common.black
                  : themes.common.white
              }
            >
              <F id="chip.examples" defaultMessage="Examples" />
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "120px", md: "14%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "30px",
              backgroundColor:
                selected === "Articles"
                  ? themes.primary[500]
                  : themes.common.white,
              transition: "all 300ms ease-in",
              borderRadius: "16px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor:
                  selected === "Articles"
                    ? themes.primary[500]
                    : themes.neutral[50],
              },
            }}
            onClick={() => setSelected("Articles")}
          >
            <Typography
              level={selected === "Articles" ? "body1Bold" : "body1"}
              textColor={
                selected === "Articles"
                  ? themes.common.white
                  : themes.common.black
              }
            >
              <F id="chip.articles" defaultMessage="Articles" />
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "88px", md: "14%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "30px",
              backgroundColor:
                selected === "Random"
                  ? themes.primary[500]
                  : theme === "light"
                  ? themes.common.white
                  : themes.primary[900],
              transition: "all 300ms ease-in",
              borderRadius: "16px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor:
                  selected === "Random"
                    ? themes.primary[500]
                    : theme === "light"
                    ? themes.neutral[50]
                    : themes.neutral[700],
              },
            }}
            onClick={() => setSelected("Random")}
          >
            <Typography
              level={selected === "Random" ? "body1Bold" : "body1"}
              textColor={
                selected === "Random"
                  ? themes.common.white
                  : theme === "light"
                  ? themes.common.black
                  : themes.common.white
              }
            >
              <F id="chip.random" defaultMessage="Random" />
            </Typography>
          </Box>
        </Box> */}
        <Box
          sx={{
            display: "flex",
            marginTop: "24px",
            gap: "16px",
          }}
        >
          <Grid container>
            <Grid xs={12} sm={12} md={8}>
              <Box
                sx={{
                  display: "flex",
                  width: { md: "100%", lg: "100%" },
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <Box
                  onClick={() => goToVcard()}
                  sx={{
                    width: "100%",
                    borderRadius: "24px",
                    backgroundColor:
                      theme === "light"
                        ? themes.primary[500]
                        : themes.primary[800],
                    padding: "32px",
                    "&:hover": {
                      backgroundColor:
                        theme === "light"
                          ? themes.secondary[300]
                          : themes.secondary[700],
                    },
                    transition: "all 300ms ease-in",
                    cursor: "pointer",
                    marginRight: {
                      xs: "0px",
                      sm: "0px",
                      md: "16px",
                      lg: "13px",
                    },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      level="h6Bold"
                      textColor={themes.common.white}
                      textTransform="uppercase"
                    >
                      <F
                        id="vcard"
                        defaultMessage="V-cards"
                      />
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexWrap: " wrap", marginTop: "12px", gap: "8px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "83px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.example" defaultMessage="Example" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "40px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.UI" defaultMessage="UI" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "73px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="infinite.design" defaultMessage="Design" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "73px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.corporate" defaultMessage="Corporate" />
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "12px",
                      gap: "12px",
                    }}
                  >
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="vcard.text1"
                          defaultMessage="The world evolves alongside technology, which is why we have thought about how to adjust basic aspects of institutional stationery to have quick access to people's contact information with a robust system and an easily understandable process to put online swiftly."
                        />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1Medium"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F id="info.text2" defaultMessage="Use cases:" />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="vcard.text2"
                          defaultMessage="Any contact information for members of a company or business."
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {/* <Box
                  onClick={() => goToButtonDisplay()}
                  sx={{
                    width: "100%",
                    borderRadius: "24px",
                    backgroundColor:
                      theme === "light"
                        ? themes.primary[500]
                        : themes.primary[800],
                    padding: "32px",
                    "&:hover": {
                      backgroundColor:
                        theme === "light"
                          ? themes.secondary[300]
                          : themes.secondary[700],
                    },
                    transition: "all 300ms ease-in",
                    cursor: "pointer",
                    marginRight: {
                      xs: "0px",
                      sm: "0px",
                      md: "16px",
                      lg: "13px",
                    },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      level="h6Bold"
                      textColor={themes.common.white}
                      textTransform="uppercase"
                    >
                      <F
                        id="info.display"
                        defaultMessage="Info display button"
                      />
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginTop: "12px", gap: "8px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "83px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.example" defaultMessage="Example" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "40px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.UI" defaultMessage="UI" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "73px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="infinite.design" defaultMessage="Design" />
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "12px",
                      gap: "12px",
                    }}
                  >
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="info.text1"
                          defaultMessage="Playable button to display lots of info if needed."
                        />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1Medium"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F id="info.text2" defaultMessage="Use cases:" />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="info.text3"
                          defaultMessage="A friendly button to display info without changing or loading another screen and you don't want to use a plain pop-up or modal."
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box> */}
                <Box
                  onClick={() => goToEnrico()}
                  sx={{
                    width: "100%",
                    borderRadius: "24px",
                    backgroundColor:
                      theme === "light"
                        ? themes.primary[500]
                        : themes.primary[800],
                    padding: "32px",
                    "&:hover": {
                      backgroundColor:
                        theme === "light"
                          ? themes.secondary[300]
                          : themes.secondary[700],
                    },
                    transition: "all 300ms ease-in",
                    cursor: "pointer",
                    marginLeft: { sm: "16px", md: "0px" },
                    marginTop: { xs: "16px", sm: "0px", md: "0px" },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      level="h6Bold"
                      textColor={themes.common.white}
                      textTransform="uppercase"
                    >
                      <F
                        id="enrico"
                        defaultMessage="Enrico"
                      />
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", flexWrap: " wrap", marginTop: "12px", gap: "8px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "83px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.example" defaultMessage="Example" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "40px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.UI" defaultMessage="UI" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "73px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="infinite.design" defaultMessage="Design" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "73px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="infinite.project" defaultMessage="Project" />
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "12px",
                      gap: "12px",
                    }}
                  >
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="enrico.text1"
                          defaultMessage="Our working model grants us the honor of occasionally collaborating with creative individuals, enabling us to bring the ideas they have in mind to life and materialize them into robust digital products that meet and exceed the expected expectations."
                        />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="enrico.text2"
                          defaultMessage="We design and develop a dodecagon of intervals to support Enrico's arduous task of creating music and bringing art to the world, which serves educational and professional purposes."
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {/* <Box
                  onClick={() => goToCarrousel()}
                  sx={{
                    width: "100%",
                    borderRadius: "24px",
                    backgroundColor:
                      theme === "light"
                        ? themes.primary[500]
                        : themes.primary[800],
                    padding: "32px",
                    "&:hover": {
                      backgroundColor:
                        theme === "light"
                          ? themes.secondary[300]
                          : themes.secondary[700],
                    },
                    transition: "all 300ms ease-in",
                    cursor: "pointer",
                    marginLeft: { sm: "16px", md: "0px" },
                    marginTop: { xs: "16px", sm: "0px", md: "0px" },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      level="h6Bold"
                      textColor={themes.common.white}
                      textTransform="uppercase"
                    >
                      <F id="caroussel" defaultMessage="Caroussel" />
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginTop: "12px", gap: "8px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "83px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                        paddingBottom: "2px",
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.example" defaultMessage="Example" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "40px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                        paddingBottom: "1px",
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.UI" defaultMessage="UI" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "73px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                        paddingBottom: "2px",
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="infinite.design" defaultMessage="Design" />
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "12px",
                      gap: "12px",
                    }}
                  >
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="caroussel.text1"
                          defaultMessage="Banner with an scroll that never ends and continues forever or until your device discharges."
                        />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1Medium"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F id="info.text2" defaultMessage="Use cases:" />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="caroussel.text2"
                          defaultMessage="Display information to feel the sense that there is unlimited options."
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box> */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    display: { xs: "none", sm: "flex", md: "none" },
                    marginLeft: { xs: "0px", md: "13px" },
                    marginTop: { xs: "16px", md: "0px" },
                  }}
                >
                  <Box
                    onClick={() => goToCarrousel()}
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "24px",
                      backgroundColor:
                        theme === "light"
                          ? themes.primary[500]
                          : themes.primary[800],
                      paddingX: "32px",
                      paddingY: { xs: "32px", md: "157px" },
                      "&:hover": {
                        backgroundColor:
                          theme === "light"
                            ? themes.secondary[300]
                            : themes.secondary[700],
                      },
                      transition: "all 300ms ease-in",
                      cursor: "pointer",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        level="h6Bold"
                        textColor={themes.common.white}
                        textTransform="uppercase"
                      >
                        <F id="caroussel" defaultMessage="Caroussel" />
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", marginTop: "12px", gap: "8px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "83px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                          paddingBottom: "2px",
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.example" defaultMessage="Example" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "40px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                          paddingBottom: "1px",
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.UI" defaultMessage="UI" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "73px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                          paddingBottom: "2px",
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="infinite.design" defaultMessage="Design" />
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "12px",
                        gap: "12px",
                      }}
                    >
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="caroussel.text1"
                            defaultMessage="Banner with an scroll that never ends and continues forever or until your device discharges."
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          level="body1Medium"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F id="info.text2" defaultMessage="Use cases:" />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="caroussel.text2"
                            defaultMessage="Display information to feel the sense that there is unlimited options."
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {/* <Box
                    onClick={() => goToGraphBars()}
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "24px",
                      backgroundColor:
                        theme === "light"
                          ? themes.primary[500]
                          : themes.primary[800],
                      paddingX: "32px",
                      paddingY: { xs: "32px", md: "157px" },
                      "&:hover": {
                        backgroundColor:
                          theme === "light"
                            ? themes.secondary[300]
                            : themes.secondary[700],
                      },
                      transition: "all 300ms ease-in",
                      cursor: "pointer",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        level="h6Bold"
                        textColor={themes.common.white}
                        textTransform="uppercase"
                      >
                        <F
                          id="graphics"
                          defaultMessage="graphic bars animation"
                        />
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", marginTop: "12px", gap: "8px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "83px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.example" defaultMessage="Example" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "40px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.UI" defaultMessage="UI" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "73px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="infinite.design" defaultMessage="Design" />
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "12px",
                        gap: "12px",
                      }}
                    >
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="graphics.text1"
                            defaultMessage="When you need to display any information and don't want the usual and boring basic html options you can go bold with some animation to display and show the information."
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          level="body1Medium"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F id="info.text2" defaultMessage="Use cases:" />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="graphics.text2"
                            defaultMessage="Boring information that need to be more visual pleasing to users."
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </Box> */}
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    marginTop: { xs: "16px", md: "13px" },
                  }}
                >
                  <Box
                    onClick={() => goToButtonDisplay()}
                    sx={{
                      width: "100%",
                      borderRadius: "24px",
                      backgroundColor:
                        theme === "light"
                          ? themes.primary[500]
                          : themes.primary[800],
                      padding: "32px",
                      "&:hover": {
                        backgroundColor:
                          theme === "light"
                            ? themes.secondary[300]
                            : themes.secondary[700],
                      },
                      transition: "all 300ms ease-in",
                      cursor: "pointer",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        level="h6Bold"
                        textColor={themes.common.white}
                        textTransform="uppercase"
                      >
                        <F
                          id="info.display"
                          defaultMessage="Info display button"
                        />
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", marginTop: "12px", gap: "8px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "83px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.example" defaultMessage="Example" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "40px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.UI" defaultMessage="UI" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "73px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="infinite.design" defaultMessage="Design" />
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "12px",
                        gap: "12px",
                      }}
                    >
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="info.text1"
                            defaultMessage="Playable button to display lots of info if needed."
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          level="body1Medium"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F id="info.text2" defaultMessage="Use cases:" />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="info.text3"
                            defaultMessage="A friendly button to display info without changing or loading another screen and you don't want to use a plain pop-up or modal."
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {/* <Box
                    onClick={() => goToMode()}
                    sx={{
                      width: "100%",
                      borderRadius: "24px",
                      backgroundColor:
                        theme === "light"
                          ? themes.primary[500]
                          : themes.primary[800],
                      padding: "32px",
                      "&:hover": {
                        backgroundColor:
                          theme === "light"
                            ? themes.secondary[300]
                            : themes.secondary[700],
                      },
                      transition: "all 300ms ease-in",
                      cursor: "pointer",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        level="h6Bold"
                        textColor={themes.common.white}
                        textTransform="uppercase"
                      >
                        <F id="mode" defaultMessage="Mode selector" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", marginTop: "12px", gap: "8px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "83px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.example" defaultMessage="Example" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "40px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.UI" defaultMessage="UI" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "73px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="infinite.design" defaultMessage="Design" />
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "12px",
                        gap: "12px",
                      }}
                    >
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="mode.text1"
                            defaultMessage="Funny group-button to select between two or more options that. Could be toggle options or true-false options."
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          level="body1Medium"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F id="info.text2" defaultMessage="Use cases:" />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="mode.text2"
                            defaultMessage="Options, toggle or theme selectors."
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </Box> */}
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} md={4}>
              <Box
                sx={{
                  display: { xs: "flex", sm: "none", md: "flex" },
                  marginLeft: { xs: "0px", md: "13px" },
                  marginTop: { xs: "16px", md: "0px" },
                  height: "100%",
                }}
              >
                <Box
                  onClick={() => goToCarrousel()}
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "24px",
                    backgroundColor:
                      theme === "light"
                        ? themes.primary[500]
                        : themes.primary[800],
                    paddingX: "32px",
                    paddingY: { xs: "32px", md: "122.5px", lg: "141.5px" },
                    "&:hover": {
                      backgroundColor:
                        theme === "light"
                          ? themes.secondary[300]
                          : themes.secondary[700],
                    },
                    transition: "all 300ms ease-in",
                    cursor: "pointer",
                    marginLeft: { xs: "0px", sm: "0px", md: "0px", lg: "0px" },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      level="h6Bold"
                      textColor={themes.common.white}
                      textTransform="uppercase"
                    >
                      <F id="caroussel" defaultMessage="Caroussel" />
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginTop: "12px", gap: "8px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "83px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                        paddingBottom: "2px",
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.example" defaultMessage="Example" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "40px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                        paddingBottom: "1px",
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.UI" defaultMessage="UI" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "73px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                        paddingBottom: "2px",
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="infinite.design" defaultMessage="Design" />
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "12px",
                      gap: "12px",
                    }}
                  >
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="caroussel.text1"
                          defaultMessage="Banner with an scroll that never ends and continues forever or until your device discharges."
                        />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1Medium"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F id="info.text2" defaultMessage="Use cases:" />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="caroussel.text2"
                          defaultMessage="Display information to feel the sense that there is unlimited options."
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {/* <Box
                  onClick={() => goToGraphBars()}
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "24px",
                    backgroundColor:
                      theme === "light"
                        ? themes.primary[500]
                        : themes.primary[800],
                    paddingX: "32px",
                    paddingY: { xs: "32px", md: "122.5px", lg: "141.5px" },
                    "&:hover": {
                      backgroundColor:
                        theme === "light"
                          ? themes.secondary[300]
                          : themes.secondary[700],
                    },
                    transition: "all 300ms ease-in",
                    cursor: "pointer",
                    marginLeft: { xs: "0px", sm: "0px", md: "0px", lg: "0px" },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      level="h6Bold"
                      textColor={themes.common.white}
                      textTransform="uppercase"
                    >
                      <F
                        id="graphics"
                        defaultMessage="graphic bars animation"
                      />
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginTop: "12px", gap: "8px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "83px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.example" defaultMessage="Example" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "40px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.UI" defaultMessage="UI" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "73px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="infinite.design" defaultMessage="Design" />
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "12px",
                      gap: "12px",
                    }}
                  >
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="graphics.text1"
                          defaultMessage="When you need to display any information and don't want the usual and boring basic html options you can go bold with some animation to display and show the information."
                        />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1Medium"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F id="info.text2" defaultMessage="Use cases:" />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="graphics.text2"
                          defaultMessage="Boring information that need to be more visual pleasing to users."
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* <Box sx={{ display: "flex", marginTop: "16px" }}>
          <Box
            onClick={() => goToDesignSystem()}
            sx={{
              width: "100%",
              borderRadius: "24px",
              backgroundColor:
                theme === "light"
                  ? themes.secondary[500]
                  : themes.secondary[800],
              "&:hover": {
                backgroundColor:
                  theme === "light" ? themes.primary[500] : themes.primary[700],
              },
              transition: "all 300ms ease-in",
              cursor: "pointer",
              padding: "32px",
              marginTop: { xs: "16px", sm: "0px" },
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography
                level="h6Bold"
                textColor={themes.common.white}
                textTransform="uppercase"
              >
                <F
                  id="why.you.need"
                  defaultMessage="Why you need a design system?"
                />
              </Typography>
            </Box>
            <Box sx={{ display: "flex", marginTop: "12px", gap: "8px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "83px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor:
                    theme === "light"
                      ? themes.common.white
                      : themes.secondary[800],
                  paddingBottom: "2px",
                }}
              >
                <Typography
                  level="body1"
                  textColor={
                    theme === "light"
                      ? themes.secondary[500]
                      : themes.secondary[100]
                  }
                >
                  <F id="info.example" defaultMessage="Example" />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "40px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor:
                    theme === "light"
                      ? themes.common.white
                      : themes.secondary[800],
                  paddingBottom: "1px",
                }}
              >
                <Typography
                  level="body1"
                  textColor={
                    theme === "light"
                      ? themes.secondary[500]
                      : themes.secondary[100]
                  }
                >
                  <F id="info.UI" defaultMessage="UI" />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "73px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor:
                    theme === "light"
                      ? themes.common.white
                      : themes.secondary[800],
                  paddingBottom: "2px",
                }}
              >
                <Typography
                  level="body1"
                  textColor={
                    theme === "light"
                      ? themes.secondary[500]
                      : themes.secondary[100]
                  }
                >
                  <F id="info.react" defaultMessage="React" />
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "12px",
                gap: "12px",
              }}
            >
              <Box>
                <Typography
                  level="body1"
                  textColor={themes.common.white}
                  textAlign="start"
                >
                  <F
                    id="why.you.need.text1"
                    defaultMessage="Know the benefits to have a well planed, and well designed Design system for you project. Because just a corporate manual won't save you for your online presence."
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box> */}
        {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
        <Box
          sx={{
            display: "flex",
            marginTop: { xs: "32px", sm: "13px", md: "13px" },
            gap: "16px",
          }}
        >
          <Grid container direction="row-reverse">
            <Grid xs={12} sm={12} md={8}>
              <Box
                sx={{
                  display: "flex",
                  width: { md: "48.40%", lg: "100%" },
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <Box
                  onClick={() => goToSpyglass()}
                  sx={{
                    width: "100%",
                    borderRadius: "24px",
                    backgroundColor:
                      theme === "light"
                        ? themes.primary[500]
                        : themes.primary[800],
                    padding: "32px",
                    "&:hover": {
                      backgroundColor:
                        theme === "light"
                          ? themes.secondary[300]
                          : themes.secondary[700],
                    },
                    transition: "all 300ms ease-in",
                    cursor: "pointer",
                    marginRight: {
                      xs: "0px",
                      sm: "0px",
                      md: "16px",
                      lg: "13px",
                    },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      level="h6Bold"
                      textColor={themes.common.white}
                      textTransform="uppercase"
                    >
                      <F id="spyglass" defaultMessage="Info display button" />
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginTop: "12px", gap: "8px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "83px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.example" defaultMessage="Example" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "40px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.UI" defaultMessage="UI" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "73px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="infinite.design" defaultMessage="Design" />
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "12px",
                      gap: "12px",
                    }}
                  >
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="spyglass.text1"
                          defaultMessage="Playable button to display lots of info if needed."
                        />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1Medium"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F id="info.text2" defaultMessage="Use cases:" />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="spyglass.text2"
                          defaultMessage="A friendly button to display info without changing or loading another screen and you don't want to use a plain pop-up or modal."
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  onClick={() => goToItems()}
                  sx={{
                    width: "100%",
                    borderRadius: "24px",
                    backgroundColor:
                      theme === "light"
                        ? themes.primary[500]
                        : themes.primary[800],
                    padding: "32px",
                    "&:hover": {
                      backgroundColor:
                        theme === "light"
                          ? themes.secondary[300]
                          : themes.secondary[700],
                    },
                    transition: "all 300ms ease-in",
                    cursor: "pointer",
                    marginLeft: { sm: "16px", md: "0px" },
                    marginTop: { xs: "16px", sm: "0px", md: "0px" },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      level="h6Bold"
                      textColor={themes.common.white}
                      textTransform="uppercase"
                    >
                      <F id="itemsRow" defaultMessage="Columnas de opciones" />
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginTop: "12px", gap: "8px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "83px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.example" defaultMessage="Example" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "40px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.UI" defaultMessage="UI" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "73px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="infinite.design" defaultMessage="Design" />
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "12px",
                      gap: "12px",
                    }}
                  >
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="itemsRow.text1"
                          defaultMessage="Banner with an scroll that never ends and continues forever or until your device discharges."
                        />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1Medium"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F id="info.text2" defaultMessage="Use cases:" />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="itemsRow.text2"
                          defaultMessage="Display information to feel the sense that there is unlimited options."
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    display: { xs: "none", sm: "flex", md: "none" },
                    marginRight: { xs: "0px", md: "13px" },
                    marginTop: { xs: "16px", md: "0px" },
                  }}
                >
                  <Box
                    onClick={() => goToMode()}
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "24px",
                      backgroundColor:
                        theme === "light"
                          ? themes.primary[500]
                          : themes.primary[800],
                      paddingX: "32px",
                      paddingY: { xs: "32px", md: "157px" },
                      "&:hover": {
                        backgroundColor:
                          theme === "light"
                            ? themes.secondary[300]
                            : themes.secondary[700],
                      },
                      transition: "all 300ms ease-in",
                      cursor: "pointer",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        level="h6Bold"
                        textColor={themes.common.white}
                        textTransform="uppercase"
                      >
                        <F id="mode" defaultMessage="Mode selector" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", marginTop: "12px", gap: "8px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "83px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.example" defaultMessage="Example" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "40px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.UI" defaultMessage="UI" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "73px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="infinite.design" defaultMessage="Design" />
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "12px",
                        gap: "12px",
                      }}
                    >
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="mode.text1"
                            defaultMessage="Funny group-button to select between two or more options that. Could be toggle options or true-false options."
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          level="body1Medium"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F id="info.text2" defaultMessage="Use cases:" />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="mode.text2"
                            defaultMessage="Options, toggle or theme selectors."
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {/* <Box
                    onClick={() => goToEnrico()}
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "24px",
                      backgroundColor:
                        theme === "light"
                          ? themes.primary[500]
                          : themes.primary[800],
                      paddingX: "32px",
                      paddingY: { xs: "32px", md: "157px" },
                      "&:hover": {
                        backgroundColor:
                          theme === "light"
                            ? themes.secondary[300]
                            : themes.secondary[700],
                      },
                      transition: "all 300ms ease-in",
                      cursor: "pointer",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        level="h6Bold"
                        textColor={themes.common.white}
                        textTransform="uppercase"
                      >
                        <F
                          id="enrico"
                          defaultMessage="Enrico"
                        />
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", flexWrap: " wrap", marginTop: "12px", gap: "8px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "83px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.example" defaultMessage="Example" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "40px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.UI" defaultMessage="UI" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "73px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="infinite.design" defaultMessage="Design" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "73px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="infinite.project" defaultMessage="Project" />
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "12px",
                        gap: "12px",
                      }}
                    >
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="enrico.text1"
                            defaultMessage="Our working model grants us the honor of occasionally collaborating with creative individuals, enabling us to bring the ideas they have in mind to life and materialize them into robust digital products that meet and exceed the expected expectations."
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="enrico.text2"
                            defaultMessage="We design and develop a dodecagon of intervals to support Enrico's arduous task of creating music and bringing art to the world, which serves educational and professional purposes."
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </Box> */}
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    marginTop: { xs: "16px", md: "13px" },
                  }}
                >
                  <Box
                    onClick={() => goToGraphBars()}
                    sx={{
                      width: "100%",
                      borderRadius: "24px",
                      backgroundColor:
                        theme === "light"
                          ? themes.primary[500]
                          : themes.primary[800],
                      padding: "32px",
                      "&:hover": {
                        backgroundColor:
                          theme === "light"
                            ? themes.secondary[300]
                            : themes.secondary[700],
                      },
                      transition: "all 300ms ease-in",
                      cursor: "pointer",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        level="h6Bold"
                        textColor={themes.common.white}
                        textTransform="uppercase"
                      >
                        <F
                          id="graphics"
                          defaultMessage="graphic bars animation"
                        />
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", marginTop: "12px", gap: "8px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "83px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.example" defaultMessage="Example" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "40px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.UI" defaultMessage="UI" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "73px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="infinite.design" defaultMessage="Design" />
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "12px",
                        gap: "12px",
                      }}
                    >
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="graphics.text1"
                            defaultMessage="When you need to display any information and don't want the usual and boring basic html options you can go bold with some animation to display and show the information."
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          level="body1Medium"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F id="info.text2" defaultMessage="Use cases:" />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="graphics.text2"
                            defaultMessage="Boring information that need to be more visual pleasing to users."
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {/* <Box
                    onClick={() => goToVcard()}
                    sx={{
                      width: "100%",
                      borderRadius: "24px",
                      backgroundColor:
                        theme === "light"
                          ? themes.primary[500]
                          : themes.primary[800],
                      padding: "32px",
                      "&:hover": {
                        backgroundColor:
                          theme === "light"
                            ? themes.secondary[300]
                            : themes.secondary[700],
                      },
                      transition: "all 300ms ease-in",
                      cursor: "pointer",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        level="h6Bold"
                        textColor={themes.common.white}
                        textTransform="uppercase"
                      >
                        <F
                          id="vcard"
                          defaultMessage="V-cards"
                        />
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexWrap: " wrap", marginTop: "12px", gap: "8px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "83px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.example" defaultMessage="Example" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "40px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.UI" defaultMessage="UI" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "73px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="infinite.design" defaultMessage="Design" />
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "73px",
                          height: "28px",
                          borderRadius: "16px",
                          backgroundColor:
                            theme === "light"
                              ? themes.common.white
                              : themes.secondary[800],
                        }}
                      >
                        <Typography
                          level="body3"
                          textColor={
                            theme === "light"
                              ? themes.secondary[500]
                              : themes.secondary[100]
                          }
                        >
                          <F id="info.corporate" defaultMessage="Corporate" />
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "12px",
                        gap: "12px",
                      }}
                    >
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="vcard.text1"
                            defaultMessage="The world evolves alongside technology, which is why we have thought about how to adjust basic aspects of institutional stationery to have quick access to people's contact information with a robust system and an easily understandable process to put online swiftly."
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          level="body1Medium"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F id="info.text2" defaultMessage="Use cases:" />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          level="body1"
                          textColor={themes.common.white}
                          textAlign="start"
                        >
                          <F
                            id="vcard.text2"
                            defaultMessage="Any contact information for members of a company or business."
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </Box> */}
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} md={4}>
              <Box
                sx={{
                  display: { xs: "flex", sm: "none", md: "flex" },
                  marginRight: { xs: "0px", md: "13px" },
                  marginTop: { xs: "16px", md: "0px" },
                  height: "100%",
                }}
              >
                <Box
                  onClick={() => goToMode()}
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "24px",
                    backgroundColor:
                      theme === "light"
                        ? themes.primary[500]
                        : themes.primary[800],
                    paddingX: "32px",
                    paddingY: { xs: "32px", md: "122.5px", lg: "141.5px" },
                    "&:hover": {
                      backgroundColor:
                        theme === "light"
                          ? themes.secondary[300]
                          : themes.secondary[700],
                    },
                    transition: "all 300ms ease-in",
                    cursor: "pointer",
                    marginLeft: { xs: "0px", sm: "0px", md: "0px", lg: "0px" },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      level="h6Bold"
                      textColor={themes.common.white}
                      textTransform="uppercase"
                    >
                      <F id="mode" defaultMessage="Mode selector" />
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", marginTop: "12px", gap: "8px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "83px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.example" defaultMessage="Example" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "40px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.UI" defaultMessage="UI" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "73px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="infinite.design" defaultMessage="Design" />
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "12px",
                      gap: "12px",
                    }}
                  >
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="mode.text1"
                          defaultMessage="Funny group-button to select between two or more options that. Could be toggle options or true-false options."
                        />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1Medium"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F id="info.text2" defaultMessage="Use cases:" />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="mode.text2"
                          defaultMessage="Options, toggle or theme selectors."
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {/* <Box
                  onClick={() => goToEnrico()}
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "24px",
                    backgroundColor:
                      theme === "light"
                        ? themes.primary[500]
                        : themes.primary[800],
                    paddingX: "32px",
                    paddingY: { xs: "32px", md: "70px", lg: "100px" },
                    "&:hover": {
                      backgroundColor:
                        theme === "light"
                          ? themes.secondary[300]
                          : themes.secondary[700],
                    },
                    transition: "all 300ms ease-in",
                    cursor: "pointer",
                    marginLeft: { xs: "0px", sm: "0px", md: "0px", lg: "0px" },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      level="h6Bold"
                      textColor={themes.common.white}
                      textTransform="uppercase"
                    >
                      <F
                        id="enrico"
                        defaultMessage="Enrico"
                      />
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", flexWrap: " wrap", marginTop: "12px", gap: "8px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "83px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.example" defaultMessage="Example" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "40px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="info.UI" defaultMessage="UI" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "73px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="infinite.design" defaultMessage="Design" />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "73px",
                        height: "28px",
                        borderRadius: "16px",
                        backgroundColor:
                          theme === "light"
                            ? themes.common.white
                            : themes.secondary[800],
                      }}
                    >
                      <Typography
                        level="body3"
                        textColor={
                          theme === "light"
                            ? themes.secondary[500]
                            : themes.secondary[100]
                        }
                      >
                        <F id="infinite.project" defaultMessage="Project" />
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "12px",
                      gap: "12px",
                    }}
                  >
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="enrico.text1"
                          defaultMessage="Our working model grants us the honor of occasionally collaborating with creative individuals, enabling us to bring the ideas they have in mind to life and materialize them into robust digital products that meet and exceed the expected expectations."
                        />
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        level="body1"
                        textColor={themes.common.white}
                        textAlign="start"
                      >
                        <F
                          id="enrico.text2"
                          defaultMessage="We design and develop a dodecagon of intervals to support Enrico's arduous task of creating music and bringing art to the world, which serves educational and professional purposes."
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
      </Box>
    </Box>
  );
};

export default PlayGround;
