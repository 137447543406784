import { Box, Typography, useTheme } from "@mui/joy";
import React, { useContext, useState } from "react";
import Context from "../../Context/Context";

// type mode = "LIGHT" | "DARK";

const ThemeSwitch = () => {
  const themes = useTheme();
  // const [selectedMode, setSelectedMode] = useState<mode>('LIGHT')
  const [hover, setHover] = useState(false)

  const { theme, setTheme } = useContext(Context);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
      }}
    >
      <Box
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          cursor: "pointer",
          transition: "all 300ms ease-in",
          display: "flex",
          borderRadius: "20px",
          border: `2px solid ${themes.vars.palette.primary[400]}`,
          padding: "4px",
          width: hover ? 252 : 132,
          height: "fit-content",
        }}
      >
        <Box
          onClick={() => setTheme("dark")}
          sx={{
            transition: "all 300ms ease-in",
            flexShrink: 0,
            zIndex: theme === "light" ? 0 : 1,
            width: 120,
            paddingY: "16px",
            borderRadius: hover ? "16px 0px 0px 16px" : "16px",
            backgroundColor: theme === "light" ? themes.vars.palette.neutral[700] : themes.vars.palette.primary[400],
          }}
        >
          <Typography
            fontWeight={600}
            fontSize={"20px"}
            lineHeight={"20px"}
            textColor={themes.vars.palette.common.white}
          >
            DARK
          </Typography>
        </Box>
        <Box
          onClick={() => setTheme("light")}
          sx={{
            cursor: "pointer",
            transition: "all 300ms ease-in",
            transform: hover ? "translateX(0%)" : "translateX(-100%)",
            flexShrink: 0,
            zIndex: theme === "light" ? 1 : 0,
            width: 120,
            paddingY: "16px",
            borderRadius: hover ? "0px 16px 16px 0px" : "16px",
            backgroundColor: theme === "light" ? themes.vars.palette.primary[400] : themes.vars.palette.neutral[700],
          }}
        >
          <Typography
            fontWeight={600}
            fontSize={"20px"}
            lineHeight={"20px"}
            textColor={themes.vars.palette.common.white}
          >
            LIGHT
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ThemeSwitch;
