import { MouseEventHandler } from "react";

const CancelIcon: React.ElementType = ({
  show,
}: {
  show: MouseEventHandler;
}) => {
  return (
    <svg
      onClick={show}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0613 18.9387C21.343 19.2205 21.5014 19.6027 21.5014 20.0012C21.5014 20.3997 21.343 20.7819 21.0613 21.0637C20.7795 21.3455 20.3973 21.5038 19.9988 21.5038C19.6002 21.5038 19.218 21.3455 18.9363 21.0637L11 13.125L3.06126 21.0612C2.77946 21.343 2.39727 21.5013 1.99876 21.5013C1.60024 21.5013 1.21805 21.343 0.936257 21.0612C0.654464 20.7794 0.496155 20.3972 0.496155 19.9987C0.496155 19.6002 0.654464 19.218 0.936257 18.9362L8.87501 11L0.938756 3.06122C0.656964 2.77943 0.498655 2.39724 0.498655 1.99872C0.498655 1.60021 0.656964 1.21802 0.938756 0.936225C1.22055 0.654432 1.60274 0.496123 2.00126 0.496123C2.39977 0.496123 2.78196 0.654432 3.06376 0.936225L11 8.87498L18.9388 0.934975C19.2205 0.653182 19.6027 0.494873 20.0013 0.494873C20.3998 0.494873 20.782 0.653182 21.0638 0.934975C21.3455 1.21677 21.5039 1.59896 21.5039 1.99747C21.5039 2.39599 21.3455 2.77818 21.0638 3.05998L13.125 11L21.0613 18.9387Z"
        fill="white"
      />
    </svg>
  );
};

export default CancelIcon;
