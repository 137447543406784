import { Box, Button, Typography } from "@mui/joy";
import { useState } from "react";

const AnimatedButton9 = () => {
    const [hovered, setHovered] = useState(false);
  
    const handleHover = () => {
      setHovered(!hovered);
    };
  
    return (
      <Box
        display="flex" justifyContent="center" alignItems="center"
        sx={{
            backgroundColor: hovered ? 'blue' : 'green',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '5px',
            transition: 'background-color 0.3s ease-in-out',
            cursor: "pointer",
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        Click!
      </Box>
    );
}

const AnimatedButton10 = () => {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    };
  
    return (
      <Box
        display="flex" justifyContent="center" alignItems="center"
        sx={{
            backgroundColor: clicked ? 'purple' : 'blue',
            color: 'white',
            padding: clicked ? '15px 30px' : '10px 20px',
            borderRadius: clicked ? '50px' : '5px',
            transition: 'all 0.3s ease-in-out',
            cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        {clicked ? 'Clicked!' : 'Click!'}
      </Box>
    );
}

const AnimatedButton11 = () => {
    const [hovered, setHovered] = useState(false);

    const handleHover = () => {
      setHovered(!hovered);
    };
  
    return (
      <Box
        display="flex" justifyContent="center" alignItems="center"
        sx={{
            backgroundColor: 'blue',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '5px',
            transition: 'transform 0.3s ease-in-out',
            transform: hovered ? 'translateX(-10px)' : 'none',
            cursor: 'pointer',
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        Move!
      </Box>
    );
}

const AnimatedButton12 = () => {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    };
  
    return (
      <Box
        display="flex" justifyContent="center" alignItems="center"
        sx={{
            backgroundColor: 'blue',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '5px',
            transition: 'opacity 0.3s ease-in-out',
            opacity: clicked ? '0' : '1',
            cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        {clicked ? 'Bye!' : 'Click!'}
      </Box>
    );
}

const AnimatedButton13 = () => {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    };
  
    return (
      <Box
        display="flex" justifyContent="center" alignItems="center"
        sx={{
            backgroundColor: 'blue',
            color: 'white',
            padding: clicked ? '20px 40px' : '10px 20px',
            borderRadius: clicked ? '50px' : '5px',
            transition: 'all 0.3s ease-in-out',
            transform: clicked ? 'translate(50px, 50px)' : 'none',
            cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        {clicked ? 'Clicked!' : 'Click!'}
      </Box>
    );
}

const AnimatedButton14 = () => {
    const [hovered, setHovered] = useState(false);

    const handleHover = () => {
        setHovered(!hovered);
    };
  
    return (
      <Box
        display="flex" justifyContent="center" alignItems="center"
        sx={{
            backgroundColor: hovered ? 'red' : 'blue',
            color: 'white',
            padding: hovered ? '20px 40px' : '10px 20px',
            borderRadius: hovered ? '50px' : '5px',
            transition: 'all 0.3s ease-in-out',
            transform: hovered ? 'rotate(180deg)' : 'none',
            cursor: 'pointer',
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        {hovered ? 'WOW!' : 'Hover Me!'}
      </Box>
    );
}

const AnimatedButton15 = () => {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
      setClicked(!clicked);
    };
  
    return (
      <Box
        display="flex" justifyContent="center" alignItems="center"
        sx={{
            backgroundColor: 'blue',
            color: 'white',
            padding: clicked ? '20px 40px' : '10px 20px',
            borderRadius: clicked ? '50%' : '5px',
            transition: 'all 0.3s ease-in-out',
            transform: clicked ? 'rotate(360deg)' : 'none',
            cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        {clicked ? 'Clicked!' : 'Click Me!'}
      </Box>
    );
}

const AnimatedButton16 = () => {
    const [hovered, setHovered] = useState(false);

    const handleHover = () => {
        setHovered(!hovered);
    };

    return (
      <Box
        display="flex" justifyContent="center" alignItems="center"
        sx={{
            backgroundColor: hovered ? 'purple' : 'green',
            color: 'white',
            padding: '10px 20px',
            borderRadius: hovered ? '0' : '50%',
            transition: 'all 0.3s ease-in-out',
            transform: hovered ? 'rotate(45deg)' : 'none',
            cursor: 'pointer',
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
        >
            {hovered ? 'WOW!' : 'Hover Me!'}
      </Box>
    );
}

const AnimatedButton17 = () => {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    };

    return (
      <Box
        display="flex" justifyContent="center" alignItems="center"
        sx={{
            backgroundColor: clicked ? 'purple' : 'green',
            color: 'white',
            padding: clicked ? '50px 100px' : '10px 20px',
            borderRadius: clicked ? '0' : '50%',
            transition: 'all 0.3s ease-in-out',
            transform: clicked ? 'rotate(45deg) translate(100px, 100px)' : 'none',
            position: clicked ? 'absolute' : 'static',
            right: clicked ? '10px' : 'auto',
            bottom: clicked ? '10px' : 'auto',
            cursor: 'pointer',
        }}
        onClick={handleClick}
        >
            {clicked ? 'Clicked!' : 'Click Me!'}
      </Box>
    );
}

const AnimatedButton18 = () => {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    };

    return (
      <Box
        display="flex" justifyContent="center" alignItems="center"
        sx={{
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: clicked ? 'purple' : 'green',
            border: 'none',
            padding: clicked ? '10px 40px' : '10px 20px',
            fontWeight: clicked ? 'bold' : 'normal',
            boxShadow: clicked
            ? '0 0 20px 5px rgba(0, 0, 0, 0.3)'
            : '0 0 10px 2px rgba(0, 0, 0, 0.3)',
            transition: 'all 0.3s ease-in-out',
            top: clicked ? '50%' : 'auto',
            left: clicked ? '50%' : 'auto',
            transform: clicked ? 'translate(-50%, -50%) scale(2)' : 'none',
            cursor: 'pointer',
            outline: 'none',
            zIndex: 1,
            color: "black"
        }}
        onClick={handleClick}
        >
            {clicked ? 'Clicked!' : 'Click Me!'}
            <Box
                style={{
                position: 'absolute',
                backgroundColor: 'white',
                width: '200%',
                height: '200%',
                borderRadius: '50%',
                top: '-50%',
                left: '-50%',
                transform: clicked ? 'scale(0)' : 'scale(1)',
                transition: 'all 0.3s ease-in-out',
                zIndex: -1,
                }}
            />
      </Box>
    );
}

const AnimatedButton19 = () => {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    };

    return (
      <Box
        display="flex" justifyContent="center" alignItems="center"
        sx={{
            position: 'relative',
            backgroundColor: 'purple',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            fontWeight: 'bold',
            boxShadow: '0 0 20px 5px rgba(0, 0, 0, 0.3)',
            transition: 'all 0.3s ease-in-out',
            transform: clicked ? 'rotate(360deg)' : 'none',
            cursor: 'pointer',
            outline: 'none',
        }}
        onClick={handleClick}
        >
            {clicked ? 'Rotated!' : 'Click Me!'}
      </Box>
    );
}

const AnimatedButton20 = () => {
    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => {
      setHover(true);
    };
  
    const handleMouseLeave = () => {
      setHover(false);
    };

    return (
      <Box
        display="flex" justifyContent="center" alignItems="center"
        sx={{
            position: 'relative',
            backgroundColor: hover ? 'red' : 'blue',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            fontWeight: 'bold',
            fontSize: hover ? '24px' : '16px',
            transition: 'all 0.3s ease-in-out',
            cursor: 'pointer',
            outline: 'none',
            boxShadow: hover ? '0 0 20px 5px rgba(0, 0, 0, 0.3)' : 'none',
            transform: hover ? 'scale(1.2)' : 'none',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        >
            {hover ? 'Hovering!' : 'Hover!'}
      </Box>
    );
}

const AnimationButtons = () => {
  return (
    <Box>
        <Box display="flex" justifyContent="center"> 
            <Typography level="h2">
                Animation Buttons
            </Typography>
        </Box>
        <Box mt={4} px={2} height="100%">
            <Button sx={{
                width: "130px",
                height: "40px",
                color: "#fff",
                borderRadius: "5px",
                padding: "10px 25px",
                fontWeight: 500,
                cursor: "pointer",
                transition: "all 0.3s ease",
                position: "relative",
                display: "inline-block",
                boxShadow: "inset 2px 2px 2px 0px rgba(255,255,255,.5),7px 7px 20px 0px rgba(0,0,0,.1),4px 4px 5px 0px rgba(0,0,0,.1)",
                outline: "none",
                background: "linear-gradient(0deg, rgba(6,14,131,1) 0%, rgba(12,25,180,1) 100%)",
                border: "none",
                ":hover":{
                    background: "linear-gradient(0deg, rgba(0,3,255,1) 0%, rgba(2,126,251,1) 100%)"
                },
                mr: 2
            }}>
                Hermes
            </Button>
            <Button sx={{
                width: "130px",
                height: "40px",
                color: "#fff",
                borderRadius: "5px",
                padding: "10px 25px",
                fontWeight: 500,
                cursor: "pointer",
                transition: "all 0.3s ease",
                position: "relative",
                display: "inline-block",
                boxShadow: "inset 2px 2px 2px 0px rgba(255,255,255,.5),7px 7px 20px 0px rgba(0,0,0,.1),4px 4px 5px 0px rgba(0,0,0,.1)",
                outline: "none",
                background: "linear-gradient(0deg, rgba(96,9,240,1) 0%, rgba(129,5,240,1) 100%)",
                border: "none",
                ":before":{
                    height: "0%",
                    width: "2px"
                },
                ":hover":{
                    boxShadow:  "4px 4px 6px 0 rgba(255,255,255,.5), -4px -4px 6px 0 rgba(116, 125, 136, .5), inset -4px -4px 6px 0 rgba(255,255,255,.2),inset 4px 4px 6px 0 rgba(0, 0, 0, .4)"
                },
                mr: 2
            }}>
                Hermes
            </Button>
            <Box m={2} className="btn-3 custom-btn">
                <span style={{textAlign: "center"}}>
                    Hermes
                </span>
            </Box>
            <Box m={2} className="custom-btn btn-4">
                <span style={{textAlign: "center"}}>
                    Hermes
                </span>
            </Box>
            <Box m={2} className="custom-btn btn-5">
                <span style={{textAlign: "center", display:"flex", justifyContent:"center"}}>
                    Hermes
                </span>
            </Box>
            <Box m={2} className="custom-btn btn-6">
                <span style={{textAlign: "center"}}>
                    Hermes
                </span>
            </Box>
            <Box m={2} className="custom-btn btn-7">
                <span style={{textAlign: "center"}}>
                    Hermes
                </span>
            </Box>
            <Box m={2} className="custom-btn btn-8">
                <span style={{textAlign: "center"}}>
                    Hermes
                </span>
            </Box>
        </Box>
        <Box display="flex">
            <Box m={2} sx={{width: "130px", height: "40px",}}>
                <AnimatedButton9 />
            </Box>
            <Box m={2} sx={{width: "130px", height: "40px",}}>
                <AnimatedButton10 />
            </Box>
            <Box m={2} sx={{width: "130px", height: "40px",}}>
                <AnimatedButton11 />
            </Box>
            <Box m={2} sx={{width: "130px", height: "40px",}}>
                <AnimatedButton12 />
            </Box>
            <Box m={2} sx={{width: "130px", height: "40px",}}>
                <AnimatedButton13 />
            </Box>
            <Box m={2} sx={{width: "130px", height: "40px",}}>
                <AnimatedButton14 />
            </Box>
            <Box m={2} sx={{width: "130px", height: "40px",}}>
                <AnimatedButton15 />
            </Box>
            <Box m={2} sx={{width: "130px", height: "40px",}}>
                <AnimatedButton16 />
            </Box>
        </Box>
        <Box display="flex">
            <Box m={2} sx={{width: "130px", height: "40px",}}>
                <AnimatedButton17 />
            </Box>
            <Box m={2} sx={{width: "130px", height: "40px",}}>
                <AnimatedButton18 />
            </Box>
            <Box m={2} sx={{width: "130px", height: "40px",}}>
                <AnimatedButton19 />
            </Box>
            <Box m={2} sx={{width: "130px", height: "40px",}}>
                <AnimatedButton20 />
            </Box>
        </Box>
    </Box>
  );
}

export default AnimationButtons