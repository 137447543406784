const WhatsApp: React.ElementType = ({
  width,
  height,
}: {
  width: string;
  height: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.17534 22.125C4.68645 19.613 4.16565 16.6439 4.71072 13.7752C5.25579 10.9064 6.82925 8.33525 9.13569 6.54438C11.4421 4.75352 14.323 3.86611 17.2373 4.04875C20.1517 4.2314 22.8992 5.47153 24.964 7.53634C27.0288 9.60115 28.2689 12.3486 28.4516 15.263C28.6342 18.1774 27.7468 21.0582 25.9559 23.3646C24.1651 25.6711 21.5939 27.2445 18.7252 27.7896C15.8564 28.3347 12.8873 27.8139 10.3753 26.325V26.325L6.22534 27.5C6.05531 27.5497 5.87503 27.5528 5.70341 27.5089C5.53178 27.465 5.37513 27.3757 5.24986 27.2504C5.1246 27.1252 5.03534 26.9685 4.99144 26.7969C4.94753 26.6253 4.95061 26.445 5.00034 26.275L6.17534 22.125Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5125 23C18.1967 23.0033 16.8933 22.7466 15.677 22.2446C14.4608 21.7426 13.3557 21.0052 12.4253 20.0748C11.4949 19.1444 10.7575 18.0393 10.2555 16.823C9.75346 15.6068 9.49673 14.3033 9.50003 12.9875C9.50334 12.0614 9.87355 11.1744 10.5296 10.5207C11.1856 9.86705 12.0739 9.50002 13 9.50003V9.50003C13.1528 9.49881 13.3031 9.5388 13.4351 9.61579C13.5671 9.69278 13.6759 9.80392 13.75 9.93753L15.2125 12.4875C15.2995 12.6425 15.3441 12.8177 15.3419 12.9955C15.3397 13.1732 15.2908 13.3472 15.2 13.5L14.025 15.4625C14.6269 16.8014 15.6987 17.8732 17.0375 18.475L19 17.3C19.1529 17.2093 19.3269 17.1603 19.5046 17.1581C19.6823 17.1559 19.8575 17.2006 20.0125 17.2875L22.5625 18.75C22.6961 18.8242 22.8073 18.933 22.8843 19.0649C22.9613 19.1969 23.0013 19.3472 23 19.5C22.9968 20.4251 22.6287 21.3116 21.9757 21.9669C21.3227 22.6222 20.4376 22.9934 19.5125 23V23Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WhatsApp;
