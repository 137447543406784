import { Box, Grid, Typography, useTheme } from "@mui/joy";
import { useContext, useEffect, useRef } from "react";
import Context from "../../Components/Context/Context";
// import FooterPortfolio from '../FooterPortfolio';

const Sypglass = () => {
  const blurElement = useRef<HTMLDivElement>(null);
  const { theme } = useContext(Context);
  const themeP = useTheme();

  useEffect(() => {
    document.onmousemove = (evt) => {
      if (blurElement.current === null) return;
      blurElement.current.style.transform = `translate(${evt.clientX}px, ${evt.clientY}px)`;
    };
  }, []);

  const tagStyles = {
    background:
      theme === "light"
        ? themeP.vars.palette.primary[500]
        : themeP.vars.palette.primary[600],
    borderRadius: "8px",
    padding: "4px",
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // background: "radial-gradient(circle, #95B9FF, #DCF0FF, #E6F7FF)",
        backgroundPosition: "50% 50%",
        borderRadius: "8px",
      }}
    >
      <Box
        ref={blurElement}
        sx={{
          position: "fixed",
          zIndex: "999",
          pointerEvents: "none",
          width: "200vw",
          height: "200vh",
          left: "-100vw",
          top: "-100vh",
          transformOrigin: "center",
          background: "rgba(255, 255, 255, 0.5)",
          backdropFilter: "blur(10px)",
          maskImage:
            "radial-gradient(150px at 50% 50% , transparent 100%, black 100%)",
        }}
        id="blur-around"
      ></Box>
      <Box
        sx={{
          padding: { xs: "20px 20px", md: "24px 50px" },
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignItems: "center",
          background:
            theme === "light"
              ? themeP.vars.palette.primary[50]
              : themeP.vars.palette.primary[800],
          boxShadow:
            "0px 0px 16px rgba(72, 147, 255, 0.1), 0px 0px 32px rgba(75, 176, 255, 0.1)",
          borderRadius: "48px",
          width: "100%",
          height: "fit-content",
          gap: "30px",
        }}
      >
        <Box>
          <Typography
            level="h4"
            textColor={
              theme === "light"
                ? themeP.vars.palette.neutral[800]
                : themeP.vars.palette.common.white
            }
            textAlign={"center"}
          >
            WHY WE NEED TO HAVE A...
          </Typography>
          <Typography
            level="h4"
            textColor={
              theme === "light"
                ? themeP.vars.palette.neutral[800]
                : themeP.vars.palette.common.white
            }
            textAlign={"center"}
          >
            DESIGN SYSTEM
          </Typography>
        </Box>
        <Typography
          level="body1Medium"
          textColor={
            theme === "light"
              ? themeP.vars.palette.neutral[800]
              : themeP.vars.palette.common.white
          }
          textAlign={"center"}
        >
          You can chose for a multiple Design systems libraries that exist, or
          you can go crazy and create a new one that is scalable and simple to
          implement, there is no limits, just the one the project demands!
        </Typography>

        <Grid
          container
          columns={{ xs: 2, lg: 3 }}
          spacing={"12px"}
          mb={"60px"}
          sx={{ width: "100%" }}
        >
          <Grid xs={1}>
            <Box sx={tagStyles}>
              <Typography
                level="body1Medium"
                textColor={ theme === "light" ? themeP.vars.palette.common.white : themeP.vars.palette.primary[900]}
                textAlign={"center"}
              >
                Material UI
              </Typography>
            </Box>
          </Grid>
          <Grid xs={1}>
            <Box sx={tagStyles}>
              <Typography
                level="body1Medium"
                textColor={ theme === "light" ? themeP.vars.palette.common.white : themeP.vars.palette.primary[900]}
                textAlign={"center"}
              >
                Joy UI
              </Typography>
            </Box>
          </Grid>

          <Grid xs={1}>
            <Box sx={tagStyles}>
              <Typography
                level="body1Medium"
                textColor={ theme === "light" ? themeP.vars.palette.common.white : themeP.vars.palette.primary[900]}
                textAlign={"center"}
              >
                Webflow
              </Typography>
            </Box>
          </Grid>
          <Grid xs={1}>
            <Box sx={tagStyles}>
              <Typography
                level="body1Medium"
                textColor={ theme === "light" ? themeP.vars.palette.common.white : themeP.vars.palette.primary[900]}
                textAlign={"center"}
              >
                Carbon design
              </Typography>
            </Box>
          </Grid>

          <Grid xs={1}>
            <Box sx={tagStyles}>
              <Typography
                level="body1Medium"
                textColor={ theme === "light" ? themeP.vars.palette.common.white : themeP.vars.palette.primary[900]}
                textAlign={"center"}
              >
                Dezin
              </Typography>
            </Box>
          </Grid>
          <Grid xs={1}>
            <Box sx={tagStyles}>
              <Typography
                level="body1Medium"
                textColor={ theme === "light" ? themeP.vars.palette.common.white : themeP.vars.palette.primary[900]}
                textAlign={"center"}
              >
                VUE JS
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <FooterPortfolio /> */}
    </Box>
  );
};

export default Sypglass;
