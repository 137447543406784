import React from "react";

const Line = ({ width }: { width: string }) => {
  return (
    <svg
      width={width}
      height="2"
      viewBox="0 0 471 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="0.5" x2="471" y2="0.5" stroke="#4893FF" />
    </svg>
  );
};

export default Line;
