// Language
import { IntlProvider } from "react-intl";
import Spanish from "./Languages/esp.json";
import English from "./Languages/eng.json";
import Context from "./Components/Context/Context";
import { useContext } from "react";
import "react-toastify/dist/ReactToastify.css";

// Layout
import Layout from "./Components/UI/UIComponents/Layout";

// Routes
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

// Styles
import { CssVarsProvider } from "@mui/joy";
import { hermesTheme } from "./lib/hermesTheme";

// Pages
import Home from "./Components/Pages/Home";
// import Services from "./Components/Pages/Services";
import Portfolio from "./Components/Pages/Playground";
import Modal from "./Components/UI/UIComponents/Modal";
import PortfolioX from "./Portfolio/Portfolio";
import ButtonDisplay from "./Components/Pages/ButtonDisplay";
import InfiniteScrollP from "./Components/Pages/InfiniteScroll";
import DesignSystem from "./Components/Pages/DesignSystem";
import CarousselP from "./Components/Pages/Caroussel";
import SwitchMode from "./Components/Pages/Switch";
import ItemsRow from "./Components/Pages/ItemsRow";
import Graph from "./Components/Pages/Graph";
import Spyglass from "./Components/Pages/Spyglass";
import Vcard from "./Components/Pages/Vcard";
import Enrico from "./Components/Pages/Enrico";
import { UIContextProvider } from "./Components/Context/ui-context";


const App = () => {
  const { language } = useContext(Context);

  const locale = navigator.language;
  let lang: any;

  if (language === "en") {
    lang = English;
  } else {
    lang = Spanish;
  }

  return (
    <CssVarsProvider theme={hermesTheme} defaultMode="system">
      <IntlProvider locale={locale} messages={lang}>
        <BrowserRouter>
          <UIContextProvider>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="*" element={<Navigate replace to="/" />} />
                {/* <Route path="/services" element={<Services />} /> */}
                <Route path="/playground" element={<Portfolio />} />
                <Route path="/portfolio" element={<PortfolioX />} />
                <Route path="/playground/buttondisplay" element={<ButtonDisplay />} />
                <Route path="/playground/infinitescroll" element={<InfiniteScrollP />} />
                <Route path="/playground/designsystem" element={<DesignSystem />} />
                <Route path="/playground/caroussel" element={<CarousselP />} />
                <Route path="/playground/switch" element={<SwitchMode />} />
                <Route path="/playground/itemsrow" element={<ItemsRow />} />
                <Route path="/playground/graph" element={<Graph />} />
                <Route path="/playground/spyglass" element={<Spyglass />} />
                <Route path="/playground/vcard" element={<Vcard />} />
                <Route path="/playground/enrico" element={<Enrico />} />
              </Route>
            </Routes>
            <Modal></Modal>
          </UIContextProvider>
        </BrowserRouter>
      </IntlProvider>
    </CssVarsProvider>
  );
};

export default App;
