import { Box, Tabs, TabList, Tab, TabPanel } from '@mui/joy'
import Caroussel from './modules/Caroussel';
import ThemeExample from './modules/ThemeExample';
import { InfiniteScroll } from './modules/InfiniteScroll';
import AnimationButtons from './AnimationButtons';
import AnimatedThemeSwitch from './modules/AnimatedThemeSwitch';
import InfoDisplayButton from './InfoDisplayButton';
import ParticlesComponent from "./modules/Particles";
import AnimationExample from './AnimationExample';
import AnimatedGraph from './modules/AnimatedGraph';
import Sypglass from './modules/Sypglass';

const PortfolioX = () => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100vw",
        height: "100dvh",
      }}
    >
      <Tabs defaultValue={1} sx={{ width: "100%", height: "100dvh" }}>
        <TabList sx={{ zIndex: "1" }}>
          <Tab value={1}>Caroussel</Tab>
          <Tab value={2}>Theme Example</Tab>
          <Tab value={3}>Animation Buttons</Tab>
          <Tab value={4}>Infinite Scroll</Tab>
          <Tab value={5}>Particles</Tab>
          <Tab value={6}>Animated theme switch</Tab>
          <Tab value={7}>Info Display Button</Tab>
          <Tab value={8}>Animation Example</Tab>
          <Tab value={9}>Animated Graph</Tab>
          <Tab value={10}>Spyglass</Tab>
        </TabList>
        <TabPanel value={1}>
          <Caroussel />
        </TabPanel>
        <TabPanel value={2} sx={{ p: 1 }}>
          <ThemeExample />
        </TabPanel>
        <TabPanel value={3} sx={{ p: 1 }}>
          <AnimationButtons />
        </TabPanel>
        <TabPanel value={4} sx={{ p: 0}}>
          <InfiniteScroll />
        </TabPanel>
        <TabPanel value={5}>
          <ParticlesComponent />
        </TabPanel>
        <TabPanel value={6}>
          <AnimatedThemeSwitch />
        </TabPanel>
        <TabPanel value={7}>
          <InfoDisplayButton />
        </TabPanel>
        <TabPanel value={8}>
          <AnimationExample />
        </TabPanel>
        <TabPanel value={9}>
          <AnimatedGraph />
        </TabPanel>
        <TabPanel value={10}>
          <Sypglass />
        </TabPanel>
      </Tabs>
    </Box>
  );
};

export default PortfolioX;
