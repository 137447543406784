// import FacebookIcon from "../Icons/FacebookIcon";
// import InstagramIcon from "../Icons/InstagramIcon";
import LinkedIcon from "../Icons/LinkedIcon";
import { FormattedMessage } from "react-intl";
import { useContext, useEffect, useState } from "react";
import Context from "../../Context/Context";
import {
  Box,
  Link,
  Switch,
  Typography,
  switchClasses,
  useTheme,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import CancelIcon from "../Icons/CancelIcon";
import InstagramIconDark from "../Icons/InstagramIconDark";
import InstagramIconMenu from "../Icons/InstagramIconMenu";
import LinkedIconDark from "../Icons/LinkedInIconDark";
import LinkedIconMenu from "../Icons/LinkedInIconMenu";

const Menu: React.ElementType = ({
  setOpenMenu,
}: {
  setOpenMenu: Function;
}) => {
  const F = FormattedMessage;
  const themeP = useTheme();
  const themes = themeP.vars.palette;
  const navigate = useNavigate();

  const { language, setLanguage, setOpenModal, setTheme, theme } =
    useContext(Context);
  const [checked, setChecked] = useState<boolean>(false);

  const langEng = () => {
    setLanguage("en");
  };

  const langEsp = () => {
    setLanguage("es");
  };

  const goToPortfolio = () => {
    setOpenMenu((prev: boolean) => !prev);
    navigate("/playground");
  };

  useEffect(() => {
    if (theme === "light") {
      setChecked(false);
    } else {
      setChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCurrentTheme = (e: any) => {
    setChecked(e);
    if (e === true) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  return (
    <Box
      sx={{
        width: { xs: "100dvw", md: "none" },
        height: "100dvh",
        backgroundColor: theme === "light" ? "#3facff54" : "#030b2793",
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        sx={{
          display: { sx: "flex", md: "none" },
          width: "100%",
          flexDirection: "column",
          gap: "43px",
          zIndex: 50,
          marginTop: "-68px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            paddingTop: "32px",
            paddingRight: "32px",
          }}
          onClick={() => setOpenMenu((prev: boolean) => !prev)}
        >
          <CancelIcon />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "220px",
            marginBottom: "40px",
          }}
        >
          <Box sx={{ display: "flex", gap: "16px" }}>
            <Typography
              level="body1Bold"
              textColor={
                theme === "light"
                  ? language === "en"
                    ? themes.primary[800]
                    : themes.primary[700]
                  : language === "en"
                  ? themes.common.white
                  : themes.primary[500]
              }
              sx={{ cursor: "pointer" }}
              onClick={langEng}
            >
              ENG
            </Typography>
            <Typography
              level="body1Bold"
              textColor={
                theme === "light"
                  ? language === "es"
                    ? themes.primary[800]
                    : themes.primary[700]
                  : language === "es"
                  ? themes.common.white
                  : themes.primary[500]
              }
              sx={{ cursor: "pointer" }}
              onClick={langEsp}
            >
              ESP
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginY: "40px",
            gap: "32px",
          }}
        >
          <Box>
            <Link
              href="https://www.linkedin.com/company/hermes-corp"
              target="blank"
              rel="noreferrer"
            >
              {theme === "light" ? (
                <LinkedIconMenu width={24} height={24} />
              ) : (
                <LinkedIconDark width={24} height={24} />
              )}
            </Link>
          </Box>
          <Box>
            <Link
              href="https://www.instagram.com/hermescorp.io/"
              target="blank"
              rel="noreferrer"
              underline="none"
            >
              {theme === "light" ? (
                <InstagramIconMenu width={30} height={30} />
              ) : (
                <InstagramIconDark width={30} height={30} />
              )}
            </Link>
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            marginY: "40px",
          }}
        >
          <Typography
            level="h6Bold"
            textColor={themes.primary[800]}
            textTransform="uppercase"
          >
            upwork
          </Typography>
        </Box> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            marginY: "40px",
          }}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <Typography
            level="h6Bold"
            textColor={
              theme === "light" ? themes.primary[800] : themes.common.white
            }
            textTransform="uppercase"
          >
            hey@hermescorp.io
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            marginY: "40px",
          }}
          onClick={() => {
            goToPortfolio();
          }}
        >
          <Typography
            level="h6Bold"
            textColor={
              theme === "light" ? themes.primary[800] : themes.common.white
            }
            textTransform="uppercase"
          >
            <F id="our.playground" defaultMessage="Our Playground" />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            marginY: "40px",
            gap: "16px",
          }}
        >
          <Typography
            level="h6Bold"
            textColor={
              theme === "light" ? themes.primary[800] : themes.common.white
            }
            textTransform="uppercase"
          >
            Dark theme
          </Typography>
          <Switch
            checked={checked}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentTheme(event.target.checked)
            }
            sx={() => ({
              "--Switch-thumbShadow": "0 3px 7px 0 rgba(0 0 0 / 0.12)",
              "--Switch-thumbSize": "27px",
              "--Switch-trackWidth": "51px",
              "--Switch-trackHeight": "31px",
              "--Switch-trackBackground":
                theme === "light" ? themes.primary[300] : themes.primary[300],
              [`& .${switchClasses.thumb}`]: {
                transition: "width 0.2s, left 0.2s",
              },
              "&:active": {
                "--Switch-thumbWidth": "32px",
              },
              [`&.${switchClasses.checked}`]: {
                "--Switch-trackBackground":
                  theme === "light" ? themes.primary[500] : themes.primary[500],
              },
            })}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Menu;
