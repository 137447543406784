// Assets
import LinkedIcon from "../../Icons/LinkedIcon";

// Context
import LanguageContext from "../../../Context/Context";

// Style
import { Box, Link, Typography, useTheme } from "@mui/joy";

// Tools
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import LinkedInIconDark from "../../Icons/LinkedInIconDark";

const PageModalSocialMedia: React.ElementType = ({
  closeModal,
}: {
  closeModal: Function;
}) => {
  const { language, setLanguage, setOpenModal, theme } =
    useContext(LanguageContext);
  const themes = useTheme();
  const navigate = useNavigate();
  const F = FormattedMessage;

  const langEng = () => {
    setLanguage("en");
  };

  const langEsp = () => {
    setLanguage("es");
  };

  const goToPortfolio = () => {
    navigate("/playground");
    closeModal();
  };

  return (
    <Box sx={{ display: "flex", height: "100dvh", alignItems: "center" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
          gap: "43px",
          zIndex: 1001,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Box sx={{ display: "flex", gap: "16px" }}>
            <Typography
              level="body1Bold"
              textColor={
                theme === "light"
                  ? language === "en"
                    ? themes.vars.palette.common.black
                    : themes.vars.palette.neutral[300]
                  : language === "en"
                  ? themes.vars.palette.common.white
                  : themes.vars.palette.primary[700]
              }
              sx={{ cursor: "pointer" }}
              onClick={langEng}
            >
              ENG
            </Typography>
            <Typography
              level="body1Bold"
              textColor={
                theme === "light"
                  ? language === "es"
                    ? themes.vars.palette.common.black
                    : themes.vars.palette.neutral[300]
                  : language === "es"
                  ? themes.vars.palette.common.white
                  : themes.vars.palette.primary[700]
              }
              sx={{ cursor: "pointer" }}
              onClick={langEsp}
            >
              ESP
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Link
            href="https://www.linkedin.com/company/hermes-corp"
            target="blank"
            rel="noreferrer"
          >
            {theme === "light" ? (
              <LinkedIcon width={"26"} height={"26"}></LinkedIcon>
            ) : (
              <LinkedInIconDark width={"26"} height={"26"}></LinkedInIconDark>
            )}
          </Link>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", cursor: "pointer" }}>
          <Link
            href="https://www.instagram.com/hermescorp.io/"
            target="blank"
            rel="noreferrer"
            underline="none"
          >
            <Typography
              level="h6Bold"
              textColor={
                theme === "light"
                  ? themes.vars.palette.primary[800]
                  : themes.vars.palette.primary[50]
              }
            >
              IG
            </Typography>
          </Link>
        </Box>
        {/* <Box sx={{ display: "flex", justifyContent: "end", cursor: "pointer" }}>
          <Typography
            level="h6Bold"
            textColor={theme.vars.palette.primary[800]}
            textTransform="uppercase"
          >
            upwork
          </Typography>
        </Box> */}
        <Box
          sx={{ display: "flex", justifyContent: "end", cursor: "pointer" }}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <Typography
            level="h6Bold"
            textColor={
              theme === "light"
                ? themes.vars.palette.primary[800]
                : themes.vars.palette.primary[50]
            }
            textTransform="uppercase"
          >
            hey@hermescorp.io
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "end", cursor: "pointer" }}
          onClick={() => {
            goToPortfolio();
          }}
        >
          <Typography
            level="h6Bold"
            textColor={
              theme === "light"
                ? themes.vars.palette.primary[800]
                : themes.vars.palette.primary[50]
            }
            textTransform="uppercase"
          >
            <F id="our.playground" defaultMessage="Our Playground" />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PageModalSocialMedia;
