// Assets
import Gangverk from "../../Components/UI/Images/hermes2/gangverk.webp";
import Rtp from "../../Components/UI/Images/hermes2/rtp.webp";
import Kukun from "../../Components/UI/Images/hermes2/Kukun.webp";
import Dml from "../../Components/UI/Images/hermes2/dml.webp";
import Iac from "../../Components/UI/Images/hermes2/iac.webp";
// import Invent from "../../Components/UI/Images/hermes2/invent.webp";
import Itam from "../../Components/UI/Images/hermes2/itam.webp";
import Ht from "../../Components/UI/Images/hermes2/harbor.webp";
import GoBackArrow from "../UI/Icons/GoBackArrow";
import GangverkDark from "../../Components/UI/Images/hermes2/gangverkDark.webp";
import RtpDark from "../../Components/UI/Images/hermes2/rtpDark.webp";
import KukunDark from "../../Components/UI/Images/hermes2/KukunDark.webp";
import DmlDark from "../../Components/UI/Images/hermes2/dmlDark.webp";
import IacDark from "../../Components/UI/Images/hermes2/iacDark.webp";
import HtDark from "../../Components/UI/Images/hermes2/harborDark.webp";

// Context
import { useContext, useEffect, useState } from "react";
import Context from "../Context/Context";

// Style
import { Box, Button, Grid, Link, Typography, useTheme } from "@mui/joy";
import TextTransition, { presets } from "react-text-transition";

// Tools
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const Services = ({ closeModal = () => {} }: { closeModal?: Function }) => {
  const F = FormattedMessage;
  const themeP = useTheme();
  const themes = themeP.vars.palette;
  const navigate = useNavigate();

  const { language, theme } = useContext(Context);

  const TEXTSEng = ["Help", "Design", "Engineer", "Develop"];
  const TEXTSEsp = ["Planear", "Trazar", "Diseñar", "Lograr"];
  const [index, setIndex] = useState<number>(0);

  const goHome = () => {
    /* navigate("/"); */
    closeModal();
  };

  const goToPortfolio = () => {
    navigate("/playground");
    closeModal();
  };

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index: any) => index + 1),
      2000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor:
          theme === "light" ? themes.primary[50] : themes.primary[900],
      }}
    >
      <Box
        sx={{
          width: { xs: "90dvw", sm: "94vw", md: "70dvw" },
          marginLeft: { md: "40px" },
          marginX: { xs: "20px" },
        }}
      >
        <Box>
          <Button
            onClick={() => goHome()}
            sx={{
              width: { xs: "30%", sm: "16%", md: "auto" },
              backgroundColor:
                theme === "light" ? themes.common.white : themes.primary[800],
              transition: "all 300ms ease-in",
              "&:hover": {
                borderRadius: "8px",
                backgroundColor:
                  theme === "light" ? themes.neutral[50] : themes.neutral[700],
              },
            }}
            startDecorator={<GoBackArrow width={"16px"} height={"16px"} />}
          >
            <Typography
              level="h6Bold"
              textColor={themes.primary[500]}
              sx={{ textTransform: "uppercase" }}
            >
              <F id="goBack.home" defaultMessage="Home" />
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: { xs: "auto", sm: "100%", md: "110%" },
            flexDirection: { xs: "column", md: "row" },
            gap: "12px",
            paddingTop: "36px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "8px",
            }}
          >
            <Typography
              level="h4"
              textColor={
                theme === "light" ? themes.primary[800] : themes.primary[50]
              }
              sx={{ textTransform: "uppercase" }}
            >
              <F id="services.title1" defaultMessage="we're focused in" />
            </Typography>
            <Typography
              level="h4"
              textColor={themes.primary[500]}
              sx={{ textTransform: "uppercase" }}
            >
              <TextTransition springConfig={presets.wobbly}>
                {language === "en" ? (
                  <>{TEXTSEng[index % TEXTSEng.length]}</>
                ) : (
                  <>{TEXTSEsp[index % TEXTSEsp.length]}</>
                )}
              </TextTransition>
            </Typography>
          </Box>
          <Typography
            level="h4"
            textColor={
              theme === "light" ? themes.primary[800] : themes.primary[50]
            }
            sx={{ textTransform: "uppercase" }}
          >
            <F id="services.title2" defaultMessage="new experiences" />
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "100%", lg: "90%" },
            paddingTop: "36px",
          }}
        >
          <Typography
            level="h6"
            textColor={
              theme === "light" ? themes.primary[800] : themes.primary[50]
            }
            sx={{ paddingBottom: "32px" }}
          >
            <F
              id="services.text1"
              defaultMessage="Our goal is help to develop your goals."
            />
          </Typography>
          <Typography
            level="h6"
            textColor={
              theme === "light" ? themes.primary[800] : themes.primary[50]
            }
          >
            <F
              id="services.text2"
              defaultMessage="Our goal is help to develop your goals."
            />
          </Typography>
        </Box>
        <Box sx={{ width: { xs: "100%", md: "90%" }, paddingTop: "32px" }}>
          <Box
            sx={{
              width: "100%",
              backgroundColor: themes.primary[800],
              borderRadius: "24px",
              padding: "24px",
            }}
          >
            <Box>
              <Typography
                level="h6Bold"
                textColor={themes.common.white}
                sx={{ textTransform: "uppercase" }}
              >
                <F id="services.need" defaultMessage="We do what you need" />
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: "56px",
                marginTop: "24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: { xs: "100%", md: "30%" },
                  gap: "10px",
                }}
              >
                <Typography level="h6" textColor={themes.common.white}>
                  <F id="service.need1" defaultMessage="Software engineering" />
                </Typography>
                <Typography level="h6" textColor={themes.common.white}>
                  <F id="service.need2" defaultMessage="Design (UI, UX, CX)" />
                </Typography>
                <Typography level="h6" textColor={themes.common.white}>
                  <F id="service.need3" defaultMessage="Project management" />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: { xs: "100%", md: "30%" },
                  gap: "10px",
                  marginTop: { xs: "-44px", sm: "0px", md: "0px" },
                }}
              >
                <Typography level="h6" textColor={themes.common.white}>
                  <F id="service.need4" defaultMessage="Business analysis" />
                </Typography>
                <Typography level="h6" textColor={themes.common.white}>
                  <F id="service.need5" defaultMessage="Business analysis" />
                </Typography>
                <Typography level="h6" textColor={themes.common.white}>
                  <F id="service.need6" defaultMessage="Branding" />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: { xs: "100%", md: "80%" },
                  justifyContent: { xs: "end", sm: "start", lg: "end" },
                  marginTop: "-20px",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", md: "100%", lg: "60%" },
                    borderRadius: "16px",
                    padding: "24px",
                    backgroundColor:
                      theme === "light"
                        ? themes.common.white
                        : themes.primary[700],
                  }}
                >
                  <Box sx={{ marginBottom: "24px" }}>
                    <Typography
                      level="h6Bold"
                      textTransform={"uppercase"}
                      textColor={
                        theme === "light"
                          ? themes.primary[800]
                          : themes.common.white
                      }
                    >
                      <F id="service.need.title" defaultMessage="How we work" />
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      level="h6"
                      textColor={
                        theme === "light"
                          ? themes.primary[800]
                          : themes.common.white
                      }
                    >
                      <F
                        id="service.need7"
                        defaultMessage="Consulting/Hourly"
                      />
                    </Typography>
                    <Typography
                      level="h6"
                      textColor={
                        theme === "light"
                          ? themes.primary[800]
                          : themes.common.white
                      }
                    >
                      <F id="service.need8" defaultMessage="Project" />
                    </Typography>
                    <Typography
                      level="h6"
                      textColor={
                        theme === "light"
                          ? themes.primary[800]
                          : themes.common.white
                      }
                    >
                      <F
                        id="service.need9"
                        defaultMessage="Staff augmentation"
                      />
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", marginTop: "16px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                width: "100%",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", sm: "50%" },
                  borderRadius: "24px",
                  padding: "24px",
                  backgroundColor: themes.primary[500],
                }}
              >
                <Box>
                  <Typography
                    level="h6Bold"
                    textColor={themes.common.white}
                    sx={{
                      textTransform: "uppercase",
                      textAlign: { xs: "center", md: "start" },
                    }}
                  >
                    <F
                      id="services.software"
                      defaultMessage="Software engineering"
                    />
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "24px",
                  }}
                >
                  <Typography level="h6" textColor={themes.common.white}>
                    <F
                      id="services.software1"
                      defaultMessage="Web application"
                    />
                  </Typography>
                  <Typography level="h6" textColor={themes.common.white}>
                    <F
                      id="services.software2"
                      defaultMessage="Mobile application"
                    />
                  </Typography>
                  <Typography level="h6" textColor={themes.common.white}>
                    <F id="services.software3" defaultMessage="Database" />
                  </Typography>
                  <Typography level="h6" textColor={themes.common.white}>
                    <F
                      id="services.software4"
                      defaultMessage="API Development"
                    />
                  </Typography>
                  <Typography level="h6" textColor={themes.common.white}>
                    <F id="services.software5" defaultMessage="QA/Testing" />
                  </Typography>
                  <Typography level="h6" textColor={themes.common.white}>
                    <F id="services.software6" defaultMessage="Code review" />
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", sm: "50%" },
                  borderRadius: "24px",
                  padding: "24px",
                  backgroundColor: themes.secondary[500],
                }}
              >
                <Box>
                  <Typography
                    level="h6Bold"
                    textColor={themes.common.white}
                    sx={{
                      textTransform: "uppercase",
                      textAlign: { xs: "center", md: "start" },
                    }}
                  >
                    <F id="services.design" defaultMessage="Design services" />
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "24px",
                  }}
                >
                  <Typography level="h6" textColor={themes.common.white}>
                    <F id="service.design1" defaultMessage="Design systems" />
                  </Typography>
                  <Typography level="h6" textColor={themes.common.white}>
                    <F
                      id="service.design2"
                      defaultMessage="User interface design"
                    />
                  </Typography>
                  <Typography level="h6" textColor={themes.common.white}>
                    <F
                      id="service.design3"
                      defaultMessage="User experience audit"
                    />
                  </Typography>
                  <Typography level="h6" textColor={themes.common.white}>
                    <F id="service.design4" defaultMessage="Branding" />
                  </Typography>
                  <Typography level="h6" textColor={themes.common.white}>
                    <F
                      id="service.design5"
                      defaultMessage="Branding management"
                    />
                  </Typography>
                  <Typography level="h6" textColor={themes.common.white}>
                    <F
                      id="service.design6"
                      defaultMessage="Time proof design"
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ paddingTop: "70px" }}>
          <Typography
            level="h4"
            textColor={
              theme === "light" ? themes.primary[800] : themes.primary[50]
            }
            sx={{ textTransform: "uppercase" }}
          >
            <F id="workedWith" defaultMessage="Who has worked with us" />
          </Typography>
          <Box sx={{ width: "100%", marginTop: "32px" }}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "start",
                  md: "space-between",
                  lg: "start",
                },
                gap: "16px",
              }}
            >
              <Grid
                sx={{
                  width: { xs: "100%", sm: "48%", md: "346px" },
                  cursor: "pointer",
                }}
              >
                <Link
                  href="https://www.gangverk.is/"
                  target="blank"
                  rel="noreferrer"
                  sx={{ width: { xs: "100%", sm: "100%", md: "346px" } }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "68px",
                      borderRadius: "16px",
                      backgroundColor:
                        theme === "light"
                          ? themes.common.white
                          : themes.primary[800],
                      transition: "all 300ms ease-in",
                      "&:hover": {
                        borderRadius: "8px",
                        backgroundColor:
                          theme === "light"
                            ? themes.neutral[50]
                            : themes.neutral[700],
                      },
                    }}
                  >
                    {theme === "light" ? (
                      <img alt="Gangverk" src={Gangverk}></img>
                    ) : (
                      <img alt="Gangverk" src={GangverkDark}></img>
                    )}
                  </Box>
                </Link>
              </Grid>
              <Grid
                sx={{
                  width: { xs: "100%", sm: "48%", md: "346px" },
                  cursor: "pointer",
                }}
              >
                <Link
                  href="https://rotoplas.com/"
                  target="blank"
                  rel="noreferrer"
                  sx={{ width: { xs: "100%", sm: "100%", md: "346px" } }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "68px",
                      borderRadius: "16px",
                      backgroundColor:
                        theme === "light"
                          ? themes.common.white
                          : themes.primary[800],
                      transition: "all 300ms ease-in",
                      "&:hover": {
                        borderRadius: "8px",
                        backgroundColor:
                          theme === "light"
                            ? themes.neutral[50]
                            : themes.neutral[700],
                      },
                    }}
                  >
                    {theme === "light" ? (
                      <img alt="Rotoplas" src={Rtp}></img>
                    ) : (
                      <img alt="Rotoplas" src={RtpDark}></img>
                    )}
                  </Box>
                </Link>
              </Grid>
              <Grid
                sx={{
                  width: { xs: "100%", sm: "48%", md: "346px" },
                  cursor: "pointer",
                }}
              >
                <Link
                  href="https://staykukun.com/"
                  target="blank"
                  rel="noreferrer"
                  sx={{ width: { xs: "100%", sm: "100%", md: "346px" } }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "68px",
                      borderRadius: "16px",
                      backgroundColor:
                        theme === "light"
                          ? themes.common.white
                          : themes.primary[800],
                      transition: "all 300ms ease-in",
                      "&:hover": {
                        borderRadius: "8px",
                        backgroundColor:
                          theme === "light"
                            ? themes.neutral[50]
                            : themes.neutral[700],
                      },
                    }}
                  >
                    {theme === "light" ? (
                      <img alt="Kukun" src={Kukun}></img>
                    ) : (
                      <img alt="Kukun" src={KukunDark}></img>
                    )}
                  </Box>
                </Link>
              </Grid>
              <Grid
                sx={{
                  width: { xs: "100%", sm: "48%", md: "346px" },
                  cursor: "pointer",
                }}
              >
                <Link
                  href="https://dml.mx/"
                  target="blank"
                  rel="noreferrer"
                  sx={{ width: { xs: "100%", sm: "100%", md: "346px" } }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "68px",
                      borderRadius: "16px",
                      backgroundColor:
                        theme === "light"
                          ? themes.common.white
                          : themes.primary[800],
                      transition: "all 300ms ease-in",
                      "&:hover": {
                        borderRadius: "8px",
                        backgroundColor:
                          theme === "light"
                            ? themes.neutral[50]
                            : themes.neutral[700],
                      },
                    }}
                  >
                    {theme === "light" ? (
                      <img alt="DMl" src={Dml}></img>
                    ) : (
                      <img alt="DMl" src={DmlDark}></img>
                    )}
                  </Box>
                </Link>
              </Grid>
              <Grid
                sx={{
                  width: { xs: "100%", sm: "48%", md: "346px" },
                  cursor: "pointer",
                }}
              >
                <Link
                  href={"https://www.iacgroup.com/"}
                  target="blank"
                  rel="noreferrer"
                  sx={{ width: { xs: "100%", sm: "100%", md: "346px" } }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "68px",
                      borderRadius: "16px",
                      backgroundColor:
                        theme === "light"
                          ? themes.common.white
                          : themes.primary[800],
                      transition: "all 300ms ease-in",
                      "&:hover": {
                        borderRadius: "8px",
                        backgroundColor:
                          theme === "light"
                            ? themes.neutral[50]
                            : themes.neutral[700],
                      },
                    }}
                  >
                    {theme === "light" ? (
                      <img alt="IAC" src={Iac}></img>
                    ) : (
                      <img alt="IAC" src={IacDark}></img>
                    )}
                  </Box>
                </Link>
              </Grid>
              {/* <Grid sx={{ width: { xs: "100%", sm: "48%", md: "346px" }, cursor: "pointer" }}>
                <Link
                  href="https://capitalinvent.com/"
                  target="blank"
                  rel="noreferrer"
                  sx={{ width: { xs: "100%", sm: "100%", md: "346px" } }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "68px",
                      borderRadius: "16px",
                     backgroundColor: theme === "light" ? themes.common.white : themes.primary[800],
                      transition: "all 300ms ease-in",
                      "&:hover": {
                        borderRadius: "8px",
                        backgroundColor: themes.neutral[50],
                      },
                    }}
                  >
                    <img alt="Invent" src={Invent}></img>
                  </Box>
                </Link>
              </Grid> */}
              <Grid
                sx={{
                  width: { xs: "100%", sm: "48%", md: "346px" },
                  cursor: "pointer",
                }}
              >
                <Link
                  href="https://epicplus.itam.mx/"
                  target="blank"
                  rel="noreferrer"
                  sx={{ width: { xs: "100%", sm: "100%", md: "346px" } }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "68px",
                      borderRadius: "16px",
                      backgroundColor:
                        theme === "light"
                          ? themes.common.white
                          : themes.primary[800],
                      transition: "all 300ms ease-in",
                      "&:hover": {
                        borderRadius: "8px",
                        backgroundColor:
                          theme === "light"
                            ? themes.neutral[50]
                            : themes.neutral[700],
                      },
                    }}
                  >
                    <img alt="Itam" src={Itam}></img>
                  </Box>
                </Link>
              </Grid>
              <Grid
                sx={{
                  width: { xs: "100%", sm: "48%", md: "346px" },
                  cursor: "pointer",
                }}
              >
                <Link
                  href="https://www.harbortouch.com/"
                  target="blank"
                  rel="noreferrer"
                  sx={{ width: { xs: "100%", sm: "100%", md: "346px" } }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "68px",
                      borderRadius: "16px",
                      backgroundColor:
                        theme === "light"
                          ? themes.common.white
                          : themes.primary[800],
                      transition: "all 300ms ease-in",
                      "&:hover": {
                        borderRadius: "8px",
                        backgroundColor:
                          theme === "light"
                            ? themes.neutral[50]
                            : themes.neutral[700],
                      },
                    }}
                  >
                    {theme === "light" ? (
                      <img alt="HarborTouch" src={Ht}></img>
                    ) : (
                      <img alt="HarborTouch" src={HtDark}></img>
                    )}
                  </Box>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: "70px",
            paddingBottom: { xs: "100px", md: "188px" },
          }}
        >
          <Typography
            level="h4"
            textColor={
              theme === "light" ? themes.primary[800] : themes.primary[50]
            }
            sx={{ textTransform: "uppercase" }}
          >
            <F id="seeMore" defaultMessage="Want to see more?" />
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: "24px",
              paddingTop: "32px",
            }}
          >
            <Button
              sx={{
                width: { xs: "100%", sm: "40%", md: "30%" },
                height: "47px",
                backgroundColor:
                  theme === "light" ? themes.common.white : themes.primary[800],
                transition: "all 300ms ease-in",
                "&:hover": {
                  borderRadius: "8px",
                  backgroundColor:
                    theme === "light"
                      ? themes.neutral[50]
                      : themes.neutral[700],
                },
              }}
              onClick={() => goToPortfolio()}
            >
              <Typography
                level="h6Bold"
                textColor={
                  theme === "light" ? themes.primary[800] : themes.common.white
                }
                textTransform="uppercase"
              >
                <F id="seePortfolio" defaultMessage="See portfolios" />
              </Typography>
            </Button>
            {/* <Button
              sx={{
                width: { xs: "177px", sm: "219px" },
                height: "47px",
                backgroundColor:
                  theme === "light" ? themes.common.white : themes.primary[800],
                transition: "all 300ms ease-in",
                "&:hover": {
                  borderRadius: "8px",
                  backgroundColor:
                    theme === "light"
                      ? themes.neutral[50]
                      : themes.neutral[700],
                },
              }}
            >
              <Typography
                level="h6Bold"
                textColor={
                  theme === "light" ? themes.primary[800] : themes.common.white
                }
                textTransform="uppercase"
              >
                <F id="randPRoject" defaultMessage="random project" />
              </Typography>
            </Button> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Services;
