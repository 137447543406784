import {
  useEffect,
  useState,
  ReactNode,
  isValidElement,
  cloneElement,
} from "react";
import { Box } from "@mui/joy";
import LayoutPageModal from "./PageModal/LayoutPageModal";

const PageModal = ({
  ComponentModal,
  closePageModal,
}: {
  ComponentModal: ReactNode;
  closePageModal: Function;
}) => {
  const [animate, setAnimate] = useState(false);
  const [overflowControl, setOverflowControl] = useState(true);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const closeModal = () => {
    setAnimate(false);
    setTimeout(() => {
      closePageModal();
    }, 450);
  };

  useEffect(() => {
    let timeout: any;

    setOverflowControl(false);
    timeout = setTimeout(() => {
      setOverflowControl(true);
    }, 400)

    return () => {
      timeout && clearTimeout(timeout);
    }
  }, [animate])

  function addPropsToReactElement(element: ReactNode, props: any) {
    if (isValidElement(element)) {
      return cloneElement(element, props);
    }
    return element;
  }

  function addPropsToChildren(children: ReactNode, props: any) {
    if (!Array.isArray(children)) {
      return addPropsToReactElement(children, props);
    }
    return children.map((childElement) =>
      addPropsToReactElement(childElement, props)
    );
  }

  return (
    <Box
      sx={{
        transition: "all 400ms ease-in-out",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100dvh",
        overflowY: `${overflowControl ? "auto" : "hidden"}`,
        zIndex: 9999,
        backgroundColor: "white",
        transform: `${animate ? "translateY(0%)" : "translateY(100%)"}`,
      }}
    >
      <LayoutPageModal closeModal={closeModal}>
        {addPropsToChildren(ComponentModal, { closeModal })}
      </LayoutPageModal>
    </Box>
  );
};

export default PageModal;