import { Box, Typography, useTheme } from "@mui/joy";
import React, { useMemo, useState } from "react";
// import Footer from '../FooterPortfolio'

type mode = "LIGHT" | "DARK";

const AnimatedThemeSwitch = () => {
  const [selectedMode, setSelectedMode] = useState<mode>("LIGHT");
  const [hover, setHover] = useState(false);
  const theme = useTheme();
  const themes = theme.vars.palette;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const textColor = useMemo(() => {
    switch (selectedMode) {
      case "DARK":
        return "#FAFAFA";
      case "LIGHT":
        return "#232323";
    }
  }, [selectedMode]);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        height: { xs: "200px", sm: "300px" },
        justifyContent: "center",
        alignItems: "center",
        transition: "all 300ms ease-in",
        borderRadius: "24px",
        backgroundColor: selectedMode === "LIGHT" ? "#FFFFFF" : "#232323",
      }}
    >
      <Box
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          cursor: "pointer",
          transition: "all 300ms ease-in",
          display: "flex",
          borderRadius: "42px",
          border: "4px solid #4893FF",
          padding: "8px",
          position: "relative",
          width: hover ? 170 * 2 : 180,
          height: "90px",
        }}
      >
        <Box
          onClick={() => setSelectedMode("DARK")}
          sx={{
            transition: "all 300ms ease-in",
            flexShrink: 0,
            zIndex: selectedMode === "LIGHT" ? 0 : 1,
            width: 158,
            height: { xs: "40px" },
            paddingY: "32px",
            borderRadius: hover ? "32px 0px 0px 32px" : "32px",
            backgroundColor: selectedMode === "LIGHT" ? "#3E3E3E" : "#4893FF",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-16px",
            }}
          >
            <Typography level="h4" textColor={themes.common.white}>
              Dark
            </Typography>
          </Box>
        </Box>
        <Box
          onClick={() => setSelectedMode("LIGHT")}
          sx={{
            cursor: "pointer",
            transition: "all 300ms ease-in",
            transform: hover ? "translateX(0%)" : "translateX(-100%)",
            flexShrink: 0,
            zIndex: selectedMode === "LIGHT" ? 1 : 0,
            width: 158,
            height: { xs: "40px" },
            paddingY: "32px",
            borderRadius: hover ? "0px 32px 32px 0px" : "32px",
            backgroundColor: selectedMode === "LIGHT" ? "#4893FF" : "#3E3E3E",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-16px",
            }}
          >
            <Typography level="h4" textColor={themes.common.white}>
              Light
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* <Footer textColor={textColor} /> */}
    </Box>
  );
};

export default AnimatedThemeSwitch;
