// Assets
import GoBackLeftArrow from "../UI/Icons/GoBackLeftArrow";
import Line from "../UI/Icons/Line";

// React
// import { useState } from "react";

// Style
import { Box, Button, Typography, useTheme } from "@mui/joy";

// Tools
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { InfiniteScroll } from "../../Portfolio/modules/InfiniteScroll";

const InfiniteScrollP = () => {
  const F = FormattedMessage;
  const theme = useTheme();
  const themes = theme.vars.palette;
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/playground");
  };

  return (
    <Box
      component="main"
      sx={{ maxHeight: "100dvh", backgroundColor: themes.primary[50] }}
    >
      <Box
        sx={{
          width: { xs: "90dvw", sm: "94dvw", md: "70dvw" },
          marginLeft: { md: "40px" },
          marginX: { xs: "20px" },
          paddingBottom: "130px",
        }}
      >
        <Box>
          <Button
            onClick={() => goBack()}
            sx={{
              width: { xs: "30%", sm: "16%", md: "auto" },
              backgroundColor: themes.common.white,
              transition: "all 300ms ease-in",
              "&:hover": {
                borderRadius: "8px",
                backgroundColor: themes.neutral[50],
              },
            }}
            startDecorator={<GoBackLeftArrow width={"16px"} height={"16px"} />}
          >
            <Typography
              level="h6Bold"
              textColor={themes.primary[400]}
              sx={{ textTransform: "uppercase" }}
            >
              <F id="goBack.back" defaultMessage="Back" />
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" } }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "100%",
              paddingTop: "36px",
            }}
          >
            <Typography
              level="h4"
              textColor={themes.primary[800]}
              sx={{ textTransform: "uppercase" }}
            >
              <F id="infinite.scroll" defaultMessage="Infinite scroll" />
            </Typography>
            <Box
              sx={{
                display: "flex",
                paddingTop: "4px",
              }}
            >
              <Line width="550px" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                width: "100%",
                paddingTop: "2px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "83px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor: themes.secondary[50],
                }}
              >
                <Typography level="body3" textColor={themes.secondary[500]}>
                  <F id="info.example" defaultMessage="Example" />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "40px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor: themes.secondary[50],
                }}
              >
                <Typography level="body3" textColor={themes.secondary[500]}>
                  <F id="info.UI" defaultMessage="UI" />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "73px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor: themes.secondary[50],
                }}
              >
                <Typography level="body3" textColor={themes.secondary[500]}>
                  <F id="info.react" defaultMessage="React" />
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", marginTop: "20px" }}
          >
            <Box sx={{ marginBottom: "16px" }}>
              <Typography
                level="body1"
                textColor={themes.common.black}
                textAlign="start"
              >
                <F
                  id="info.text1"
                  defaultMessage="Playable button to display lots of info if needed."
                />
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "8px" }}>
              <Typography
                level="body1Medium"
                textColor={themes.common.black}
                textAlign="start"
              >
                <F id="info.text2" defaultMessage="Use cases:" />
              </Typography>
            </Box>
            <Box>
              <Typography
                level="body1"
                textColor={themes.common.black}
                textAlign="start"
              >
                <F
                  id="info.text3"
                  defaultMessage="A friendly button to display info without changing or loading another screen and you don't want to use a plain pop-up or modal."
                />
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "60px" }}>
          <InfiniteScroll />
        </Box>
      </Box>
    </Box>
  );
};

export default InfiniteScrollP;
