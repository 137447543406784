import {
  Box,
  Button,
  CircularProgress,
  Input,
  Modal,
  ModalDialog,
  Textarea,
  Typography,
  useTheme,
} from "@mui/joy";
import { useContext, useEffect, useState } from "react";
import Context from "../../Context/Context";
import { FormattedMessage } from "react-intl";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CloseIcon from "../Icons/CloseIcon";
import ThankYou from "../../UI/Images/ThankYou.webp";

const ModalForm = () => {
  // const F = FormattedMessage;
  const themeP = useTheme();
  const themes = themeP.vars.palette;

  const { openModal, setOpenModal, language, theme } = useContext(Context);
  const [nameProject, setNameProject] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [emailInput, setEmailInput] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [moreAbout, setMoreAbout] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [errorName, setErrorName] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorSend, setErrorSend] = useState<boolean>(false);

  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    if (openModal === false) setStartAnimation(false);

    if (openModal === true) setStartAnimation(true);
  }, [openModal]);

  const validEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const sendData = () => {
    const data = {
      project: nameProject,
      name: fullName,
      email: emailInput,
      cellphone: phone,
      text: moreAbout,
    };
    setLoading(true);
    if (data.name.length > 3) {
      setErrorName(false);
      if (validEmail.test(data.email) && data.email !== "") {
        fetch("https://api.hermescorp.io/api/login/message", {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => {
            if (res.status === 200) {
              // console.log(data);
              setLoading(false);
              setEmailSent(true);
              setErrorSend(false);
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            setErrorSend(true);
          });
        setError(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } else {
      setErrorName(true);
      setLoading(false);
    }
  };

  const closeModal = () => {
    setErrorSend(false);
    setOpenModal(false);
  };

  useEffect(() => {
    setPhone("");
    setEmailSent(false);
    setErrorName(false);
    setError(false);
  }, []);

  return (
    <Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme === "light" ? "#3facff80" : "#030B27CC",
          transition: "all 150ms ease-in-out",
          opacity: `${startAnimation ? "1" : "0"}`,
        }}
      >
        <ModalDialog
          variant="soft"
          sx={{
            width: "30%",
            minWidth: "320px",
            backgroundColor:
              theme === "light" ? themes.primary[50] : themes.primary[800],
            padding: "16px",
            height: {xs: "85%", sm: "88%", md: "85%", lg: "80%"}
          }}
        >
          <Box
            sx={{
              transition: "all 150ms ease-in-out",
              height: `${
                !loading && !emailSent
                  ? "482px"
                  : loading && !emailSent
                  ? "254px"
                  : "100%"
              }`,
              maxHeight: `${
                !loading && !emailSent
                  ? "482px"
                  : loading && !emailSent
                  ? "254px"
                  : "600px"
              }`,
            }}
          >
            {loading ? (
              <LoadingForm />
            ) : (
              <RegularForm
                closeModal={closeModal}
                emailSent={emailSent}
                error={error}
                errorName={errorName}
                errorSend={errorSend}
                language={language}
                phone={phone}
                sendData={sendData}
                setEmailInput={setEmailInput}
                setFullName={setFullName}
                setMoreAbout={setMoreAbout}
                setNameProject={setNameProject}
                setOpenModal={setOpenModal}
                setPhone={setPhone}
              />
            )}
          </Box>
        </ModalDialog>
      </Modal>
    </Box>
  );
};

const LoadingForm = () => {
  const F = FormattedMessage;
  const themeP = useTheme();
  const themes = themeP.vars.palette;
  const { theme } = useContext(Context);
  return (
    <>
      <Box>
        <Typography
          level="h4"
          textColor={
            theme === "light" ? themes.common.black : themes.primary[200]
          }
          textAlign="center"
          textTransform="uppercase"
        >
          <F
            id="We'll be glad to hear about you"
            defaultMessage="We'll be glad to hear about you"
          />
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress></CircularProgress>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          sx={{
            width: "100%",
            backgroundColor: themes.primary[500],
            transition: "all 300ms ease-in",
            "&:hover": {
              backgroundColor: themes.primary[500],
            },
          }}
        >
          <Typography level="body1Bold" textColor={themes.common.white}>
            <F id="submiting" defaultMessage="Submiting" />
          </Typography>
        </Button>
      </Box>
    </>
  );
};

interface RegularFormProps {
  error: boolean;
  errorName: boolean;
  errorSend: boolean;
  emailSent: boolean;
  language: string;
  phone: string;
  setPhone: Function;
  setEmailInput: Function;
  setFullName: Function;
  setOpenModal: Function;
  setNameProject: Function;
  setMoreAbout: Function;
  sendData: Function;
  closeModal: Function;
}

const RegularForm = ({
  emailSent,
  setOpenModal,
  setNameProject,
  language,
  setFullName,
  errorName,
  error,
  phone,
  setPhone,
  setEmailInput,
  setMoreAbout,
  errorSend,
  sendData,
  closeModal,
}: RegularFormProps) => {
  const F = FormattedMessage;
  const themeP = useTheme();
  const themes = themeP.vars.palette;
  const { theme } = useContext(Context);
  return (
    <>
      {!emailSent ? (
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "-60px",
              marginBottom: "18px",
              marginRight: "-25px",
            }}
          >
            <Button
              onClick={() => setOpenModal(false)}
              endDecorator={
                <Box sx={{ paddingTop: { xs: "0px", md: "0px" } }}>
                  {" "}
                  <CloseIcon width={"18px"} height={"18px"} />
                </Box>
              }
              sx={{
                backgroundColor: "transparent",
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              <Typography
                level="h6Bold"
                textColor={themes.common.white}
                textTransform="uppercase"
              >
                <F id="close.form" defaultMessage="Close" />
              </Typography>
            </Button>
          </Box>
          <Box sx={{ marginTop: "15px" }}>
            <Typography
              level="h4"
              textColor={
                theme === "light" ? themes.common.black : themes.primary[200]
              }
              textAlign="center"
              textTransform="uppercase"
            >
              <F
                id="form.title"
                defaultMessage="We'll be glad to hear about you"
              />
            </Typography>
          </Box>
          <Box sx={{ marginTop: "15px" }}>
            <Input
              onChange={(e) => setNameProject(e.target.value)}
              placeholder={
                language === "en"
                  ? "What's the name of your project or company?"
                  : "Nombre de tu proyecto"
              }
              variant="outlined"
              sx={{
                color: themes.primary[500],
                border: `solid 1px ${themes.primary[500]}`,
                fontFamily: "Armin Grotesk Regular",
                "&:hover": {
                  border: `solid 1px ${themes.primary[500]}`,
                },
                backgroundColor: "transparent",
              }}
            />
          </Box>
          <Box sx={{ marginTop: "15px" }}>
            <Input
              onChange={(e) => setFullName(e.target.value)}
              placeholder={language === "en" ? "Your full name" : "Nombre"}
              variant="outlined"
              sx={{
                color: themes.primary[500],
                border: `solid 1px ${themes.primary[500]}`,
                fontFamily: "Armin Grotesk Regular",
                "&:hover": {
                  border: `solid 1px ${themes.primary[500]}`,
                },
                backgroundColor: "transparent",
              }}
            />
            {errorName ? (
              <Box
                sx={{
                  transition: "all 1000ms cubic-bezier(0.4, 0, 0.2, 1)",
                }}
              >
                <Typography
                  level="body1"
                  textColor={themes.danger[500]}
                  textAlign="start"
                >
                  <F id="noName" defaultMessage="Required name" />
                </Typography>
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <Box sx={{ marginTop: "15px" }}>
            <Input
              onChange={(e) => setEmailInput(e.target.value)}
              placeholder={language === "en" ? "Your mail" : "Mail"}
              variant="outlined"
              sx={{
                color: themes.primary[500],
                border: error
                  ? `solid 1px ${themes.danger[500]}`
                  : `solid 1px ${themes.primary[500]}`,
                fontFamily: "Armin Grotesk Regular",
                "&:hover": {
                  border: `solid 1px ${themes.primary[500]}`,
                },
                backgroundColor: "transparent",
              }}
            />
            {error ? (
              <Box
                sx={{
                  transition: "all 1000ms cubic-bezier(0.4, 0, 0.2, 1)",
                }}
              >
                <Typography
                  level="body1"
                  textColor={themes.danger[500]}
                  textAlign="start"
                >
                  <F id="invalid" defaultMessage="Invalid Email" />
                </Typography>
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <Box sx={{ marginTop: "15px" }}>
            <PhoneInput
              // onChange={(
              //   value: string,
              //   data: {} | CountryData,
              //   event: React.ChangeEvent<HTMLInputElement>,
              //   formattedValue: string
              // ) => {
              //   setPhone(value);
              // }}
              value={phone}
              onChange={(value) => setPhone(value)}
              country={"mx"}
              preferredCountries={["us", "mx"]}
              countryCodeEditable={false}
              inputStyle={{
                width: "100%",
                background: "transparent",
                border: `solid 1px ${themes.primary[500]}`,
                borderRadius: "8px",
                color: `${themes.primary[500]}`,
              }}
            />
          </Box>
          <Box sx={{ marginTop: "15px", width: "100%" }}>
            <Textarea
              onChange={(e) => {
                setMoreAbout(e.target.value);
              }}
              placeholder={
                language === "en"
                  ? "Tell us more about your project"
                  : "Cuéntanos más"
              }
              variant="outlined"
              sx={{
                color: themes.primary[500],
                border: `solid 1px ${themes.primary[500]}`,
                fontFamily: "Armin Grotesk Regular",
                "&:hover": {
                  border: `solid 1px ${themes.primary[500]}`,
                },
                backgroundColor: "transparent",
              }}
              minRows={3}
            />
          </Box>
          {errorSend ? (
            <Box
              sx={{
                marginTop: "15px",
                width: "100%",
                transition: "all 1000ms cubic-bezier(0.4, 0, 0.2, 1)",
              }}
            >
              <Typography
                level="body1Bold"
                textColor={themes.danger[500]}
                textAlign="center"
              >
                <F
                  id="something"
                  defaultMessage="Something went wrong, try again"
                />
              </Typography>
            </Box>
          ) : (
            <Box sx={{ height: "24px" }}></Box>
          )}
          <Box sx={{ marginTop: "20px", marginBottom: { xs: "40px" } }}>
            <Button
              onClick={() => sendData()}
              sx={{
                width: "100%",
                backgroundColor: themes.primary[500],
                transition: "all 300ms ease-in",
                "&:hover": {
                  backgroundColor: themes.primary[600],
                },
              }}
            >
              <Typography level="body1Bold" textColor={themes.common.white}>
                <F id="submit" defaultMessage="Submit" />
              </Typography>
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "-60px",
              marginBottom: "18px",
              marginRight: "-25px",
            }}
          >
            <Button
              onClick={() => setOpenModal(false)}
              endDecorator={
                <Box sx={{ paddingTop: "4px" }}>
                  {" "}
                  <CloseIcon width={"18px"} height={"18px"} />
                </Box>
              }
              sx={{
                backgroundColor: "transparent",
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              <Typography
                level="h6Bold"
                textColor={themes.common.white}
                textTransform="uppercase"
              >
                <F id="close.form" defaultMessage="Close" />
              </Typography>
            </Button>
          </Box>
          <Box sx={{ marginTop: "15px" }}>
            <Typography
              level="h4"
              textColor={
                theme === "light" ? themes.common.black : themes.primary[200]
              }
              textAlign="center"
              textTransform="uppercase"
            >
              <F
                id="thank.you"
                defaultMessage="Thank you! we'll stay in touch"
              />
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <img alt="thankyou" width={251} height={200} src={ThankYou}></img>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "40px",
            }}
          >
            <Button
              onClick={() => closeModal()}
              sx={{
                width: "100%",
                backgroundColor: themes.primary[500],
                transition: "all 300ms ease-in",
                "&:hover": {
                  backgroundColor: themes.primary[600],
                },
              }}
            >
              <Typography level="body1Bold" textColor={themes.common.white}>
                <F id="goBack" defaultMessage="Go back" />
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ModalForm;
