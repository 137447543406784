// Assets
import GoBackLeftArrow from "../UI/Icons/GoBackLeftArrow";
import Line from "../UI/Icons/Line";

// React
// import { useState } from "react";

// Style
import { Box, Button, Typography, useTheme } from "@mui/joy";

// Tools
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import AnimatedGraph from "../../Portfolio/modules/AnimatedGraph";
import Context from "../Context/Context";
import { useContext } from "react";

const Graph = () => {
  const F = FormattedMessage;
  const themeP = useTheme();
  const themes = themeP.vars.palette;
  const navigate = useNavigate();
  const { theme } = useContext(Context);

  const goBack = () => {
    navigate("/playground");
  };

  return (
    <Box
      sx={{
        height: "auto",
        backgroundColor:
          theme === "light" ? themes.primary[50] : themes.primary[900],
      }}
    >
      <Box
        sx={{
          width: { xs: "90dvw", sm: "94dvw", md: "70dvw" },
          marginLeft: { md: "40px" },
          marginX: { xs: "20px" },
          paddingBottom: "130px",
        }}
      >
        <Box>
          <Button
            onClick={() => goBack()}
            sx={{
              width: { xs: "30%", sm: "16%", md: "auto" },
              backgroundColor:
                theme === "light" ? themes.common.white : themes.primary[800],
              transition: "all 300ms ease-in",
              "&:hover": {
                borderRadius: "8px",
                backgroundColor:
                  theme === "light" ? themes.neutral[50] : themes.neutral[700],
              },
            }}
            startDecorator={<GoBackLeftArrow width={"16px"} height={"16px"} />}
          >
            <Typography
              level="h6Bold"
              textColor={themes.primary[400]}
              sx={{ textTransform: "uppercase" }}
            >
              <F id="goBack.back" defaultMessage="Back" />
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "100%",
              paddingTop: "36px",
            }}
          >
            <Typography
              level="h4"
              textColor={
                theme === "light" ? themes.primary[800] : themes.primary[50]
              }
            >
              <F id="graphbars" defaultMessage="Animated graph bars" />
            </Typography>
            <Box
              sx={{
                display: "flex",
                paddingTop: "4px",
              }}
            >
              <Line width="550px" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                width: "100%",
                paddingTop: "2px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "83px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor:
                    theme === "light"
                      ? themes.common.white
                      : themes.secondary[800],
                }}
              >
                <Typography
                  level="body3"
                  textColor={
                    theme === "light"
                      ? themes.secondary[500]
                      : themes.secondary[100]
                  }
                >
                  <F id="info.example" defaultMessage="Example" />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "40px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor:
                    theme === "light"
                      ? themes.common.white
                      : themes.secondary[800],
                }}
              >
                <Typography
                  level="body3"
                  textColor={
                    theme === "light"
                      ? themes.secondary[500]
                      : themes.secondary[100]
                  }
                >
                  <F id="info.UI" defaultMessage="UI" />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "73px",
                  height: "28px",
                  borderRadius: "16px",
                  backgroundColor:
                    theme === "light"
                      ? themes.common.white
                      : themes.secondary[800],
                }}
              >
                <Typography
                  level="body3"
                  textColor={
                    theme === "light"
                      ? themes.secondary[500]
                      : themes.secondary[100]
                  }
                >
                  <F id="infinite.design" defaultMessage="Design" />
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", marginTop: "20px" }}
          >
            <Box sx={{ marginBottom: "16px" }}>
              <Typography
                level="body1"
                textColor={
                  theme === "light" ? themes.common.black : themes.common.white
                }
                textAlign="start"
              >
                <F
                  id="graphbars.text1"
                  defaultMessage="Sabemos que las gráficas suelen ser aburridas y monótonas, y usualmente son copia y pega de tablas en excel, nos cansamos de ello así que tomamos manos a la obra para crear estas animaciones que cambian lo usualmente visto, sin cambiarlo tanto que no se entienda. Estás gráficas son súper personalizables, así que se adaptarían a cualquier tipo de información fácilmente."
                />
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "8px" }}>
              <Typography
                level="body1Medium"
                textColor={
                  theme === "light" ? themes.common.black : themes.common.white
                }
                textAlign="start"
              >
                <F id="info.text2" defaultMessage="Use cases:" />
              </Typography>
            </Box>
            <Box>
              <Typography
                level="body1"
                textColor={
                  theme === "light" ? themes.common.black : themes.common.white
                }
                textAlign="start"
              >
                <F
                  id="graphbars.text2"
                  defaultMessage="Cuando no quieras gráficas planas y aburridas."
                />
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: { xs: "40px", lg: "60px" },
          }}
        >
          <AnimatedGraph />
        </Box>
      </Box>
    </Box>
  );
};

export default Graph;
