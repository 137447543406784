// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Props {
  width?: string;
  height?: string;
}

const LinkedIconDark: React.ElementType = ({
  width = "",
  height = "",
}: {
  width: string;
  height: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.81982 26.5H0.429464V9.14113H5.81982V26.5ZM3.12174 6.77322C1.39808 6.77322 0 5.34551 0 3.62181C1.23372e-08 2.79385 0.328897 1.99981 0.914337 1.41436C1.49978 0.828904 2.2938 0.5 3.12174 0.5C3.94968 0.5 4.7437 0.828904 5.32914 1.41436C5.91459 1.99981 6.24348 2.79385 6.24348 3.62181C6.24348 5.34551 4.84482 6.77322 3.12174 6.77322ZM25.9942 26.5H20.6154V18.0498C20.6154 16.0359 20.5748 13.4533 17.8129 13.4533C15.0104 13.4533 14.5809 15.6413 14.5809 17.9047V26.5H9.19634V9.14113H14.3662V11.509H14.4416C15.1612 10.1452 16.9192 8.70585 19.5418 8.70585C24.9971 8.70585 26 12.2983 26 16.9645V26.5H25.9942Z"
        fill="#F8FBFF"
      />
    </svg>
  );
};

export default LinkedIconDark;
